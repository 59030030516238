<div class="app-dropdown bs-enabled w100 h100"
	 [ngStyle]="{'border' : isLf ? 'none' : error ? 'solid 1px var(--bs-danger)' : ''}"
	 [ngClass]="{'active' : dropdownActive && !isDisabled, 'disabled' : isDisabled , 'only-one-item' : itemsOriginal && itemsOriginal.length === 1 && !alwaysDrop}">
	<!--dropdown box-->
	<div class="app-dropdown-box flex-row justify-content-between align-items-center w-100 h-100 py-1 px-2 ellipsis"
		 [tooltip]="showTitle ? (text ? text : isMultipleSelection ? (!_.some(this.items, 'selected') ? 'Select' : _.map(_.filter(this.items, 'selected'), itemDisplayKey || 'text').join(', ')) : 'Select') : null"
		 (click)="dropdownClicked();"
		 [ngClass]="{'bg-primary' : isLf && !isDisabled, 'color-text' : isLf, 'bg-white' : !isLf}">
		<span class="ellipsis pe-2" *ngIf="text || !isMultipleSelection" [innerHTML]="text || 'Select'"></span>
		<span class="ellipsis pe-2" *ngIf="isMultipleSelection && !text">{{!_.some(this.items, 'selected') ? 'Select' : _.map(_.filter(this.items, 'selected'), itemDisplayKey || 'text').join(', ')}}</span>
		<div class="flex-row gap-1 align-items-center">
			<span class="fw-bold animated fadeIn" *ngIf="isMultipleSelection && !hideCounter && _.filter(this.items, 'selected').length > 1">({{_.filter(this.items, 'selected').length}})</span>
			<i class="fa fa-chevron-down" style="font-size: inherit; opacity: 0.85;" *ngIf="(itemsOriginal && (alwaysDrop ? itemsOriginal.length : itemsOriginal.length > 1)) || customContent.children?.length"></i>
		</div>
	</div>

	<div class="app-dropdown-menu-container" [shown]="(itemsOriginal && (alwaysDrop ? itemsOriginal.length : itemsOriginal.length > 1)) || customContent.children?.length">
			<ul [ngStyle]="{'--bs-dropdown-overflow' : itemsOriginal && (alwaysDrop ? itemsOriginal.length : itemsOriginal.length > 1) ? 'auto' : ''}" >
				<!--search-->
			<li class="static-item" style="height: 40px; padding: 6px 5px; position: sticky; top: 0;" *ngIf="enableSearch && itemsOriginal?.length">
					<div class="position-relative h-100">
						<input class="w-100 h-100 pe-4" type="search"
							   #searchInputElement
							   (click)="$event.stopPropagation();"
							   [selectAllText]="search.selectAll"
							   [(ngModel)]="search.text"
							   placeholder="Search"
							   (keyup)="searchItem();">
						<i class="fa fa-times text-muted animated fadeIn cursor-pointer hvr-darken"
						   style="position: absolute; right: 5px; top: 50%; transform: translateY(-50%); padding: 4px 5px 3px;"
						   *ngIf="search.text"
						   (click)="clearSearchItem();"></i>
					</div>
				</li>

				<virtual-scroller #scroll *ngIf="itemsOriginal && itemsOriginal?.length" [ngStyle]="{'height' : items.length * 36 + 'px'}" class="f-1-0" style="max-height: var(--bs-dropdown-menu-max-height);" [items]="items" [scrollAnimationTime]="0" [enableUnequalChildrenSizes]="true">					<li *ngFor="let item of scroll.viewPortItems"
						(click)="itemClicked(item); item.disabled && $event.stopPropagation();"
						[ngClass]="{'selected' : isMultipleSelection && item.selected, 'disabled' : item.disabled, 'ellipsis' : isEllipsis, 'static-item flex-column': item.isSeparator}">
						<!--single selection (default)-->
						<div class="flex-row gap-1 align-items-center" *ngIf="!isMultipleSelection && item.show !== false && !item.isSeparator">
							<!--just text-->
							<span class="f-1-0" *ngIf="!item.text && !itemDisplayKey">{{item}}</span>
							<!--text with icon and/or style-->
							<i [ngClass]="item.icon || itemIcon" *ngIf="item.icon || itemIcon"></i>
							<span class="f-1-0" *ngIf="item.text || itemDisplayKey" [ngStyle]="item.style">{{item[itemDisplayKey] || item.text}}</span>
							<i *ngIf="item.explanation || item.tooltip" style="font-weight: 400;" class="fas fa-question-circle" [tooltip]="item.explanation || item.tooltip"></i>

							<!--delete icon-->
							<i class="zmdi zmdi-close text-danger" *ngIf="onItemDelete.observed" (click)="onItemDelete.emit({item}); $event.stopPropagation();" tooltip="Remove item"></i>
						</div>

						<!--multiple selection-->
						<div class="gap-1 md-checkbox d-block" *ngIf="isMultipleSelection && !item.isSeparator">
							<input type="checkbox" style="opacity: 0; cursor: pointer; height: 100%; position: absolute; left: 0; width: 1.25em; visibility: visible; z-index: 50; font-size: 10.5px;"
								   [style.pointer-events]="item.disabled ? 'none' : 'all'"
								   (click)="$event.stopPropagation(); itemClicked(item, true);"
								   [checked]="item.selected"/>
							<label class="ellipsis" style="display: block;">
								<i [ngClass]="item.icon || itemIcon" *ngIf="item.icon || itemIcon" style="margin-left: 2px; margin-right: 4px;"></i>
								<span>{{item[itemDisplayKey] || item.text}}</span>
							</label>
							<i *ngIf="item.explanation" style="font-weight: 400;" class="fas fa-question-circle" [tooltip]="item.explanation"></i>
						</div>

						<!--separator-->
						<div class="f-1-0 w-100 d-flex centralize" *ngIf="item.isSeparator">
							<hr class="m-0 w-100 opacity-25" />
						</div>
					</li>
			</virtual-scroller>

				<!--"no items" placeholder-->
				<div class="d-flex centralize hide-when-empty" style="height: 50px; cursor: default;" *ngIf="(itemsOriginal?.length && search.text && totalItemsDisplayed === 0) || (itemsOriginal && !itemsOriginal?.length)">
					<span class="text-muted" *ngIf="itemsOriginal && itemsOriginal?.length && enableSearch && search.text">No items found</span>
					<span class="text-muted" *ngIf="!itemsOriginal?.length">No items in list</span>
				</div>

			<div #customContent style="display: contents;" (click)="closeDropdown();">
				<ng-content></ng-content>
			</div>
		</ul>
	</div>
</div>
