<div class="flxClmn bs-enabled">
	<div *ngIf="!isLoaded" class="centralize f-1-0 flxClmn">
		<loader height="100" width="100" style="opacity: 0.8;"></loader>
	</div>

	<div *ngIf="config" class="page-inner-content-holder gap-3 pt-0 flex-column animated fadeIn">
		<div *ngIf="!config.wa_enabled">
			<div style="text-align: center; margin: 0 auto; width:70%;">
				<i style="opacity:0.4;" class="zmdi zmdi-hc-4x"></i>
				<h1 style="opacity:0.4; font-size: 50px;">Archive is disabled</h1>
				<span>To activate the Archive feature, kindly reach out to our support team.</span>
				<br/>
				<hr/>
			</div>
		</div>

		<div *ngIf="config.wa_enabled">
			<div class="list-group list-group-flush settings-list-container">
				<div class="list-group-item settings-list-item">
					<div class="title-section-container flex-column gap-2">
						<b>Import From File</b>
						<span class="lh-base" style="font-size: 0.9em;">Upload emails you want to migrate as EML files in a ZIP, or as a PST file.<br/>Migrated archive data will be available to query and view after 24 hours</span>
					</div>

					<div class="content-container flex-column gap-3">
						<div class="flex-row f-1-0 gap-2">
							<div class="content-container flex-row gap-3">
								<div class="d-flex f-1-0 centralize bg-light cursor-pointer hvr-darken-light" style="min-height: 60px; border: dashed 1px #cccccc;"
									 ngfDrop
									 ngfSelect
									 multiple="1"
									 [(files)]="emailsArchiveFiles"
									 accept=".zip, .pst, .ost">
									<span class="fw-500 text-muted" style="font-size: 14px;">
										Click here to upload or drag & drop for plan
										<br/>
										<span *ngIf="gs.controlAdmin?.email" style="color: red;"><b>{{gs.controlAdmin?.email}}</b></span>
									</span>
								</div>

								<btn-c (action)="uploadFileWrapper(emailsArchiveFiles)"
									   [isDisabled]="!emailsArchiveFiles?.length"
									   endIcon="fas fa-file-upload" style="align-self: center; width: 150px;">
									Upload file
								</btn-c>
							</div>
						</div>

						<div *ngIf="emailsArchiveFiles?.length">
							<ul>
								<li *ngFor="let file of emailsArchiveFiles">
									<b>{{ file.name }}</b>
								</li>
							</ul>
						</div>
					</div>
				</div>

				<div class="list-group-item settings-list-item ">
					<div class="title-section-container flex-column gap-2">
						<b>Microsoft API</b>
						<span class="lh-base" style="font-size: 0.9em;">Migrate emails from all Office365 mailboxes using the Microsoft Graph API.</span>
					</div>

					<div class="content-container flex-column gap-3">
						<btn-c (action)="openStartAPIMigrationPopup();" *ngIf="!apiMigrationData.processingEvents?.length" endIcon="fa fa-exchange-alt">Start API Migration</btn-c>

						<span class="text-success mt-3 animated fadeIn" *ngIf="apiMigrationData.completed">
							Last API Migration process info:
						</span>

						<div class="flex-column gap-4 animated fadeIn" *ngIf="apiMigrationData.processingEvents?.length || apiMigrationData.completed">
							<div class="flex-row justify-content-between align-items-center" *ngIf="!apiMigrationData.completed">
								<span class="loading-dots fw-bold text-success" style="font-size: 14px;">Migration process is running</span>
								<div class="flex-row gap-2 align-items-center">
									<btn-c variant="outlined" (click)="checkRunningApiMigration();" endIcon="fa fa-sync-alt">Refresh Data</btn-c>
<!--
									<btn-c variant="outlined" (click)="deleteAPIMigration();" endIcon="fa fa-ban" color="danger">Abort Process</btn-c>
-->
								</div>
							</div>
							<div class="flex-row flex-wrap gap-2">
								<div class="border border-secondary-subtle rounded-2 p-2 flex-row gap-2 align-items-center f-1-0" style="flex-basis: 45%"
									 *ngIf="apiMigrationData.started">
									<i class="fa fa-clock text-primary"></i>
									<span class="animated fadeIn">Migration start time:</span>
									<b>{{apiMigrationData.started | date:'MM/dd/yyyy HH:mm'}}</b>
								</div>
								<div class="border border-secondary-subtle rounded-2 p-2 flex-row gap-2 align-items-center f-1-0" style="flex-basis: 45%"
									 *ngIf="apiMigrationData.completed">
									<i class="fa fa-clock text-primary"></i>
									<span class="animated fadeIn">Migration complete time:</span>
									<b>{{apiMigrationData.completed | date:'MM/dd/yyyy HH:mm'}}</b>
								</div>

								<div class="border border-secondary-subtle rounded-2 p-2 flex-row gap-2 align-items-center f-1-0" style="flex-basis: 45%"
									 *ngIf="!apiMigrationData.completed">
									<i class="fa fa-user-check text-primary"></i>
									<span class="animated fadeIn">Completed users:</span>
									<b *ngIf="!apiMigrationData.getDataInProcess">{{apiMigrationData.completedUsers?.length || 0}}</b>
									<div class="loading-spinner opacity-50" *ngIf="apiMigrationData.getDataInProcess" style="position: relative; left: unset; border-width: 2px; height: 15px; width: 15px;"></div>
								</div>
								<div class="border border-secondary-subtle rounded-2 p-2 flex-row gap-2 align-items-center f-1-0" style="flex-basis: 45%">
									<i class="fa fa-envelope text-primary"></i>
									<span class="animated fadeIn">Archived emails:</span>
									<b *ngIf="!apiMigrationData.getDataInProcess">{{apiMigrationData.migratedEmails || 0}}</b>
									<div class="loading-spinner opacity-50" *ngIf="apiMigrationData.getDataInProcess" style="position: relative; left: unset; border-width: 2px; height: 15px; width: 15px;"></div>
								</div>
								<div class="border border-secondary-subtle rounded-2 p-2 flex-row gap-2 align-items-center f-1-0" style="flex-basis: 45%">
									<i class="far fa-times-circle text-primary"></i>
									<span class="animated fadeIn">Failed emails:</span>
									<b *ngIf="!apiMigrationData.getDataInProcess">{{apiMigrationData.failedEmails || 0}}</b>
									<div class="loading-spinner opacity-50" *ngIf="apiMigrationData.getDataInProcess" style="position: relative; left: unset; border-width: 2px; height: 15px; width: 15px;"></div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="list-group-item settings-list-item border-0 pb-0">
					<div class="title-section-container flex-column gap-2">
						<b>AWS S3 Bucket</b>
						<span class="lh-base" style="font-size: 0.9em;">Migrate emails from your AWS S3 bucket.</span>
					</div>

					<div class="content-container">
						<btn-c (action)="openStartAWSMigrationPopup();" endIcon="fa fa-exchange-alt">Start AWS Migration</btn-c>
					</div>
				</div>

				<div class="list-group-item settings-list-item" *ngIf="awsMigrationData.data?.length">
					<trustifi-table-c
						class="trustifi-table animated fadeIn" style="flex: 1 0; z-index: 5; min-height: 400px;"
						[list]="awsMigrationData.data"
						[headers]="[
							{text: 'Prefix', sortKey: 'key'},
							{text: 'Status', width: '100px', sortKey: 'status'},
							{text: 'Archived Emails', width: '150px', sortKey: 'migrated_emails'},
							{text: 'Failed Emails', width: '150px', sortKey: 'failed_emails'},
							{text: 'Started At', width: '170px', sortKey: 'started'},
							{text: 'Completed At', width: '170px', sortKey: 'completed'},
						]"
						[cellsPrototype]="[
							{textKey: 'key'},
							{textKey: 'status'},
							{textKey: 'migrated_emails'},
							{textKey: 'failed_emails'},
							{textKey: 'started', textType: 'dateAndTime'},
							{textKey: 'completed', textType: 'dateAndTime', placeholder: 'N/A'},
						]"
						(searchFunction)="searchS3Bucket($event.searchTerm, $event.activeFilters)"
						[options]="{
							defaultSortKey: '-migrated_emails',
							loadingTableFlag: awsMigrationData.getDataInProcess,
							refreshFunction: checkRunningAWSMigration
						}">
					</trustifi-table-c>
				</div>

				<div class="list-group-item settings-list-item" *ngIf="apiMigrationData.processingEvents?.length">
					<trustifi-table-c
						class="trustifi-table animated fadeIn" style="flex: 1 0; z-index: 5; min-height: 400px;"
						[list]="apiMigrationData.archivedUsers"
						[headers]="[
									{text: 'Mailbox', sortKey: 'id'},
									{text: 'Last Processed Email', width: '190px', sortKey: 'lastDate'},
									{text: 'Archived Emails', width: '150px', sortKey: 'migratedEmails'},
									{text: 'Failed Emails', width: '130px', sortKey: 'failedEmails'},
									{text: 'Completed', width: '130px', sortKey: 'completed'},
								]"
						[cellsPrototype]="[
									{textKey: 'id'},
									{textKey: 'lastDate', textType: 'dateAndTime'},
									{textKey: 'migratedEmails'},
									{textKey: 'failedEmails'},
									{textKey: 'completed'},
								]"
						(searchFunction)="searchMigrationMailbox($event.searchTerm, $event.activeFilters)"
						[options]="{
									defaultSortKey: '-migratedEmails',
									loadingTableFlag: apiMigrationData.getDataInProcess,
								}">
					</trustifi-table-c>
				</div>
			</div>
		</div>
	</div>
</div>

<popup-c class="bs-enabled" *ngIf="startApiMigrationPopup?.show"
		 head="Start Archive Migration" size="dynamic" width="630px"
		 (closeCb)="startApiMigrationPopup = null;"
		 (confirmCb)="startAPIMigration()"
		 [buttons]="{cancel: 'Cancel', confirm: {text: 'Confirm', loading: startApiMigrationPopup.migrationInProcess}}">
	<div class="flex-column f-1-0 gap-3" style="overflow: auto; line-height: 1.5;">
		<div style="font-size: 14px;">
			You are about to start an API migration process of your archive from Exchange into {{gs.corpname}}.
		</div>

		<ul class="ps-3">
			<li>Emails from your Exchange tenant will be migrated into the {{gs.corpname}} archive from all users for the selected time period.</li>
			<li>Once the process has started you can click on the "Refresh API Migration Status" button to view the updated status, or click on the "Stop API Migration Process" to stop the process.</li>
		</ul>

		<div class="align-items-center flex-row gap-3">
			<span class="fw-500">Maximum number of years to migrate:</span>
			<input type="number" min="1" max="7" [(ngModel)]="startApiMigrationPopup.archiveYears"/>
		</div>
	</div>
</popup-c>

<popup-c class="bs-enabled" *ngIf="startAWSMigrationPopup?.show"
		 head="Start Archive Migration" size="dynamic" width="650px"
		 (closeCb)="startAWSMigrationPopup = {}"
		 (confirmCb)="startAWSMigration()"
		 [buttons]="{cancel: 'Cancel', confirm: {text: 'Confirm', loading: startAWSMigrationPopup.migrationInProcess}}">
	<div class="flex-column f-1-0 gap-3" style="overflow: auto; line-height: 1.5;">
		<div style="font-size: 14px;">
			You are about to start an AWS migration process of your archive into {{gs.corpname}}.
		</div>

		<ul class="ps-3">
			<li>Emails from your AWS S3 will be migrated into the {{gs.corpname}} archive.</li>
			<li>Once the process begins, click the refresh button to view the latest status updates.</li>
		</ul>

		<div>
			<div class="flex-row gap-3" style="align-items: center; position: relative;">
				<span class="fw-500" style="width: 160px;">Files type:</span>
				<div class="flex-row gap-1" style="align-items: center">
					<input id="id1" type="radio" name="group1" [(ngModel)]="startAWSMigrationPopup.type" value="eml"/>
					<label for="id1" >EML</label>
				</div>
				<div class="flex-row gap-1" style="align-items: center">
					<input id="id2" type="radio" name="group1" [(ngModel)]="startAWSMigrationPopup.type" value="pst"/>
					<label for="id2" >PST/OST</label>
				</div>
				<div class="flex-row gap-1" style="align-items: center">
					<input id="id3" type="radio" name="group1" [(ngModel)]="startAWSMigrationPopup.type" value="zip"/>
					<label for="id3" >ZIP</label>
					<i style="font-weight: 400; margin-bottom: 10px;" class="fas fa-question-circle" tooltip="Migrate ZIP archives with EML files inside"></i>
				</div>
			</div>
			<span style="font-size: 12px;">* Ensure all files in the bucket or folder match the selected type; otherwise, unselected types will fail.</span>
		</div>

		<br/>

		<div style="text-decoration: underline;">
			<b>AWS S3 Information:</b>
		</div>
		<div *ngIf="!startAWSMigrationPopup.trustifiBucket" class="flex-column gap-2">
			<div class="flex-row" style="align-items: center">
				<span class="fw-500" style="width: 160px;">Bucket name:</span>
				<input type="text" style="width: 250px"
					   [(ngModel)]="startAWSMigrationPopup.bucket"/>
			</div>

			<div class="flex-row" style="align-items: center">
				<span class="fw-500" style="width: 160px;">Bucket key:</span>
				<input type="text" style="width: 250px"
					   [(ngModel)]="startAWSMigrationPopup.accessKeyId"/>
			</div>

			<div class="flex-row" style="align-items: center">
				<span class="fw-500" style="width: 160px;">Bucket secret:</span>
				<input type="text" style="width: 250px"
					   [(ngModel)]="startAWSMigrationPopup.secretAccessKey"/>
			</div>
		</div>
		<div class="flex-row" style="align-items: center">
			<span class="fw-500" style="width: 160px;">File/Path prefix (optional):</span>
			<input type="text" style="width: 250px"
				   [(ngModel)]="startAWSMigrationPopup.prefix"/>
		</div>
	</div>
</popup-c>
