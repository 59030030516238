import _ from "lodash";
import util from "util";
import html2pdf from 'html2pdf.js';
import {RouteService} from "../../../../services/routeService";
import {NotificationService} from "../../../../services/notificationService";
import {OutboundTrendsService} from '../../../../services/outboundTrendsService';
import {GeneralService} from "../../../../services/generalService";
import {DICTIONARY} from "../../../../dictionary";
import {Component, NgZone, OnInit, ViewChild} from "@angular/core";
import {Router} from "@angular/router";
import {ChartComponent} from "ng-apexcharts";
import {ConnectionGraphComponent} from "../../../uiComponents/connectionGraph/connection-graph.component";
import {LookAndFeelService} from "../../../../services/lookAndFeelService";

@Component({
	selector: 'outbound-trends-component',
	templateUrl: './outbound-trends.component.html',
})
export class OutboundTrendsComponent implements OnInit {
    constructor(private router:Router,
				public gs:GeneralService,
				private rs:RouteService,
				private outboundTrendsService: OutboundTrendsService,
				private ns:NotificationService,
				public lfs:LookAndFeelService,
				private ngZone:NgZone) {
    }

	@ViewChild('outboundEmailsChart', {static: false}) outboundEmailsChart: ChartComponent;
	@ViewChild('outboundRulesChart', {static: false}) outboundRulesChart: ChartComponent;
	@ViewChild('eventRateChart', {static: false}) eventRateChart: ChartComponent;
	@ViewChild('topSendersChart', {static: false}) topSendersChart: ChartComponent;
	@ViewChild('topRecipientsDomainsChart', {static: false}) topRecipientsDomainsChart: ChartComponent;
	@ViewChild('connectionsGraphEl', {static: false}) connectionsGraphEl: ConnectionGraphComponent;

    dic = DICTIONARY;

    period = this.dic.CONSTANTS.trendsPeriod.last3Days;
    range = {start: new Date(Date.now() - (1000 * 60 * 60 * 24 * 7)), end: new Date()};
    todayDate = new Date();
	userInfo;
	currentQuery;
	batchSize = this.gs.isProductionEnv() ? 3000 : 10;

	dashboardPeriod = [
        this.dic.CONSTANTS.trendsPeriod.lastDay,
        this.dic.CONSTANTS.trendsPeriod.last3Days,
        this.dic.CONSTANTS.trendsPeriod.lastWeek,
        this.dic.CONSTANTS.trendsPeriod.lastMonth,
        this.dic.CONSTANTS.trendsPeriod.last3Months,
        this.dic.CONSTANTS.trendsPeriod.range
    ];
	protected outboundEmails: any;

    showTrendsCustomPeriod;
    trendsData;
	abortLoadingFlag;
	showAbortLoadingEmailsLabel;
	totalEmails: number;
	batchInProcess;
	getTrendsDataInProcess;
    outboundEmailsChartOptions;
    outboundRulesChartOptions;
    eventRateChartOptions;
    topSendersChartOptions;
    topRecipientsDomainsChartOptions;
    connectionsGraphPopup;
    connectionsGraphData:any = {};
    generatePdfInProcess;
    ruleUsagePopupData;
	getDataInProcess;
	showOutboundRulesMenu;
	showOutboundRulesTips;
	showTopSenderMenu;
	showTopRecipientsMenu;
	showConnectionsGraphMenu;
	_ = _;

	ngOnInit(){
		this.gs.getUserInfo(false, (userInfo) => {
			this.userInfo = userInfo;
		});

        this.getTrendsData(false);
    };

	ngOnDestroy(): void {
		this.abortLoadingFlag = true;
	}

    changePeriod = (inputPeriod) => {
        this.period = inputPeriod;
        if (this.period.value === this.dic.CONSTANTS.trendsPeriod.range.value) {
            let range: any = {
                start: this.range.start,
                end: this.range.end
            };

            if (range.start > Date.now() || range.start > range.end) {
                this.ns.showWarnMessage(this.dic.ERRORS.adminTrendsDate);
                return;
            }
            this.showTrendsCustomPeriod = false;
            this.getTrendsData(false);
            return;
        }
        else {
            this.getTrendsData(false);
        }
    };

    getTrendsData = (isReload) => {
		this.currentQuery = {period: this.period.value, size: this.batchSize};
		if (this.period.value === this.dic.CONSTANTS.trendsPeriod.range.value) {
			this.currentQuery.range = this.range;
		}

		this.totalEmails = 0;
		this.batchInProcess = true;
		this.trendsData = null;

		this.getTrendsDataInProcess = true;

		if (isReload) {
			this.getOutboundTrendsBatch();
			return;
		}

        this.rs.getOutboundTrends(this.currentQuery).then((response) => {
            this.trendsData = response;

			// first check if data is in cache
			if (response.created) {
				this.trendsData = response;
				this.totalEmails = response.totalEmails;
				this.prepareData(false);
				this.initTrendsProcessParams();
				return;
			}

			this.getOutboundTrendsBatch();
        }, (err) => {
			this.getTrendsDataInProcess = false;
		});
    };

	getOutboundTrendsBatch = () => {
		this.rs.getOutboundTrendsBatch(this.currentQuery).then((response) => {
			this.totalEmails = response.total;
			this.outboundEmails = response.emails;
			this.gs.userInfo.internalDomains = response.domains;
			this.gs.userInfo.rules = response.rules;

			this.getTrendsDataInProcess = false;

			this.showAbortLoadingEmailsLabel = true;
			this.trendsData = this.outboundTrendsService.parseEmailSentData(this.userInfo, this.outboundEmails, this.currentQuery);

			this.prepareData(false);

			this.getNextEmailsBatch(response.lastCreated, () => {});

		}, (err) => {
			this.initTrendsProcessParams();
		});
	}

	getNextEmailsBatch = (lastCreated, cb) => {
		if (!lastCreated) {
			this.initTrendsProcessParams();
			return cb();
		}

		if (this.abortLoadingFlag) {
			this.initTrendsProcessParams();
			return cb();
		}

		this.currentQuery.lastCreated = lastCreated;

		this.rs.getOutboundTrendsBatch(this.currentQuery).then((response) => {
			if (response.emails?.length) {
				this.outboundEmails = this.outboundEmails.concat(response.emails);
				this.trendsData = this.outboundTrendsService.parseEmailSentData(this.userInfo, this.outboundEmails, this.currentQuery);
				this.prepareData(true);
			}

			this.getNextEmailsBatch(response.lastCreated, cb);
		}, (err) => {
			console.error(err);
			this.getNextEmailsBatch(lastCreated, cb);
		});
	}

	abortLoadingEmails = () => {
		this.abortLoadingFlag = true;
		this.showAbortLoadingEmailsLabel = false;
		this.getTrendsDataInProcess = false;
	}

	getPercentage = (partialNumber, totalRecords) => {
		return totalRecords ? Math.floor(100 * partialNumber / totalRecords) : 0;
	}

	initTrendsProcessParams = () => {
		this.batchInProcess = false;
		this.abortLoadingFlag = false;
		this.getTrendsDataInProcess = false;
		this.showAbortLoadingEmailsLabel = false;
	}

    prepareData = (isUpdate) => {
        this.prepareOutboundTrendsGraph(isUpdate);

        this.prepareRulesGraph(isUpdate);

        this.prepareEventRateGraph(isUpdate);

        this.prepareTopSendersGraph(isUpdate);

        this.prepareTopRecipientsDomainsGraph(isUpdate);

        this.prepareConnectionsGraph(isUpdate);
    };

    prepareOutboundTrendsGraph = (isUpdate) => {
        const series = [];
        this.trendsData.general.series.forEach((categoryName, index) => {
            series.push({
                name: categoryName,
                data: this.trendsData.general.data[index]
            });
        });

		if (isUpdate && this.outboundEmailsChartOptions?.series?.length &&
			this.gs.areArraysEqual(this.trendsData.general.labels, this.outboundEmailsChartOptions.xaxis.categories)) {
			this.outboundEmailsChart.updateSeries(series, true);
			return;
		}

		this.outboundEmailsChartOptions = {
            series: series,
            chart: {
				type: this.trendsData.general.labels?.length === 1 ? "bar" : "area",
                height: '100%',
                toolbar: {
                    show: false
                },
            },

            colors: ['#1a4d99', '#2a9d38', '#d17e38'],
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                width: 4,
                colors: ['transparent']
            },
            xaxis: {
				tickAmount: 4, // Set the maximum number of ticks (labels) to be displayed on the x-axis
				categories: this.trendsData.general.labels,
            },
            yaxis: {
                title: {
                    text: 'Emails'
                }
            },
            fill: {
                opacity: 1
            },
            tooltip: {
                y: {
                    formatter: (val) => {
                        return val + ' emails';
                    }
                }
            }
        };
    };

    prepareRulesGraph = (isUpdate) => {
        this.trendsData.rules.total = this.trendsData.rules.data.reduce((a, b) => a + b, 0);

		if (isUpdate && this.outboundRulesChartOptions?.labels?.length && this.gs.areArraysEqual(this.outboundRulesChartOptions.labels, this.trendsData.rules.labels)) {
			this.outboundRulesChart.updateSeries(this.trendsData.rules.data, true);
			return;
		}

		this.outboundRulesChartOptions = {
            series: this.trendsData.rules.data,
            chart: {
                type: 'donut',
                events: {
                    dataPointSelection: (event, chartContext, config) => {
                        if (!event || !config) {
                            return;
                        }

                        this.ruleUsagePopupData = {
                            show: true,
                            period: this.period,
                            ruleObj: {
                                _id: this.trendsData.rules.rulesIds[config.dataPointIndex]
                                , name: this.trendsData.rules.labels[config.dataPointIndex]
                            }
                        };
                    }
                }
            },
            plotOptions: {
                pie: {
                    donut: {
                        size: '52%'
                    }
                }
            },
            dataLabels: {
                enabled: true,
                formatter: (val, opt) => {
                    return opt.w.globals.series[opt.seriesIndex];
                }
            },
            labels: this.trendsData.rules.labels,
            tooltip: {
                y: {
                    formatter: (val) => {
                        return val + ' emails';
                    }
                }
            }
        }
    };

    prepareEventRateGraph = (isUpdate) => {
        const series = [
            !this.trendsData.events.rate.opened || this.trendsData.events.rate.opened > 1 ? this.trendsData.events.rate.opened : 1,
            !this.trendsData.events.rate.complaint || this.trendsData.events.rate.complaint > 1 ? this.trendsData.events.rate.complaint : 1,
            !this.trendsData.events.rate.bounced || this.trendsData.events.rate.bounced > 1 ? this.trendsData.events.rate.bounced : 1,
        ];

		if (isUpdate && this.eventRateChartOptions) {
			this.eventRateChart.updateSeries(series, true);
			return;
		}

        this.eventRateChartOptions = {
            series: series,
            chart: {
                height: 350,
                type: "radialBar"
            },
            plotOptions: {
                radialBar: {
                    dataLabels: {
                        name: {
                            fontSize: "22px"
                        },
                        value: {
                            fontSize: "15px"
                        },
                        total: {
                            show: true,
                            color: '#000000',
                            label: "Total Recipients",
                            formatter: () => {
                                return this.trendsData.general.total;
                            }
                        }
                    }
                }
            },
            colors: ['#035b1b', '#fd5500', '#f10909'],
            labels: ["Opened", "Complaint", "Bounced"],
            legend: {
                show: true,
                floating: true,
                fontSize: '12px',
                position: 'left',
                offsetX: 0,
                offsetY: 0,
                labels: {
                    useSeriesColors: true,
                },
                markers: {
                    size: 8
                },
                formatter: (seriesName, opts) => {
                    return `${seriesName}: ${seriesName === "Opened" ? this.trendsData.events.rate.opened :
                        seriesName === "Complaint" ? this.trendsData.events.rate.complaint : this.trendsData.events.rate.bounced}%`
                },
                itemMargin: {
                    vertical: 3
                }
            },
        };
    }

    prepareTopSendersGraph = (isUpdate) => {
		const series = [{
			name: 'Emails',
			data: this.trendsData.senders.data
		}];

		if (isUpdate && this.topSendersChartOptions?.xaxis?.categories?.length &&
			this.gs.areArraysEqual(this.trendsData.senders.labels, this.topSendersChartOptions.xaxis.categories)) {
			this.topSendersChart.updateSeries(series, true);
			return;
		}

        this.topSendersChartOptions = {
            series: series,
            chart: {
                type: 'bar',
                height: '100%',
                toolbar: {
                    show: false
                },
                animations: {
                    dynamicAnimation: {
                        enabled: false
                    }
                }
            },
            colors: [
                "#696461"
            ],
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    horizontal: true,
                    barHeight: this.gs.getOptimalBarWidth(this.trendsData.senders.labels.length) + '%',
                }
            },
            dataLabels: {
                total: {
                    enabled: true,
                    offsetX: 0,
                    style: {
                        fontSize: '13px',
                        fontWeight: 900
                    }
                }
            },
            xaxis: {
                categories: this.trendsData.senders.labels,
            },
        };
    };

    prepareTopRecipientsDomainsGraph = (isUpdate) => {
		const series = [{
			name: 'Emails',
			data: this.trendsData.recipientsDomains.data
		}];

		if (isUpdate && this.topRecipientsDomainsChartOptions?.xaxis?.categories?.length &&
			this.gs.areArraysEqual(this.trendsData.recipientsDomains.labels, this.topRecipientsDomainsChartOptions.xaxis.categories)) {
			this.topRecipientsDomainsChart.updateSeries(series, true);
			return;
		}


		this.topRecipientsDomainsChartOptions = {
            series: series,
            chart: {
                type: 'bar',
                height: '100%',
                toolbar: {
                    show: false
                },
                animations: {
                    dynamicAnimation: {
                        enabled: false
                    }
                }
            },
            colors: [
                "#696461"
            ],
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    horizontal: true,
                    barHeight: this.gs.getOptimalBarWidth(this.trendsData.recipientsDomains.labels.length) + '%',
                }
            },
            dataLabels: {
                total: {
                    enabled: true,
                    offsetX: 0,
                    style: {
                        fontSize: '13px',
                        fontWeight: 900
                    }
                }
            },
            xaxis: {
                categories: this.trendsData.recipientsDomains.labels,
            },
        };
    };

    goToReportsPage = () => {
		this.router.navigate([this.dic.CONSTANTS.appStates.adminOutbound, this.dic.CONSTANTS.adminPages.outbound.report]);
    };

    exportSendersToCsv = () => {
        let csvString = "Sender,Count\n";

        for (let i = 0; i < this.trendsData.senders.labels.length; i++) {
            csvString += `${this.trendsData.senders.labels[i]},${this.trendsData.senders.data[i]}\n`;
        }

        this.gs.exportCsv(csvString, `outbound_senders.csv`);
    };

    exportRecipientsToCsv = () => {
        let csvString = "Recipient Domain,Count\n";

        for (let i = 0; i < this.trendsData.recipientsDomains.labels.length; i++) {
            csvString += `${this.trendsData.recipientsDomains.labels[i]},${this.trendsData.recipientsDomains.data[i]}\n`;
        }

        this.gs.exportCsv(csvString, `outbound_recipients_domains.csv`);
    };

    prepareConnectionsGraph = (isUpdate) => {
        if (!this.trendsData.senders.labels) {
            this.trendsData.senders.labels = [];
        }

		this.connectionsGraphData = {
			graphData: [],
			nodeOptionsFn: (node, allLinks) => {
				const sentToCounter = _.filter(allLinks, { target: node.name }).length;
				const isUserNode = _.map(allLinks, 'source').includes(node.name);
				node.color = isUserNode ? 'green' : '';
				node.name = sentToCounter && !isUserNode ? node.name + ` (${sentToCounter})` : node.name;
				node.lineWidth = sentToCounter && !isUserNode ? Math.log(Math.min(sentToCounter, 20))*2 : 1;

				// hide the tooltip that shows numbers of links of a node (because we show another number in label)
				node.hideTooltip = true;
			},
			nodeSearchTxt: '',
			nodeSearchResults: null,
		};

		this.connectionsGraphData.graphData = {
			links: _.map(this.trendsData.connectionsGraph, sentEmail => ({source: sentEmail['Sent from'], target: sentEmail['Recipient'].split('@')[1]}))
		};
    };

    connectionsGraphAllUsers = () => {
        let range: any = {
            start: this.range.start,
            end: this.range.end
        };
        if (this.period.value === this.dic.CONSTANTS.trendsPeriod.range.value) {
            if (range.start > Date.now() || range.start > range.end) {
                this.ns.showWarnMessage(this.dic.ERRORS.adminTrendsDate);
                return;
            }
        }

		this.connectionsGraphPopup = {
			graphData: [],
			nodeOptionsFn: (node, allLinks) => {
				const sentToCounter = _.filter(allLinks, { target: node.name }).length;
				const isUserNode = _.map(allLinks, 'source').includes(node.name);
				node.color = isUserNode ? 'green' : '';
				node.name = sentToCounter && !isUserNode ? node.name + ` (${sentToCounter})` : node.name;
				node.lineWidth = sentToCounter && !isUserNode ? Math.log(Math.min(sentToCounter, 20))*2 : 1;

				// hide the tooltip that shows numbers of links of a node (because we show another number in label)
				node.hideTooltip = true;
			},
			nodeSearchTxt: '',
			nodeSearchResults: null,
			show: true,
			loading: true
		};

		const params = {period: this.period.value, range: range, graph: true};
        this.rs.getOutboundTrends(params).then((response) => {

			this.connectionsGraphPopup.loading = false;

			this.connectionsGraphPopup.graphData = {
				links: _.map(response, sentEmail => ({source: sentEmail['Sent from'], target: sentEmail['Recipient'].split('@')[1]}))
			};

		}, (err) => {
			this.connectionsGraphPopup.loading = false;
		});
    };

    exportChartsToPdf = () => {
        if (!this.trendsData) {
            return;
        }
        this.generatePdfInProcess = true;

        // set the relevant elements' visual properties to be ideal for the PDF page before copying the HTML
        let areaForPdf:any = document.getElementById('trendsChartsContainer');
        areaForPdf.classList.add('trends-pdf-layout');

        // timeout is needed to let the css enough time to update on screen and for the responsive charts to resize
        setTimeout(() => {
            const element = document.getElementById('trendsChartsContainer');
            const opt = {
                margin:       40,
                filename:     'trends_and_insights.pdf',
                image:        { type: 'jpeg', quality: 1 },
                html2canvas:  { scale: 2 , width: 1300, windowWidth: 1550},
                jsPDF:        { unit: 'px', format: 'a4', orientation: 'portrait', hotfixes: ['px_scaling']}
            };

            // New Promise-based usage:
            html2pdf().set(opt).from(element).then(() => {
                setTimeout(() => {
                    this.exitFromPdfGeneration();
                })
            }).save();
        },2000);
    }

    exitFromPdfGeneration = () => {
        this.ns.showInfoMessage(util.format(this.dic.MESSAGES.downloadSuccess, 'Trends and insights data'));

        document.getElementById('trendsChartsContainer').classList.remove('trends-pdf-layout');
        this.generatePdfInProcess = false;
        // rerender charts so they'll fit their container's size
        this.getTrendsDataInProcess = true;

        setTimeout(() => {
			this.ngZone.run(() => {
/*				const gElemement:any = document.getElementById('trendsConnectionsGraph');
				this.activeConnectionGraph.width(gElemement.clientWidth);
				this.activeConnectionGraph.height(gElemement.clientHeight);
				this.activeConnectionGraph.zoomToFit();*/

				this.getTrendsDataInProcess = false;
			});
        },100);
    };

}
