import * as util from 'util';
import _ from 'lodash';
import {RouteService} from "../../../../../services/routeService";
import {NotificationService} from "../../../../../services/notificationService";
import {GeneralService} from "../../../../../services/generalService";
import {DICTIONARY} from "../../../../../dictionary";
import {Component, OnInit} from "@angular/core";

@Component({
	selector: 'inbound-configuration-component',
	templateUrl: './inbound-configuration.component.html',
})
export class InboundConfigurationComponent implements OnInit {
    constructor(public gs:GeneralService,
				private rs:RouteService,
				private ns:NotificationService) {
    }

    format = util.format;
    dic = DICTIONARY;
    detectionMode;
    userInfo;
    config;
    updateUrlRewriteSettingsInProcess;
	updateThirdPartyReportingSettingsInProcess;
    addReviewerInProcess;
    contacts;
    allContacts;
	actionInProcess;
	thirdPartySimulationSkipMTA;
	mta3rdPartyAddress;
	mta3rdPartySeg;
	mta3rdPartySegOptions = [{name: 'Barracuda'}, {name: 'Sophos'}, {name: 'Cisco IronPort'}, {name: 'Other'}]
	onclickNotificationSettingsPopup: any;
	_=_;
	thirdPartyClientVendors = ['microsoft'];
	getConfigDataInProcess;

	ngOnInit() {
		this.getTPRules();

        this.gs.getUserInfo(false, (userInfo) => {
            this.userInfo = userInfo;
        });

		if (!this.gs.isProductionEnv()) {
			this.thirdPartyClientVendors.push('google');
		}
    };

    getTPRules = () => {
		this.getConfigDataInProcess = true;
        this.rs.getTPRules().then((response) => {
            this.config = response.config;

            this.detectionMode = this.config.threat_protection &&
            this.config.threat_protection.scan_threshold &&
            this.config.threat_protection.scan_threshold.level === 1 ?
                this.dic.CONSTANTS.inboundDetectionMode.aggressive :
                this.dic.CONSTANTS.inboundDetectionMode.standard;

			if (!this.config.threat_protection.third_party_report) {
				this.config.threat_protection.third_party_report = {microsoft: {mailbox: ''}};
			}
			Object.keys(this.config.threat_protection.third_party_report).forEach(vendor => {
				this.config.threat_protection.third_party_report[vendor]['originalMailbox'] = this.config.threat_protection.third_party_report[vendor].mailbox;
			})
			this.getConfigDataInProcess = false;
        }, (err) => {
			this.getConfigDataInProcess = false;
		});
    };

    updateDetectionMode = (detectionMode) => {
        if (this.detectionMode === detectionMode) return;
        let body = [], bodyText = '';
        switch (detectionMode) {
            case this.dic.CONSTANTS.inboundDetectionMode.standard:
                bodyText = '"Standard" mode Provides essential security for incoming emails by using more basic threat detection methods. This mode is less likely to cause false-positives, but is not as secure as "Aggressive" mode.';
                break;

            case this.dic.CONSTANTS.inboundDetectionMode.aggressive:
                bodyText = '"Aggressive" mode utilizes all available threat detection methods to provide full protection against all kinds of threats. This mode is the most secure, but may cause more false-positives than "Standard" mode.';
                break;
        }
        body.push(bodyText);

        this.gs.showPopup({
            title: 'Change detection mode',
            subTitle: `You are about to switch to ${this.gs.toCapitalize(detectionMode)} detection mode`,
            body: body,
            type: this.dic.CONSTANTS.popupWarning,
            doneBtnText: 'Change',
            doneCb: () => {
                this.detectionMode = detectionMode;
                this.updateScanThresholdSettings(this.dic.CONSTANTS.inboundThresholdActions.level);
            }
        });
    };

    updateScanThresholdSettings = (action) => {
        let tpData:any = {
            action: action
        };

        switch (action) {
            case this.dic.CONSTANTS.inboundThresholdActions.level:
                tpData.type = this.dic.CONSTANTS.rulesTpAction.thresholdSettings;
                const level = this.dic.CONSTANTS.inboundDetectionModeMap[this.detectionMode];
                tpData.data = {level: level};
                break;
        }
        this.rs.updateTpRules('', tpData).then(() => {
            this.ns.showInfoMessage(this.dic.MESSAGES.changedSuccessfully);
        });
    };

    changeOnClickScanStatus = () => {
		let body, subTitle;
		if (this.config.threat_protection.url_rewrite.enabled) {
			subTitle = `You are about to enable On-click link scan`;
			body = [
				'Links in received emails will be actively scanned for threats when clicked by users.',
				'If you are using any other URL re-writing services, for example Microsoft SafeLinks, it is recommended to disable this service before using Trustifi\'s on-click link scan'
			];
		}
		else {
			subTitle = `You are about to disable On-click link scan`;
			body = ['Links in received emails will no longer be scanned when clicked by users.'];

		}
        this.gs.showPopup({
            title: "On-click Link Scan",
            subTitle: subTitle,
            body: body,
            type: this.dic.CONSTANTS.popupInfo,
            doneBtnText: 'Confirm',
            doneCb: () => {
                this.updateUrlRewriteSettings(this.dic.CONSTANTS.inboundUrlRewriteActions.enabled);
            },
            cancelCb: () => {
                this.config.threat_protection.url_rewrite.enabled = !this.config.threat_protection.url_rewrite.enabled;
            }
        });
    }

    updateUrlRewriteSettings = (action) => {
        if (this.updateUrlRewriteSettingsInProcess) return;
        let tpData:any = {
            action: action,
            type: this.dic.CONSTANTS.rulesTpAction.urlRewrite
        };

        switch (action) {
            case this.dic.CONSTANTS.inboundUrlRewriteActions.enabled:
                tpData.data = {enabled: this.config.threat_protection.url_rewrite.enabled};
                break;

            case this.dic.CONSTANTS.inboundUrlRewriteActions.allowMaliciousOnClick:
                tpData.data = {allow_malicious_onclick: this.config.threat_protection.url_rewrite.allow_malicious_onclick};
                break;

            case this.dic.CONSTANTS.inboundUrlRewriteActions.urlHunting:
                tpData.data = {url_tracking: this.config.threat_protection.url_rewrite.url_tracking};
                break;
        }
        this.updateUrlRewriteSettingsInProcess = true;

        this.rs.updateTpRules('', tpData).then(() => {
            this.updateUrlRewriteSettingsInProcess = false;
            this.ns.showInfoMessage(this.dic.MESSAGES.changedSuccessfully);
        }, (err) => {
			this.updateUrlRewriteSettingsInProcess = false;
		});
    };

	updateDefangeUrl = () => {
		const tpData = {
			type: this.dic.CONSTANTS.rulesTpAction.defangeUrl,
			enabled: this.config.threat_protection.client_defang_malicious_url,
		};

		this.actionInProcess = true;
		this.rs.updateTpRules('', tpData).then(() => {
			this.actionInProcess = false;
		});
	};

	detectThirdPartyReportingEmailChanges = (vendorKey) => {
		this.config.threat_protection.third_party_report[vendorKey].isChanged =
			this.config.threat_protection.third_party_report[vendorKey].mailbox !== this.config.threat_protection.third_party_report[vendorKey].originalMailbox;
	}

	updateThirdPartyReportingSettings = (vendorKey) => {
		if (this.updateThirdPartyReportingSettingsInProcess) return;

		const tpData:any = {
			type: this.dic.CONSTANTS.rulesTpAction.thirdPartyReporting,
			data: {
				vendor: vendorKey,
				mailbox: this.config.threat_protection.third_party_report[vendorKey].mailbox
			}
		};

		this.updateThirdPartyReportingSettingsInProcess = true;

		this.rs.updateTpRules('', tpData).then(() => {
			this.config.threat_protection.third_party_report[vendorKey].originalMailbox = this.config.threat_protection.third_party_report[vendorKey].mailbox;
			this.config.threat_protection.third_party_report[vendorKey].isChanged = false;

			this.updateThirdPartyReportingSettingsInProcess = false;
			this.ns.showInfoMessage(this.dic.MESSAGES.changedSuccessfully);
		}, (err) => {
			this.updateThirdPartyReportingSettingsInProcess = false;
		});
	};

	openOnclickNotificationSettingsPopup = () => {
		this.onclickNotificationSettingsPopup = {
			show: true,
			inProcess: false,
			title: this.config.threat_protection.url_rewrite.notification?.title,
			body: this.config.threat_protection.url_rewrite.notification?.body
		};
	};

	updateOnclickNotificationSettings = () => {
		if (this.onclickNotificationSettingsPopup.inProcess) return;

		this.onclickNotificationSettingsPopup.inProcess = true;
		const tpData:any = {
			type: this.dic.CONSTANTS.rulesTpAction.urlRewrite,
			action: this.dic.CONSTANTS.inboundUrlRewriteActions.notification,
			data: {
				title: this.onclickNotificationSettingsPopup.title,
				body: this.onclickNotificationSettingsPopup.body
			}
		};

		this.rs.updateTpRules('', tpData).then(() => {
			this.onclickNotificationSettingsPopup.inProcess = false;
			this.config.threat_protection.url_rewrite.notification = {
				title: this.onclickNotificationSettingsPopup.title,
				body: this.onclickNotificationSettingsPopup.body
			}
			this.onclickNotificationSettingsPopup = null;
			this.ns.showInfoMessage(this.dic.MESSAGES.changedSuccessfully);
		}, (err) => {
			this.onclickNotificationSettingsPopup.inProcess = false;
		});
	};

	addExisting3rdPartyMta = () => {
		if (this.actionInProcess) return;

		if (!this.mta3rdPartyAddress || !this.mta3rdPartySeg) {
			this.ns.showErrorMessage('Fill MTA and type');
			return;
		}

		if (!this.gs.isValidDomain(this.mta3rdPartyAddress) && !this.gs.isValidIPAddress(this.mta3rdPartyAddress)) {
			this.ns.showErrorMessage('MTA needs to be domain or IP');
			return;
		}

		this.actionInProcess = true;
		const tpData:any = {
			type: this.dic.CONSTANTS.rulesTpAction.thirdPartyMtaSeg,
			action: this.dic.CONSTANTS.actions.add,
			data: {
				mta: this.mta3rdPartyAddress,
				seg: this.mta3rdPartySeg
			}
		};

		this.rs.updateTpRules('', tpData).then(() => {
			this.actionInProcess = false;
			this.config.threat_protection.third_party_seg_mta.push({
				mta: this.mta3rdPartyAddress,
				seg: this.mta3rdPartySeg
			});
			this.mta3rdPartyAddress = '';
			this.mta3rdPartySeg = '';

			this.ns.showInfoMessage(this.dic.MESSAGES.changedSuccessfully);
		}, (err) => {
			this.actionInProcess = false;
		});
	}

	deleteExisting3rdPartyMta = (addressObj) => {
		if (this.actionInProcess) return;

		this.actionInProcess = true;
		const tpData:any = {
			type: this.dic.CONSTANTS.rulesTpAction.thirdPartyMtaSeg,
			action: this.dic.CONSTANTS.actions.delete,
			data: {
				mta: addressObj.mta,
			}
		};

		this.rs.updateTpRules('', tpData).then(() => {
			this.actionInProcess = false;
			_.remove<any>(this.config.threat_protection.third_party_seg_mta, (mtaObj:any) => mtaObj.mta === addressObj.mta);

			this.ns.showInfoMessage(this.dic.MESSAGES.changedSuccessfully);
		}, (err) => {
			this.actionInProcess = false;
		});
	}

	addThirdPartySimulationSkipMta = () => {
		if (this.actionInProcess) return;

		if (!this.thirdPartySimulationSkipMTA) {
			this.ns.showErrorMessage('Fill MTA and type');
			return;
		}
		if (!this.gs.isValidDomain(this.thirdPartySimulationSkipMTA) && !this.gs.isValidIPAddress(this.thirdPartySimulationSkipMTA)  && !this.gs.isCIDRAddress(this.thirdPartySimulationSkipMTA, 24)) {
			this.ns.showErrorMessage('MTA needs to be domain or IP/CIDR');
			return;
		}

		this.actionInProcess = true;
		const tpData:any = {
			type: this.dic.CONSTANTS.rulesTpAction.thirdPartySimulationSkip,
			action: this.dic.CONSTANTS.actions.add,
			data: {
				mta: this.thirdPartySimulationSkipMTA
			}
		};

		this.rs.updateTpRules('', tpData).then(() => {
			this.actionInProcess = false;
			this.config.threat_protection.third_party_simulation_skip.push(this.thirdPartySimulationSkipMTA);
			this.thirdPartySimulationSkipMTA = '';

			this.ns.showInfoMessage(this.dic.MESSAGES.changedSuccessfully);
		}, (err) => {
			this.actionInProcess = false;
		});
	}

	deleteThirdPartySimulationSkipMta = (address) => {
		if (this.actionInProcess) return;

		this.actionInProcess = true;
		const tpData:any = {
			type: this.dic.CONSTANTS.rulesTpAction.thirdPartySimulationSkip,
			action: this.dic.CONSTANTS.actions.delete,
			data: {
				mta: address,
			}
		};

		this.rs.updateTpRules('', tpData).then(() => {
			this.actionInProcess = false;
			_.remove<any>(this.config.threat_protection.third_party_simulation_skip, (mta:any) => mta === address);

			this.ns.showInfoMessage(this.dic.MESSAGES.changedSuccessfully);
		}, (err) => {
			this.actionInProcess = false;
		});
	}
}
