<div id="wrapper" class="app-container top-level-page" #appContainer
	 *ngIf="appLoaded">
    <header class="app-header bs-enabled">
        <div class="flex-row w-100 h-100">
            <!--Global search-->
            <div class="f-1-0">
                <div class="global-search w-100">
                    <div class="search-box flex-row f-1-0">
                        <i class="zmdi zmdi-search"></i>
                        <input type="search" style="padding: 0 25px; margin: 0; flex: 1 0;"
                               id="globalSearchInput"
                               (focus)="searchText ? (showGlobalSearchResults = true) : null"
                               (clickOutside)="showGlobalSearchResults = false;"
                               [exclude]="'#globalSearchResults'"
                               placeholder="Search anything..."
                               [(ngModel)]="searchText"
                               [maxlength]="dic.CONSTANTS.maxCharsInSearch"
                               (keyup)="searchGlobal(searchText)">
                        <a class="clear-x animated fadeIn"
                           *ngIf="searchText && searchText.length"
                           (click)="searchText = ''; searchResults = []; showGlobalSearchResults = false;">
                            <i class="zmdi zmdi-close"></i>
                        </a>
                    </div>
                    <div class="drop-menu flex-column" id="globalSearchResults"
                         [ngClass]="{'active' : showGlobalSearchResults}">
                        <ul (clickOutside)="showGlobalSearchResults = false;"
                            [exclude]="'#globalSearchInput'">
                            <li class="result"
                                *ngFor="let resultPageObj of searchResults | orderBy: 'rate'; trackBy: gs.trackByIndex"
                                (click)="navigateFromSearch(resultPageObj)">
                                <!--normal result-->
                                <a class="flex-row justify-content-between align-items-center"
                                   *ngIf="!resultPageObj.url">
                                    <div class="flex-row gap-2 align-items-baseline">
                                        <span class="fw-bold">{{resultPageObj.title}}</span>
                                        <span class="text-muted" style="font-size: 0.7rem;" *ngIf="resultPageObj.parentPage">/ {{resultPageObj.parentPage.title}}</span>
                                    </div>
                                    <span class="result-tag px-2 py-1">{{resultPageObj.tag || resultPageObj.parentSection.tag}}</span>
                                </a>
                                <!--external link result-->
                                <a class="flex-row justify-content-between align-items-center"
                                   *ngIf="resultPageObj.url"
                                   href="{{resultPageObj.url}}" target="_blank" rel="noopener noreferrer nofollow">
                                    <span class="fw-bold">{{resultPageObj.title}}</span>
                                    <i class="fas fa-external-link-alt" style="position: relative; font-size: 0.8rem;"></i>
                                </a>
                            </li>
                        </ul>
                        <div class="f-1-0 w-100 flex-column centralize" *ngIf="!searchResults?.length">
                            <span>No results found</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex-column align-items-center justify-content-center gap-1 app-page-title">
                <!--page title-->
                <div class="flex-row align-items-start gap-2 align-self-center fs-6 white-text">
                    <i *ngIf="stateIcon" class="{{stateIcon}} color-text"></i>
					<div style="background: var(--bs-text-color); width: 17px; height: 17px; mask-size: auto 17px; -webkit-mask-size: auto 17px; mask-repeat: no-repeat; -webkit-mask-repeat: no-repeat;"
						 [ngStyle]="{'mask-image' : 'url(\'../../images/' + stateIconSvgName + '\')', '-webkit-mask-image' : 'url(\'../../images/' + stateIconSvgName + '\')'}"
						 *ngIf="stateIconSvgName">
					</div>
                    <span class="fw-500 color-text">
                        {{stateTitle}}
                    </span>
                </div>

                <!--admin select dropdown-->
                <div class="admin-select"
                     *ngIf="gs.planAdmins && gs.planAdmins.length > 1 && userInfo.show_multi_tenant &&
                             (currentSection === dic.CONSTANTS.appStates.adminOutbound
                            || currentSection === dic.CONSTANTS.appStates.adminInbound
                            || currentSection === dic.CONSTANTS.appStates.partner
                            || currentSection === dic.CONSTANTS.appStates.archive
                            || currentSection === dic.CONSTANTS.appStates.domainProtection
                            || currentSection === dic.CONSTANTS.appStates.threatSimulation
                            || currentSection === dic.CONSTANTS.appStates.accountCompromised)"
                     [ngClass]="{'is-current-admin' : userInfo && gs.controlAdmin && userInfo.email === gs.controlAdmin.email}">
                    <div class="flex-row hvr-opacity gap-1 align-items-center" id="adminSelectLabel"
                         (click)="showAdminDropdown = !showAdminDropdown; clearSearchAdmin();">
                        <a class="color-text">{{gs.controlAdmin.display_email}}</a>
                        <span class="you-label color-text">
                            (you)
                        </span>
                        <i class="fa fa-chevron-down color-text"></i>
                        <i class="fas fa-exclamation d-none"
                           tooltip="NOTE: You are managing other admin's plan" placement="bottom"></i>
                    </div>
                    <div class="drop-menu p-0"
                         (clickOutside)="showAdminDropdown = false;"
                         [exclude]="'#adminSelectLabel, #clearSearchAdmin'"
                         [ngClass]="{'active' : showAdminDropdown}">
                        <div class="search-box flxRow" style="position: relative;">
                            <input type="search"
                                   [(ngModel)]="searchAdminTxt"
                                   maxlength="{{dic.CONSTANTS.maxCharsInSearch}}"
                                   (keyup)="searchAdmin(searchAdminTxt);"
                                   placeholder="Search admin">
                            <a class="closeRed animated fadeIn"
                               id="clearSearchAdmin"
                               (click)="clearSearchAdmin();"
                               *ngIf="searchAdminTxt"
                               style="font-size: 16px; color: #cccccc; position: absolute; right: 15px; top: calc(50% + 3px); transform: translateY(-50%);">
                                <i class="zmdi zmdi-close"></i>
                            </a>
                        </div>
                        <ul *ngIf="planAdmins?.length">
							<virtual-scroller class="f-1-0" style="max-height: 300px;" [style.height]="planAdmins.length * 41.59 + 'px'" #planAdminsScroll [items]="planAdmins">
								<li class="flex-row gap-2 align-items-center ellipsis"
									*ngFor="let admin of planAdminsScroll.viewPortItems | orderBy: 'toString()' | filter: {hide : '!true'}"
									(click)="changeControlAdmin(admin,true); showAdminDropdown = false;">
									<i class="fas fa-user-tie"></i>
									<i title="EMDR silent mode" *ngIf="gs.reviewerInfo?.is_security_reviewer && admin.alloc_plan_id?.is_silent_mode" class="fas fa-volume-off"></i>
									<span class="ellipsis">{{admin.display_email}}</span>
								</li>
							</virtual-scroller>
                        </ul>
                        <ul *ngIf="!planAdmins?.length">
                            <li class="flex-row gap-2 align-items-center static-menu-item">
                                <span>No results found</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <!--apply policy-->
            <div class="d-flex align-items-center" style="padding-left: 1rem;">
                <button type="button" class="bs-btn gap-2 border-rounded btn-in-header"
                        style="margin-top: 5px;"
                        *ngIf="gs.planAdmins?.length && (
                        currentSection === dic.CONSTANTS.appStates.adminInbound || currentSection === dic.CONSTANTS.appStates.adminOutbound
                        || currentSection === dic.CONSTANTS.appStates.accountCompromised || currentSection === dic.CONSTANTS.appStates.domainProtection)"
                        (click)="installPolicyEl.installPolicy({ planAdmins: gs.planAdmins, controlAdmin: gs.controlAdmin })">
                    <span>Copy Policy</span>
					<i class="fa fa-arrow-alt-circle-down"></i>
                </button>
            </div>

            <!--wizard/support/profile-->
            <div class="flex-row-reverse px-3 gap-4 f-1-0" style="margin-top: 5px;">
                <div class="flex-row gap-2 py-1 px-2 align-items-center profile-menu-container" id="profileMenuContainer">
                    <div class="flex-row align-items-center justify-content-center border-rounded overflow-hidden" style="width: 29.5px; height: 29.5px; background: rgba(255,255,255,0.25);" >
                        <i class="fa fa-spin fa-spinner white-text" *ngIf="!userInfo?.picture"></i>
                        <img class="animated fadeIn" *ngIf="userInfo?.picture" [src]="userInfo.picture">
                    </div>
                    <div class="drop-menu">
                        <ul>
                            <li class="flex-column gap-1 static-menu-item">
                                <span class="fw-bold" style="word-break: break-word;">{{userInfo?.name}}</span>
                            </li>
                            <hr class="mt-0"/>
                            <li class="flex-row gap-2 align-items-center" id="profileMenu_accountSettings" *ngIf="showPersonalPages"
								[routerLink]="['/' + dic.CONSTANTS.appStates.accountDetails, dic.CONSTANTS.accountDetailsPages.myAccount]">
                                <i class="fas fa-cog"></i>
                                <span>Account Settings</span>
                            </li>
                            <li class="flex-row gap-2 align-items-center" id="profileMenu_myPlan" *ngIf="showPersonalPages"
								[routerLink]="['/' + dic.CONSTANTS.appStates.accountDetails, dic.CONSTANTS.accountDetailsPages.myPlan]">
                                <i class="fas fa-wallet"></i>
                                <span>My Plan</span>
                            </li>
                            <li class="flex-row gap-2 align-items-center" id="profileMenu_notifications" *ngIf="showPersonalPages"
								[routerLink]="['/' + dic.CONSTANTS.appStates.accountDetails, dic.CONSTANTS.accountDetailsPages.notifications]">
                                <i class="fas fa-bell"></i>
                                <span>Notifications</span>
                            </li>
                            <hr/>
                            <li class="flex-row gap-2 align-items-center" id="profileMenu_logout"
                                (click)="logout()">
                                <i class="fas fa-sign-out-alt"></i>
                                <span>Log Out</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="flex-row-reverse gap-2 align-items-center" *ngIf="userInfo.type !== dic.CONSTANTS.userTypes.dlp">

                    <support-menu-component *ngIf="showSupportMenu"></support-menu-component>

                    <button type="button" class="bs-btn gap-2 border-rounded btn-in-header"
                            [ngClass]="{'pending': onboardingInfo && onboardingInfo.questionnaire_step && onboardingInfo.questionnaire_step === 'pendingSupport',
                            'only-review': onboardingInstallationDone,
                            'animated fadeIn': !onboardingInstallationDone && !onboardingActiveStep,
                            'attention' : onboardingActiveStep}"
                            *ngIf="showOnboardingButton "
                            (click)="showOnboardingPopup = true;">
                        <span>Trustifi Wizard</span>
						<i class="fa fa-rocket" style="transform: rotate(-45deg) translate(-2px);"></i>
                    </button>

                    <!--what's new notifier-->
                    <div class="mx-2 whats-new-notification-container flex-column centralize position-relative h-100 cursor-default"
                         *ngIf="gs.whatsNewAlert;"
                         (click)="userInfo.whats_new_alert = !userInfo.whats_new_alert;">
                        <i class="fas fa-bullhorn cursor-pointer p-1 animated tada" style="font-size: 1.1rem; color: lightyellow; transform: rotate(-10deg); animation-iteration-count: infinite; animation-duration: 1.5s;"></i>
                        <div class="drop-menu p-0 active" style="height: 75px; width: 400px;"
                             (click)="$event.stopPropagation();">
                            <div class="p-2 h-100 flex-row align-items-center gap-3" style="gap: 9px !important;">
                                <div class="bg-success h-100" style="width: 5px;"></div>
                                <i class="fas fa-sun text-success" style="font-size: 28px;"></i>
                                <div class="flex-column gap-2">
                                    <h6 class="m-0">New Update Alert!</h6>
                                    <span style="font-size: 0.83rem; font-weight: 500;">
                                        Check out the new features in the
                                        <a class="blue-link" [routerLink]="['/' + dic.CONSTANTS.appStates.knowledge, dic.CONSTANTS.supportPages.whatsNew]" (click)="gs.whatsNewAlert = false;">
                                            What's New
                                        </a>
                                        page.
                                    </span>
                                </div>
                                <div class="flex-column h-100 centralize">
                                    <i class="p-1 zmdi zmdi-close closeRed cursor-pointer" (click)="gs.whatsNewAlert = false;"></i>
                                </div>
                            </div>
                        </div>
                    </div>

					<button type="button" class="bs-btn gap-2 border-rounded btn-in-header d-flex centralize" style="width: 20px;"
						 (click)="darkModeToggle()" tooltip="Dark mode" placement="bottom" >
						<i class="fas fa-adjust" style="font-size: 15px; transform: rotate(180deg);"></i>
					</button>
                </div>
            </div>
        </div>
    </header>

    <!--content below header-->
    <div class="app-content-area flxRow" style="flex: 1 0;">

        <!--sidebar-->
        <div class="bs-enabled flxClmn gap-2 app-sidebar" [ngClass]="{'collapsed' : isSidebarCollapsed}">
            <!--logo-->
            <div class="flex-row centralize px-2 py-4" id="appSidebarLogo" style="width: 220px;">
                <img [src]="lfs.logo"/>
            </div>
            <!--collapse/expand button-->
            <div class="app-sidebar-toggle d-flex centralize"
                 (click)="toggleSidebarWidth();">
                <i class="fas fa-angle-double-right"></i>
            </div>
            <!---->
            <!--navigation-->
            <nav class="f-1-0">
                <ul class="p-0" style="font-size: 13px;">
                    <!--menuItem = section (1st level) , childPage = page (2nd level)-->
					<ng-container *ngFor="let menuItem of appPagesNaviData">
						<li class="flex-column sidebar-menu-item"
							*ngIf="menuItem.show && (menuItem.showInSidebar !== false)"
							[ngClass]="{'expanded' : activeItemTitle === menuItem.title,
									   'active' : currentSection === menuItem.state,
									   'no-sub-items' : !menuItem.pages}">
							<div class="w-100 flex-row justify-content-between align-items-center p-3 sidebar-menu-title"
								 (click)="activeItemTitle === menuItem.title ? (activeItemTitle = null) : (activeItemTitle = menuItem.title);
										   !menuItem.pages && navigateToPage(menuItem.state);">
								<div class="flex-row f-1-0">
									<i class="section-icon" [ngClass]="menuItem.iconClass" *ngIf="menuItem.iconClass"></i>
									<div class="section-icon image-icon" style="background: black;"
										 [ngStyle]="{'mask-image' : 'url(\'../../images/' + menuItem.iconSvgName + '\')', '-webkit-mask-image' : 'url(\'../../images/' + menuItem.iconSvgName + '\')'}"
										 *ngIf="menuItem.iconSvgName">
									</div>
									<span class="fw-bold align-self-center">{{menuItem.title}}</span>
									<span *ngIf="menuItem.isNewFeature" class="new-page-btn align-self-center mx-1">New</span>
								</div>
								<i class="fa fa-chevron-down" style="font-size: 7px; transform: scale(1.3,1) rotate(90deg);"
								   *ngIf="menuItem.pages"></i>
							</div>
							<div class="flex-column sidebar-submenu-container">
								<div class="sidebar-submenu-dropdown-title">
									<span>{{menuItem.title}}</span>
								</div>
								<ng-container *ngFor="let childPage of menuItem.pages">
									<a class="mx-2 sidebar-submenu-item d-flex"
									   style="align-items: center; justify-content: space-between;"
									   [ngClass]="{'active' : currentSection === childPage.state || (currentPage === childPage.page && currentSection === menuItem.state)}"
									   *ngIf="childPage.show"
									   (click)="navigateToPage(menuItem.state, childPage.page); $event.stopPropagation();">
										<span>{{childPage.title}}</span>
										<span *ngIf="childPage.isNewFeature" class="new-page-btn">New</span>
										<span *ngIf="childPage.isBetaFeature" class="new-page-btn">Beta</span>
									</a>
								</ng-container>
							</div>
						</li>
					</ng-container>
                </ul>
            </nav>
        </div>

        <main class="app-content-container f-1-0" id="main">
            <div class="flxClmn w100 f-1-0" [ngClass]="{'full-width' : currentSection === dic.CONSTANTS.appStates.knowledge}">
				<router-outlet></router-outlet>
			</div>
        </main>
    </div>

	<onboarding-popup-component *ngIf="showOnboardingPopup"
								[userOnboardingInfo]="onboardingInfo"
								[navigateToStep]="onboardingActiveStep"
								(closeCb)="showOnboardingPopup = false">
	</onboarding-popup-component>

    <apply-policy-component #installPolicyEl></apply-policy-component>

	<notification-toaster-component></notification-toaster-component>
</div>
