<div class="flxClmn">
	<span>
		Choose the incidents of potentially suspicious activities to be identified for specific users, along with the corresponding response actions upon detection
	</span>
	<br/>

	<div>
		<div class="d-flex centralize" style="flex-grow:1;" *ngIf="getAccountCompromisedInProcess">
			<loader height="100" width="100" style="opacity: 0.8;"></loader>
		</div>
		<div class="flex-column gap-4 animated fadeIn" *ngIf="incidentTypes">
			<div class="list-group list-group-flush settings-list-container">
				<div class="list-group-item settings-list-item py-3"
					 *ngFor="let incident of incidentTypes | keyvalue: gs.returnZero">
					<div class="title-section-container flex-column gap-2">
						<div class="flex-row gap-2">
							<b>{{incident.value.name}}</b>
							<span *ngIf="incident.key === 'new_forward_redirect_rules'" class="new-page-btn align-self-center mx-1">New</span>
						</div>
						<span class="lh-base" style="font-size: 0.9em;">{{incident.value.tooltip}}</span>
						<btn-c (action)="getUserEmailRulesForPlan();" *ngIf="incident.key === 'new_forward_redirect_rules'">
							Check existing rules
						</btn-c>
					</div>
					<div class="content-container flex-row gap-3">
						<div class="flex-row gap-3 align-items-center">
							<b>Apply for</b>
							<div
								*ngIf="incident.key === 'high_recipient_compliant_rate' || incident.key === 'high_recipient_hard_bounce_rate'"
								style="width: 250px; z-index: unset; margin: 0; box-sizing: inherit;">
								All users
								<i style="font-weight: 400;" class="fas fa-question-circle"
								   tooltip="This protection is applied by default to all users and cannot be changed or disabled"
								   placement="right"></i>
							</div>
							<app-dropdown-c style="width: 250px;"
										  (click)="openSelectUsersPopup(incident.key);"
										  [text]="accountCompromisedConfig.incident_types[incident.key].enabledUsersText"
										  *ngIf="incident.key !== 'high_recipient_compliant_rate' && incident.key !== 'high_recipient_hard_bounce_rate'"></app-dropdown-c>
						</div>
						<div class="flex-row gap-3 align-items-center">
							<b>and</b>
							<app-dropdown-c style="width: 250px;"
										  [text]="incidentTypes[incident.key].actionTxt.join(', ') || 'Do nothing (Choose)'">
								<li *ngFor="let action of incidentTypes[incident.key].actions | keyvalue"
									[explanationC]="action.value.tooltip"
									(click)="action.value.selected = !action.value.selected; changeIncidentActions(incident.key); $event.stopPropagation();">
									<div class="gap-1 md-checkbox">
										<input type="checkbox" (click)="$event.preventDefault()" [checked]="action.value.selected"/>
										<label><span>{{action.value.display}}</span></label>
									</div>
								</li>
							</app-dropdown-c>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<!--select users popup-->
	<popup-c class="bs-enabled" *ngIf="selectUsersPopup?.show"
		   head="Select Users" size="large"
		   subTitle="Incident type <b>{{dic.CONSTANTS.accountCompromisedIncidentTypes[selectUsersPopup.incidentType].name}}</b> will be applied to the selected users"
		   (closeCb)="selectUsersPopup.show = false;"
		   (confirmCb)="applyUsersToIncidentType()"
		   [buttons]="{confirm: 'Apply'}">
		<div class="flex-column gap-2 f-1-0 overflow-auto">
			<label class="radio-container m-0" style="display: inline-block;">
				All users
				<input type="radio"
					   [(ngModel)]="selectUsersPopup.selectUsersType"
					   [value]="usersSelectionTypes.all"
					   [checked]="selectUsersPopup.selectUsersType === usersSelectionTypes.all">
				<span class="checkmark"></span>
			</label>
			<div class="flex-row gap-1 align-items-center">
				<label class="radio-container m-0" style="display: block; width: fit-content;">
					Specific users:
					<input type="radio"
						   [(ngModel)]="selectUsersPopup.selectUsersType"
						   [value]="usersSelectionTypes.specific"
						   [checked]="selectUsersPopup.selectUsersType === usersSelectionTypes.specific">
					<span class="checkmark"></span>
				</label>
				<span class="animated fadeIn"
					  *ngIf="selectUsersPopup?.selectUsersType === usersSelectionTypes.specific">
					({{_.filter(selectUsersPopup.users, 'selected').length || '0'}} selected)
				</span>
			</div>
			<div class="position-relative f-1-0 flex-column overflow-auto">
				<!--table overlay when disabled-->
				<div class="w-100 h-100"
					 style="background-color: white; position: absolute; top: 0; left: 0; z-index: 20; opacity: 0.6;"
					 *ngIf="selectUsersPopup?.selectUsersType === usersSelectionTypes.all">
				</div>
				<!--users table-->
				<trustifi-table-c
					class="trustifi-table" style="flex: 1 0; z-index: 5;"
					[list]="selectUsersPopup.users"
					[headers]="[
						{text:'Name', sortKey:'name'},
						{text:'Email', sortKey:'email'},
					]"
					[cellsPrototype]="[
						{textKey: 'name'},
						{textKey: 'email'},
					]"
					[options]="{
						itemsNameSingular: 'user',
						defaultSortKey: '-name',
						showRadioButtons: true
					}"
					(searchFunction)="searchUser($event)"
				>
					<!---->
				</trustifi-table-c>
			</div>
		</div>
	</popup-c>

	<popup-c *ngIf="suspiciousMailboxRulesPopup?.show"
			 head="Suspicious Mailbox Rules" size="dynamic"
			 (closeCb)="suspiciousMailboxRulesPopup = null;"
			 (confirmCb)="suspiciousMailboxRulesConfirmPopup()"
			 [buttons]="{confirm: 'Proceed'}">
		<div class="flxClmn f-1-0" style="overflow: auto;">
			<div class="flxClmn f-1-0 gap-2 overflow-auto">
				To use this feature, please verify you have an active integration with the Exchange API and that advanced permissions have been granted

				<btn-c [loading]="suspiciousMailboxRulesPopup.inProcess"
					   [isDisabled]="suspiciousMailboxRulesPopup.inProcess"
					   (action)="getUserEmailRules();">
					Verify access to mailboxes rules
				</btn-c>

				<span *ngIf="suspiciousMailboxRulesPopup.verifyRulesSucces" style="color: darkgreen;">
					Access to mailbox rules is available
				</span>

				<div *ngIf="suspiciousMailboxRulesPopup.verifyRulesFailure" style="color: red;">
					<span>Access to mailbox rules is not available. Make sure the following settings are enabled in the
						<a class="fw-bold blue-link"
						   [routerLink]="['/' + dic.CONSTANTS.appStates.adminOutbound, dic.CONSTANTS.adminPages.outbound.sharedPlan, dic.CONSTANTS.outboundPlanSettingsPageTabs.integrations]"
						   tooltip="Exchange Server Powershell">
							Integrations
						</a> page:</span>
					<ul class="vSpace1" style="margin-top: 0;">
						<li>
							<span>"Microsoft Exchange" integration is enabled and active</span>
						</li>
						<li>
							<span>"Advanced Permissions" have been granted</span>
						</li>
						<li>
							<span>The "Advanced Permissions" must be up to date. Click on the "Update Permissions" button to verify.</span>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</popup-c>
</div>
