import * as util from 'util';
import _ from 'lodash';
import {RouteService} from "../../../../services/routeService";
import {NotificationService} from "../../../../services/notificationService";
import {GeneralService} from "../../../../services/generalService";
import {DICTIONARY} from "../../../../dictionary";
import {Component, OnInit} from "@angular/core";


@Component({
	selector: 'threat-response-component',
	templateUrl: './threat-response.component.html',
})
export class ThreatResponseComponent implements OnInit {
    constructor(public gs:GeneralService,
				private rs:RouteService,
				private ns:NotificationService) {
    }
	_=_;
    dic = DICTIONARY;
    mailboxesType = {all: 'all', specific: 'specific'};
	allowedInboundThreatResponse:any = JSON.parse(JSON.stringify(this.dic.CONSTANTS.inboundThreatResponse));
    userInfo;
    loadingItemsInProcess;
    tpItems;
    tpMailboxes;
    displayEmailPopup;
    emailReviewersActivityPopup;
    graphConnected;
    gsuiteConnected;
    mailboxesTypeSelection;
    threatResponseData;
    showThreatResponsePopup;
    threatResponseInProcess;
    searchMailboxTxt;
    defaultAction;
	isPartnerAdmin;
	searchPlanAdminTxt = '';
	planAdmins;
	selectedPlansToApplyTo = [];
	orderBy = '-created';
	reviewerActions = [
		'Remove'
	];
	selectAllPlanAdmins = false;
	invalidDrag;
	validDrag;
	allowReviewerShowContent: boolean;

	ngOnInit() {
        this.gs.getUserInfo(false, userInfo => {
            this.userInfo = userInfo;
        });

        this.getThreatResponses();
        this.getMailboxesForThreatResponse();
    };


    getThreatResponses = () => {
        if (this.loadingItemsInProcess) {
            return;
        }

        this.loadingItemsInProcess = true;
        this.tpItems = [];
        this.rs.getThreatResponses().then((response) => {
            if (response.exchange_server && response.exchange_server.tid
                && response.exchange_server.enabled) {
                this.graphConnected = true;
            }
            else if (response.gsuite_server && response.gsuite_server.enabled) {
                this.gsuiteConnected = true;
                // rescan is not supported in g-suite
                delete this.allowedInboundThreatResponse.rescan;
            }
			this.allowReviewerShowContent = response.allowReviewerShowContent;

			this.tpItems = response.items;

            // make sure completed is set
            this.tpItems.forEach((tpObj) => {
                if (!tpObj.finished) {
                    const twentyFourHrInMs = 24 * 60 * 60 * 1000;
                    const createdDateObj = new Date(tpObj.created);
                    if (createdDateObj.getTime() + twentyFourHrInMs < (new Date().getTime())) {
                        // add 24 hours from created date and set it as finish date
                        tpObj.finished = new Date(createdDateObj.setHours(createdDateObj.getHours() + 24));
                    }
                }

                // relate ecery matched email in this TP item to it. Necessary when performing action from trustifi-table on that email
                if (tpObj.scanResult.matched_emails && tpObj.scanResult.matched_emails.length) {
                    tpObj.scanResult.matched_emails.forEach(matchedEmail => {
                        matchedEmail.parentTpObj = tpObj;
                    })
                }
            });

            this.loadingItemsInProcess = false;
        }, () => {
            this.loadingItemsInProcess = false;
        });
    };

    getMailboxesForThreatResponse = () => {
        this.rs.getMailboxesForThreatResponse().then((response) => {
            this.tpMailboxes = response;
        }, (err) => {

		});
    };

    searchMailbox = (searchTerm, activeFilters) => {
        this.tpMailboxes.forEach(record => {
            // search
            if (searchTerm) {
                const isFound = searchMailboxTextExecute(record, searchTerm);
                if (!isFound) {
                    record.hide = true;
                    return;
                }
            }

            record.hide = false;
        });
    };

    searchThreatResponse = (searchTerm, activeFilters) => {
        this.tpItems.forEach(record => {
            // search
            if (searchTerm) {
                const isFound = searchTextExecute(record, searchTerm);
                if (!isFound) {
                    record.hide = true;
                    return;
                }
            }

            record.hide = false;
        });
    };

	selectItemAction = (item, action) => {
		switch (action) {
			case 'Remove':
				this.removeThreatItem(item);
				break;
		}
	}

	itemAction = (item: any) => {
		if (item) {
			return ["Remove"];
		}
		return ["Remove"];
	}

    showMatchedEmailBulkActions = () => {
        return [
            this.dic.CONSTANTS.threatResponseMatchedEmailsActions.delete
        ];
    }

    showMatchedEmailActions = (mailboxObj) => {
		const actions = [
			this.dic.CONSTANTS.threatResponseMatchedEmailsActions.reviewersActivity,
			this.dic.CONSTANTS.threatResponseMatchedEmailsActions.delete
		];

		if (this.allowReviewerShowContent) {
			actions.unshift(this.dic.CONSTANTS.threatResponseMatchedEmailsActions.showContent);
		}

		return actions;
    }

    selectMatchedEmailAction = (matchedEmail, action) => {
        if (!matchedEmail) {
            return;
        }

        switch (action) {
            case this.dic.CONSTANTS.threatResponseMatchedEmailsActions.showContent:
                this.getEmailContent(matchedEmail);
                break;

            case this.dic.CONSTANTS.threatResponseMatchedEmailsActions.reviewersActivity:
                this.getEmailReviewersActivity(matchedEmail);
                break;

            case this.dic.CONSTANTS.threatResponseMatchedEmailsActions.delete:
                this.deleteMatchedEmail([matchedEmail]);
                break;
        }
    }

    selectMultipleMatchedEmailsAction = (selectedEmails ,action) => {
        switch (action) {
            case this.dic.CONSTANTS.threatResponseMatchedEmailsActions.delete:
                this.deleteMatchedEmail(selectedEmails);
                break;
        }
    }

    getEmailContent = (emailObj) => {
		if (this.displayEmailPopup && this.displayEmailPopup.inProcess) {
			return;
		}

        if (!emailObj || !emailObj.resource_id) return;
        const parentTpObj = emailObj.parentTpObj;

        const actionInfo = {
            action: 'view',
            mailbox: emailObj.mailbox,
            resourceId: emailObj.resource_id
        };

        this.displayEmailPopup = {
            mailbox: emailObj.mailbox,
            subject: emailObj.subject,
			inProcess: true,
            show: true
        };

        this.rs.threatResponseAction(parentTpObj._id, actionInfo).then(res => {
			if (this.displayEmailPopup) {
				this.displayEmailPopup.inProcess = false;
				this.displayEmailPopup.content = res.content || res.html;
			}

            this.addReviewerAction(parentTpObj, emailObj, 'Email content viewed');
        }, () => {
			this.displayEmailPopup.inProcess = false;
			this.displayEmailPopup.show = false;
        });
    };

    getEmailReviewersActivity = (emailObj) => {
        this.emailReviewersActivityPopup = {
            email: emailObj,
            show: true
        };
    }

    deleteMatchedEmail = (emails) => {
        this.gs.showPopup({
            title: emails.length > 1 ? 'Delete Email' : `Delete ${emails.length} Emails`,
            subTitle: emails.length > 1 ? `Are you sure you want to delete ${emails.length} emails` : `Are you sure you want to delete email "${emails[0].subject}"`,
            body: [],
            type: this.dic.CONSTANTS.popupWarning,
            doneBtnText: 'Delete',
            doneCb: () => {
                this.deleteMultipleMatchedEmails(emails, 0, () => {});
            }
        });
    };

    deleteMultipleMatchedEmails = (emails, idx, cb) => {
        if (idx < emails.length) {
            this.deleteMatchedEmailExecute(emails[idx], (err) => {
                if (err) {
                    return cb(err);
                }
                this.deleteMultipleMatchedEmails(emails, idx + 1, cb);
            });
        }
        else {
            cb();
        }
    };

    deleteMatchedEmailExecute = (emailObj, cb) => {
        const actionInfo = {
            action: 'delete',
            mailbox: emailObj.mailbox,
            resourceId: emailObj.resource_id
        };
        this.rs.threatResponseAction(emailObj.parentTpObj._id, actionInfo).then((response) => {
            this.ns.showInfoMessage('Email deleted successfully');

            this.addReviewerAction(emailObj.parentTpObj, emailObj, 'Deleted email');
            cb();
        }, (err) => {
            if (err && err.data && err.data.message && !err.data.display_bar) {
                this.ns.showErrorMessage(err.data.message);
            }
            cb(err);
        });
    };

    searchMatchedEmail = (tpObj, searchTerm, activeFilters) => {
		tpObj.scanResult.matched_emails.forEach(record => {
            // search
            if (searchTerm) {
                const isFound = searchMatchedEmailTextExecute(record, searchTerm);
                if (!isFound) {
                    record.hide = true;
                    return;
                }
            }

            record.hide = false;
        });
    };

    addReviewerAction = (tpObj, emailObj, reviewerAction) => {
        const matchedEmailObj = tpObj.scanResult.matched_emails.find(itm => itm.resource_id === emailObj.resource_id);
        if (!matchedEmailObj.reviewer_actions) {
            matchedEmailObj.reviewer_actions = [];
        }
        matchedEmailObj.reviewer_actions.push({
            created: new Date(),
            action: reviewerAction,
            reviewer: this.userInfo.email
        });
    }


    threatResponseAction = () => {
        if (!this.graphConnected && !this.gsuiteConnected) {
			const message = util.format(this.dic.ERRORS.mailboxesThreatResponseNoExchange,'<a target="_self" href="/' + this.dic.CONSTANTS.appStates.adminInbound + '/' + this.dic.CONSTANTS.adminPages.inbound.plan + '/' + this.dic.CONSTANTS.inboundPlanSettingsPageTabs.emailFlowIntegration + '"><b><i>Inbound Plan Settings</i></b></a>');
            return this.ns.showErrorMessage(message);
        }

		this.isPartnerAdmin = this.gs.planAdmins && this.gs.planAdmins[0].email === this.userInfo.email;
		if (this.isPartnerAdmin) {
			this.planAdmins = _.cloneDeep(this.gs.planAdmins);
			this.selectPlanAdmins(this.planAdmins[0]); // current plan is selected by default
		}

        this.mailboxesTypeSelection = this.mailboxesType.specific;

        this.tpMailboxes.forEach((mailboxObj) => {
            mailboxObj.selected = false;
        });

        if (this.gsuiteConnected) {
            this.defaultAction = this.dic.CONSTANTS.inboundThreatResponse.link;
        }
		else {
			this.defaultAction = this.dic.CONSTANTS.inboundThreatResponse.rescan;
		}

        this.threatResponseData = {
            mailboxes: [],
            action: this.defaultAction.action,
            type: this.defaultAction.type,
            result: this.dic.CONSTANTS.inboundThreatResponseResults.reviewEmail.name,
            emailsCount: 10,
            items: [],
            item: '',
            file: null,
            error: '',
            range: {start: new Date(Date.now() - (1000 * 60 * 60 * 24 * 7)), end: new Date()},
            step: this.dic.CONSTANTS.threatResponseSteps.selectMailboxes,
            notifyOnFinish: false,
			scanSentItems: false
        };

        this.showThreatResponsePopup = true;
    };

    threatResponseExecute = () => {
        if (this.threatResponseData.emailsCount > this.dic.CONSTANTS.maxEmailScanThreats) {
            this.threatResponseData.error = this.dic.ERRORS.mailboxesEmailScanThreatMax;
            return;
        }

        if (this.threatResponseData.action !== this.dic.CONSTANTS.inboundThreatResponse.rescan.action && !this.threatResponseData.items.length) {
            this.threatResponseData.error = util.format(this.dic.ERRORS.mailboxesEmailScanNoItems, this.threatResponseData.type);
            return;
        }

		let admins = null;
		if (this.mailboxesTypeSelection === this.mailboxesType.all && this.selectedPlansToApplyTo.length && !(this.selectedPlansToApplyTo.length === 1 && this.selectedPlansToApplyTo[0].email === this.userInfo.email)) {
			admins = _.map(this.selectedPlansToApplyTo, 'email');
		}

        const actionData:any = {
            type: this.dic.CONSTANTS.inboundMailboxAction.threatResponse,
            numberOfEmails: this.threatResponseData.emailsCount,
            mailboxes: this.threatResponseData.mailboxes.length ? this.threatResponseData.mailboxes : null,
            action: this.threatResponseData.action,
            items: this.threatResponseData.items,
            earliestDate: this.threatResponseData.range.start,
            latestDate: this.threatResponseData.range.end,
            notifyOnFinish: this.threatResponseData.notifyOnFinish,
			scanSentItems: this.threatResponseData.scanSentItems,
			admins
        };

		if (this.threatResponseData.action === this.dic.CONSTANTS.inboundThreatResponse.link.action ||
			this.threatResponseData.action === this.dic.CONSTANTS.inboundThreatResponse.messageid.action ||
			this.threatResponseData.action === this.dic.CONSTANTS.inboundThreatResponse.sender.action ||
			this.threatResponseData.action === this.dic.CONSTANTS.inboundThreatResponse.keyword.action) {
			actionData.items = this.threatResponseData.items.map(item => item.name);
		}

        if (this.threatResponseData.action === this.dic.CONSTANTS.inboundThreatResponse.rescan.action) {
            actionData.result = '';
        }
        else {
            actionData.result = this.threatResponseData.result;
        }

		this.threatResponseInProcess = true;


        this.rs.createThreatResponse(actionData).then((response) => {
            this.getThreatResponses();

            this.threatResponseInProcess = false;
            this.showThreatResponsePopup = false;

			if (response?.adminErrors?.length) {
				this.ns.showWarnMessage(`Threat Response could not run on these plans: ${response.adminErrors.join(', ')}. Make sure that for all plans the API integration is active and the "Threat Response" feature is enabled`);
			}
        }, err => {
            if (err && err.data && err.data.message) {
                this.threatResponseData.error = err.data.message;
            }
            this.threatResponseInProcess = false;
        });
    };
    selectedMailboxes = () => {
        const mailboxes = this.tpMailboxes.filter(itm => itm.selected).map(itm => itm.email);
        this.threatResponseData.mailboxes = mailboxes;
    };
    threatResponseNextStep = () => {
        if (this.threatResponseData.step === this.dic.CONSTANTS.threatResponseSteps.selectMailboxes) {
            if (this.mailboxesTypeSelection === this.mailboxesType.specific && !this.threatResponseData.mailboxes.length) {
                this.threatResponseData.error = 'You must select at least one mailbox';
                return;
            }
            this.threatResponseData.error = '';
            this.threatResponseData.step = this.dic.CONSTANTS.threatResponseSteps.selectAction;
            return;
        }

        if (this.threatResponseData.step === this.dic.CONSTANTS.threatResponseSteps.selectAction) {
            this.threatResponseData.step = this.dic.CONSTANTS.threatResponseSteps.configData;
			this.threatResponseData.error = '';
            return;
        }

        if (this.threatResponseData.step === this.dic.CONSTANTS.threatResponseSteps.configData) {
            this.threatResponseData.step = this.dic.CONSTANTS.threatResponseSteps.summary;
			this.threatResponseData.error = '';
            return;
        }
    };

    threatResponseBackStep = () => {
        this.searchMailboxTxt = '';
        if (this.threatResponseData.step === this.dic.CONSTANTS.threatResponseSteps.selectAction) {
            this.threatResponseData.step = this.dic.CONSTANTS.threatResponseSteps.selectMailboxes;
			this.threatResponseData.error = '';
            return;
        }

        if (this.threatResponseData.step === this.dic.CONSTANTS.threatResponseSteps.configData) {
            this.threatResponseData.step = this.dic.CONSTANTS.threatResponseSteps.selectAction;
			this.threatResponseData.error = '';
            return;
        }if (this.threatResponseData.step === this.dic.CONSTANTS.threatResponseSteps.summary) {
            this.threatResponseData.step = this.dic.CONSTANTS.threatResponseSteps.configData;
			this.threatResponseData.error = '';
            return;
        }
    };


    exportThreatResponseListToCsv = (type = this.threatResponseData.type) => {
        let csvString = "";
        const list = this.threatResponseData.items;

        switch (type) {
            case this.dic.CONSTANTS.inboundThreatResponse.header.type:
                csvString = "Header";
                break;

            case this.dic.CONSTANTS.inboundThreatResponse.link.type:
                csvString = "Link";
                break;

            case this.dic.CONSTANTS.inboundThreatResponse.sender.type:
                csvString = "Sender";
                break;

            case this.dic.CONSTANTS.inboundThreatResponse.keyword.type:
                csvString = "Keyword";
                break;

			case this.dic.CONSTANTS.inboundThreatResponse.messageid.type:
				csvString = "Messageid";
				break;

            case this.dic.CONSTANTS.inboundThreatResponse.file.type:
                csvString = "File, Hash";
                break;
        }

        if (!list.length) {
            return;
        }

        csvString += "\n";
        list.forEach((item) => {
            if (item.key && item.value) // when type is headers
                csvString += `${item.key}:${item.value}\n`;
            else if (type === this.dic.CONSTANTS.inboundThreatResponse.file.type)
                csvString += `${item.name}, ${item.hash}\n`;
            else
                csvString += `${item.name}\n`;
        });

        this.gs.exportCsv(csvString, `inbound_mailboxes_ThreatResponse_${type}sList.csv`);
    };


    changeThreatResponseAction = (threatKey) => {
        if (!threatKey || this.threatResponseData.action === this.dic.CONSTANTS.inboundThreatResponse[threatKey]?.action) return;

        this.threatResponseData.action = this.dic.CONSTANTS.inboundThreatResponse[threatKey]?.action;
        this.threatResponseData.items = [];
        this.threatResponseData.type = this.dic.CONSTANTS.inboundThreatResponse[threatKey]?.type;
        this.threatResponseData.range = {start: new Date(Date.now() - (1000 * 60 * 60 * 24 * 7)), end: new Date()};
    };

    addThreatItem = (item) => {
        if (!item) return false;
        this.threatResponseData.error = '';

        if (this.threatResponseData.items.length >= this.dic.CONSTANTS.maxThreatScanItems) {
            this.threatResponseData.error =  util.format(this.dic.ERRORS.mailboxesScanThreatItemMax, this.threatResponseData.type);
            return false;
        }

		switch (this.threatResponseData.type) {
			case this.dic.CONSTANTS.inboundThreatResponse.file.type:
				if (_.map(this.threatResponseData.items, 'name').includes(item.name)) {
					this.threatResponseData.error = util.format(this.dic.ERRORS.itemAlreadyExist, item.name);
					return false;
				}
				return true;

			case this.dic.CONSTANTS.inboundThreatResponse.sender.type:
				if (!this.gs.validateEmail(item)) {
					this.threatResponseData.error = util.format(this.dic.ERRORS.invalidEmail, item);
					return false;
				}
				break;

			case this.dic.CONSTANTS.inboundThreatResponse.messageid.type:
				item = item.toLowerCase();
				if (item[0] !== '<') {
					item = `<${item}`;
				}
				if (!item.endsWith('>')) {
					item = `${item}>`;
				}
				break;

			case this.dic.CONSTANTS.inboundThreatResponse.link.type:
				if (item.indexOf('http://') !== 0 && item.indexOf('https://')) {
					this.threatResponseData.error = util.format(this.dic.ERRORS.invalidUrl, item);
					return false;
				}
				break;

			case this.dic.CONSTANTS.inboundThreatResponse.header.type:
				if (item.indexOf(':') < 1) {
					this.threatResponseData.error = this.dic.ERRORS.invalidHeader;
					return false;
				}
				let header = item.trim().split(':');
				//enforce only the first ":" will be splitted
				header = [header.shift(), header.join(':')];
				if (!header[0] || !header[1]) {
					this.threatResponseData.error = this.dic.ERRORS.invalidHeader;
					return false;
				}
				if (_.map(this.threatResponseData.items, 'key').includes(header[0].trim().toLowerCase())) {
					this.threatResponseData.error = util.format(this.dic.ERRORS.itemAlreadyExist, 'header ' + header[0]);
					return false;
				}
				item = {key: header[0].trim().toLowerCase(), value: header[1].trim().toLowerCase()};
				this.threatResponseData.items.push(item);
				return true;
		}

		if (this.threatResponseData.items.some(existingItem => existingItem.name === item)) {
			this.threatResponseData.error = util.format(this.dic.ERRORS.itemAlreadyExist, item);
			return false;
		}

		this.threatResponseData.items.push({name: item});
		this.threatResponseData.item = '';
        return true;
    };

    removeThreatItem = (itemVal) => {
        if (!itemVal) return;
        _.remove<any>(this.threatResponseData.items, item => {return item === itemVal})
        // this.threatResponseData.items.splice(index, 1);
    };

    uploadItemsFromCSV() {
        let file = this.threatResponseData.file;
		if (!file) return;

        this.threatResponseData.error = '';

        let header = this.threatResponseData.type, regex = null;
        const validationFuncAlwaysTrue = () => {return true};
        let validationFunctions:any = [validationFuncAlwaysTrue];

        if (this.threatResponseData.type === this.dic.CONSTANTS.inboundThreatResponse.sender.type) {
            regex = this.dic.CONSTANTS.EMAIL_REGEX;
            validationFunctions = [this.gs.validateEmail];
        }
        this.gs.readCsv(file, [header], null, regex, validationFunctions, (err, results) => {
            if (err) {
                this.threatResponseData.error = err;
                return;
            }
            if (!results || !results[header] || !results[header].length) {
                this.threatResponseData.error = this.dic.ERRORS.noNewResult;
                return;
            }

            let anyItemAdded = false, isItemAdded;
            for (let i = 0; i < results[header].length; i++) {
                isItemAdded = this.addThreatItem(results[header][i]);
                if (!anyItemAdded && isItemAdded) {
                    anyItemAdded = isItemAdded;
                }
            }
            if (!anyItemAdded) {
                this.threatResponseData.error = this.dic.ERRORS.noNewResult;
            }
        });
		this.threatResponseData.file = "";

	};

	dateToString(date) {
		return (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear();
	}

    uploadThreatFiles() {
		let files = this.threatResponseData.file;
        let threatFile;
        _.each(files, (file) => {
            file.isValid = this.gs.checkUploadFileSize(file.size, file.name);
            if (file.isValid) {
                this.gs.fileHash(file, (fileHash) => {
                    threatFile = {
                        name: file.name,
                        hash: fileHash
                    }
                    file.hash = fileHash;
                    this.addThreatItem(threatFile);
                });
            }
        });
		this.threatResponseData.file = [];
    };

	// Dropdown init for single cell in trustifi-table
	showRecordActions = (record) => {
		let actions = [
			record.isExpanded ? 'Hide Details' : 'Show Details',
			this.dic.CONSTANTS.rulesActions.delete
		];

		return actions;
	}

	selectRecordAction = (record, action) => {
		switch (action) {
			case 'Show Details':
				record.isExpanded = true;
				break;
			case 'Hide Details':
				record.isExpanded = false;
				break;
			case this.dic.CONSTANTS.rulesActions.delete:
				this.openDeleteThreatResponsePopup([record]);
				break;
		}
	};

	// Dropdown init for multiple bulk actions
	showMultipleRecordsActions = () => {
		return [
			this.dic.CONSTANTS.rulesActions.delete
		];
	}

	// Sending Selected items from to functions (Trustifi-table multiple-actions Dropdown)
	selectMultipleRecordsAction = (selectedRules, action) => {
		switch (action) {
			case this.dic.CONSTANTS.rulesActions.delete:
				this.openDeleteThreatResponsePopup(selectedRules);
				break;
		}
	}

	// Delete record popup
	openDeleteThreatResponsePopup(records) {
		this.gs.showPopup({
			title: `Delete ${records.length} Threat Response ${records.length > 1 ? 'Records' : 'Record'}`,
			subTitle: `Please note - the selected ${records.length > 1 ? 'records' : 'record'} will be deleted.`,
			body: [],
			type: this.dic.CONSTANTS.popupWarning,
			doneBtnText: 'Delete',
			doneCb: () => {
				records.forEach(record => {
					this.rs.deleteThreatResponseRecord(record._id).then(res => {
						_.remove(this.tpItems, {_id: record._id});
						this.ns.showInfoMessage(`Record deleted successfully`);
					}).catch(err => {
						console.error('Failed to delete record:', err);
					});
				});
			}
		});
	};

	selectPlanAdmins = (item, isSelectAll=null) => {
		if (isSelectAll !== null) {
			this.planAdmins.forEach(planAdmin => {
				if (planAdmin.email === this.userInfo.email) {
					planAdmin.selected = true;
					return;
				}
				planAdmin.selected = isSelectAll;
			});
		}
		else if (item) {
			// minimum of one selected admin
			if (_.filter(this.planAdmins, 'selected').length === 1 && item.selected) {
				return;
			}
			//

			item.selected = !item.selected;
		}

		this.selectedPlansToApplyTo = _.filter(this.planAdmins, 'selected');
		this.mailboxesTypeSelection = this.mailboxesType.all;
	}

	searchPlanAdmin = () => {
		this.planAdmins.forEach(record => {
			// search
			if (this.searchPlanAdminTxt) {
				const isFound = record.display_email && record.display_email.toLowerCase().includes(this.searchPlanAdminTxt.toLowerCase());
				if (!isFound) {
					record.hide = true;
					return;
				}
			}

			record.hide = false;
		});
	}
}

function searchTextExecute(tpObj, searchTerm) {
    searchTerm = searchTerm.toLowerCase();

    for (let i = 0; i < tpObj.scanInfo.mailboxes.length; i++) {
        if (tpObj.scanInfo.mailboxes[i].toLowerCase().indexOf(searchTerm) > -1) {
            return true;
        }
    }

    for (let i = 0; i < tpObj.scanResult.data.length; i++) {
        if (tpObj.scanResult.data[i].name.toLowerCase().indexOf(searchTerm) > -1) {
            return true;
        }
    }

    return (tpObj.scan_result.toLowerCase().indexOf(searchTerm) > -1 ||
        tpObj.sub_type.toLowerCase().indexOf(searchTerm) > -1);
}

function searchMailboxTextExecute(mailbox, searchTerm) {
    searchTerm = searchTerm.toLowerCase();
    return ((mailbox.name && mailbox.name.toLowerCase().indexOf(searchTerm) > -1) ||
        (mailbox.email && mailbox.email.toLowerCase().indexOf(searchTerm) > -1));
}

function searchMatchedEmailTextExecute(email, searchTerm) {
    searchTerm = searchTerm.toLowerCase();
    return ((email.mailbox && email.mailbox.toLowerCase().indexOf(searchTerm) > -1) ||
        (email.subject && email.subject.toLowerCase().indexOf(searchTerm) > -1) ||
        (email.matched_item && email.matched_item.toLowerCase().indexOf(searchTerm) > -1) ||
        (email.message_id && email.message_id.toLowerCase().indexOf(searchTerm) > -1));
}
