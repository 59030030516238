<div>
    <popup-c class="bs-enabled no-content-padding" *ngIf="showApplyPolicyPopup"
           head="Copy Policy" size="large"
           subTitle="Use this tool to copy policies from <b>{{installPolicyData.fromAdmin}}</b> to the selected admins"
           (closeCb)="showApplyPolicyPopup = false;">
        <div class="flex-column f-1-0 pt-2 gap-2" style="padding: 20px; overflow: auto;">
            <!--steps progress-->
            <div class="steps-container">
                <div class="step-tab"
                     [ngClass]="{'active' : installPolicyData.step === dic.CONSTANTS.installPolicySteps.selectAdmins }">
                    Select admins
                </div>
                <div class="step-tab"
                     [ngClass]="{'active' : installPolicyData.step === dic.CONSTANTS.installPolicySteps.selectPolicy }">
                    Select policies
                </div>
                <div class="step-tab"
                     [ngClass]="{'active' : installPolicyData.step === dic.CONSTANTS.installPolicySteps.summary }">
                    Summary & Execution
                </div>
            </div>

            <!--Steps content:-->

            <!--STEP: Select admins-->
            <div class="flex-column overflow-auto animated fadeIn f-1-0 gap-2 p-2 pt-3"
                 *ngIf="installPolicyData.step === dic.CONSTANTS.installPolicySteps.selectAdmins">
                <span>Select one or more admins to copy policies to:</span>
                <div class="flex-column f-1-0 overflow-auto gap-2 p-2">
                    <label class="radio-container m-0" style="display: inline-block;">
                        All admins
                        <input type="radio" name="installPolicyAdmins"
                               [(ngModel)]="adminSelectionTypeSelection"
                               [value]="adminSelectionType.all"
                               [checked]="adminSelectionTypeSelection === adminSelectionType.all">
                        <span class="checkmark"></span>
                    </label>
                    <div class="flex-row gap-1 align-items-center">
                        <label class="radio-container m-0" style="display: block; width: fit-content;">
                            Specific admins:
                            <input type="radio" name="installPolicyAdmins"
                                   [(ngModel)]="adminSelectionTypeSelection"
                                   [value]="adminSelectionType.specific"
                                   [checked]="adminSelectionTypeSelection === adminSelectionType.specific">
                            <span class="checkmark"></span>
                        </label>
                        <span class="animated fadeIn" *ngIf="adminSelectionTypeSelection === adminSelectionType.specific">
                                    ({{_.filter(planAdmins, 'selected').length || '0'}} selected)
                                </span>
                    </div>
                    <div class="position-relative f-1-0 flex-column overflow-auto">
                        <!--table overlay when disabled-->
                        <div class="w-100 h-100" style="background-color: white; position: absolute; top: 0; left: 0; z-index: 20; opacity: 0.6;"
                             *ngIf="adminSelectionTypeSelection === adminSelectionType.all">
                        </div>
                        <trustifi-table-c
                                class="trustifi-table f-1-0" style="z-index: 5;"
                                [list]="planAdmins"
                                [cellsPrototype]="[
								  {html: 'adminCell'},
								  {textKey: 'name'},
							  ]"
                                (searchFunction)="searchAdmin($event.searchTerm, $event.activeFilters)"
                                [options]="{
									itemsNameSingular: 'admin',
									showRadioButtons: true,
									noHeader: true
								}"

                        >
							<ng-template #customCells let-item let-cellId="cellId">
								<!--admin email cell-->
								<div *ngIf="cellId === 'adminCell'" class="h100">
									<div class="flxRow hSpace1" style="align-items: center;">
										<i class="fa fa-user-tie"></i>
										<span>{{item.email}}</span>
									</div>
								</div>
							</ng-template>
                        </trustifi-table-c>
                    </div>
                </div>
            </div>

            <!--STEP: Choose policies-->
            <div class="flxClmn f-1-0 gap-2 p-2 pt-3 animated fadeIn"
                 *ngIf="installPolicyData.step === dic.CONSTANTS.installPolicySteps.selectPolicy">
                <span>Select one or more policy components to copy</span>
                <div class="flxClmn copy-policy-options p-2">
                    <div class="flex-row align-items-center border">
                        <div class="flex-column gap-2 justify-content-center align-items-center bs-col-2">
                            <i class="zmdi zmdi-shield-security" style="font-size: 1rem;"></i>
                            <span class="fw-bold text-center">Outbound<br/>Management</span>
                        </div>
                        <div class="flex-row align-items-center gap-3 flex-wrap justify-content-start" style="padding: 1rem 2rem; border-left: 1px solid lightgray;">
                            <div class="policy-option gap-2"
                                 (click)="installPolicyData.policy.outbound.rules = !installPolicyData.policy.outbound.rules"
                                 [ngClass]="{'active' : installPolicyData.policy.outbound.rules}"
                                 style="width: 180px; cursor: pointer;">
								<div class="cover"></div>
								<input id="outboundRules" type="checkbox"
                                       [(ngModel)]="installPolicyData.policy.outbound.rules"
                                       style="accent-color: white; width: 15px;"/>
                                <span class="form-check-label" style="cursor: pointer;">Outbound Rules</span>
                            </div>
                            <div class="policy-option gap-2"
                                 (click)="installPolicyData.policy.outbound.policy = !installPolicyData.policy.outbound.policy"
                                 [ngClass]="{'active' : installPolicyData.policy.outbound.policy}"
                                 style="width: 180px; cursor: pointer;">
								<div class="cover"></div>
                                <input id="outboundPolicy" type="checkbox"
                                       [(ngModel)]="installPolicyData.policy.outbound.policy"
                                       style="accent-color: white; width: 15px;"/>
                                <span class="form-check-label" style="cursor: pointer;">Outbound Policy</span>
                            </div>
							<div class="policy-option gap-2"
								 (click)="installPolicyData.policy.outbound.enforce_domain = !installPolicyData.policy.outbound.enforce_domain"
								 [ngClass]="{'active' : installPolicyData.policy.outbound.enforce_domain}"
								 style="width: 180px; cursor: pointer;">
								<div class="cover"></div>
								<input id="enforce_domain" type="checkbox"
									   [(ngModel)]="installPolicyData.policy.outbound.enforce_domain"
									   style="accent-color: white; width: 15px;"/>
								<span class="form-check-label" style="cursor: pointer;">Enforce Shared Plan Domain</span>
							</div>
							<div class="policy-option gap-2"
								 (click)="installPolicyData.policy.outbound.send_welcome_email = !installPolicyData.policy.outbound.send_welcome_email"
								 [ngClass]="{'active' : installPolicyData.policy.outbound.send_welcome_email}"
								 style="width: 180px; cursor: pointer;">
								<div class="cover"></div>
								<input id="send_welcome_email" type="checkbox"
									   [(ngModel)]="installPolicyData.policy.outbound.send_welcome_email"
									   style="accent-color: white; width: 15px;"/>
								<span class="form-check-label" style="cursor: pointer;">Send Welcome Email To New Users</span>
							</div>
                        </div>
                    </div>

                    <div class="flex-row align-items-center" style="border: 1px solid lightgray;">
                        <div class="flex-column gap-2 justify-content-center align-items-center bs-col-2"  style="">
                            <i class="fas fa-user-shield" style="font-size: 1rem;"></i>
                            <span class="fw-bold" style="text-align: center;">Inbound<br/>Management</span>
                        </div>
                        <div class="flex-column gap-3" style="padding: 1.5rem 2rem; border-left: 1px solid lightgray;">
                            <div class="flex-row align-items-center gap-3 flex-wrap justify-content-start">
                                <div class="policy-option gap-2"
                                     (click)="installPolicyData.policy.inbound.rules = !installPolicyData.policy.inbound.rules"
                                     [ngClass]="{'active' : installPolicyData.policy.inbound.rules}"
                                     style="width: 180px; cursor: pointer;">
									<div class="cover"></div>
                                    <input id="tpRules" type="checkbox"
                                           [(ngModel)]="installPolicyData.policy.inbound.rules"
                                           style="accent-color: white; width: 15px;"/>
                                    <span class="form-check-label" style="cursor: pointer;">Threat Prevention Rules</span>
                                </div>
                                <div class="policy-option gap-2"
                                     (click)="installPolicyData.policy.inbound.inbound_email_rules =!installPolicyData.policy.inbound.inbound_email_rules"
                                     [ngClass]="{'active' : installPolicyData.policy.inbound.inbound_email_rules}"
                                     style="width: 180px; cursor: pointer;">
									<div class="cover"></div>
                                    <input id="inboundEmailRules" type="checkbox"
                                           [(ngModel)]="installPolicyData.policy.inbound.inbound_email_rules"
                                           style="accent-color: white; width: 15px;"/>
                                    <span class="form-check-label" style="cursor: pointer;">Inbound Email Rules</span>
                                </div>
								<div class="policy-option gap-2"
									 (click)="installPolicyData.policy.inbound.phishing_bypass =!installPolicyData.policy.inbound.phishing_bypass"
									 [ngClass]="{'active' : installPolicyData.policy.inbound.phishing_bypass}"
									 style="width: 180px; cursor: pointer;">
									<div class="cover"></div>
									<input id="phishing_bypass" type="checkbox"
										   [(ngModel)]="installPolicyData.policy.inbound.phishing_bypass"
										   style="accent-color: white; width: 15px;"/>
									<span class="form-check-label" style="cursor: pointer;">Phishing Simulation Bypass</span>
								</div>
                            </div>
                            <div class="flex-column gap-2">
                                <div class="flex-row gap-3 flex-wrap justify-content-start">
                                    <div class="policy-option gap-2"
                                         (click)="installPolicyData.policy.inbound.configuration.senders_whitelist = !installPolicyData.policy.inbound.configuration.senders_whitelist"
                                         [ngClass]="{'active' : installPolicyData.policy.inbound.configuration.senders_whitelist}"
                                         style="width: 180px; cursor: pointer;">
										<div class="cover"></div>
                                        <input id="senderAllowList" type="checkbox"
                                               [(ngModel)]="installPolicyData.policy.inbound.configuration.senders_whitelist"
                                               style="accent-color: white; width: 15px;"/>
                                        <span class="form-check-label" style="cursor: pointer;">Sender Allowlist</span>
                                    </div>
									<div class="policy-option gap-2"
										 (click)="installPolicyData.policy.inbound.configuration.senders_whitelist_advanced = !installPolicyData.policy.inbound.configuration.senders_whitelist_advanced"
										 [ngClass]="{'active' : installPolicyData.policy.inbound.configuration.senders_whitelist_advanced}"
										 style="width: 180px; cursor: pointer;">
										<div class="cover"></div>
										<input id="senders_whitelist_advanced" type="checkbox"
											   [(ngModel)]="installPolicyData.policy.inbound.configuration.senders_whitelist_advanced"
											   style="accent-color: white; width: 15px;"/>
										<span class="form-check-label" style="cursor: pointer;">Sender Allowlist Advanced Settings</span>
									</div>
                                    <div class="policy-option gap-2"
                                         (click)="installPolicyData.policy.inbound.configuration.senders_blacklist = !installPolicyData.policy.inbound.configuration.senders_blacklist"
                                         [ngClass]="{'active' : installPolicyData.policy.inbound.configuration.senders_blacklist}"
                                         style="width: 180px; cursor: pointer;">
										<div class="cover"></div>
                                        <input id="senderBlockList" type="checkbox"
                                               [(ngModel)]="installPolicyData.policy.inbound.configuration.senders_blacklist"
                                               style="accent-color: white; width: 15px;"/>
                                        <span class="form-check-label" style="cursor: pointer;" >Sender Blocklist</span>
                                    </div>
                                    <div class="policy-option gap-2"
                                         (click)="installPolicyData.policy.inbound.configuration.links_whitelist = !installPolicyData.policy.inbound.configuration.links_whitelist"
                                         [ngClass]="{'active' : installPolicyData.policy.inbound.configuration.links_whitelist}"
                                         style="width: 180px; cursor: pointer;">
										<div class="cover"></div>
                                        <input id="linksAllowList" type="checkbox"
                                               [(ngModel)]="installPolicyData.policy.inbound.configuration.links_whitelist"
                                               style="accent-color: white; width: 15px;"/>
                                        <span class="form-check-label" style="cursor: pointer;">Links Allowlist</span>
                                    </div>
                                    <div class="policy-option gap-2"
                                         (click)="installPolicyData.policy.inbound.configuration.links_blacklist = !installPolicyData.policy.inbound.configuration.links_blacklist"
                                         [ngClass]="{'active' : installPolicyData.policy.inbound.configuration.links_blacklist}"
                                         style="width: 180px; cursor: pointer;">
										<div class="cover"></div>
                                        <input id="linksBlockList" type="checkbox"
                                               [(ngModel)]="installPolicyData.policy.inbound.configuration.links_blacklist"
                                               style="accent-color: white; width: 15px;"/>
                                        <span class="form-check-label" style="cursor: pointer;" >Links Blocklist</span>
                                    </div>
                                    <div class="policy-option gap-2"
                                         (click)="installPolicyData.policy.inbound.configuration.attachments_whitelist = !installPolicyData.policy.inbound.configuration.attachments_whitelist"
                                         [ngClass]="{'active' : installPolicyData.policy.inbound.configuration.attachments_whitelist}"
                                         style="width: 180px; cursor: pointer;">
										<div class="cover"></div>
                                        <input id="attachmentAllowList" type="checkbox"
                                               [(ngModel)]="installPolicyData.policy.inbound.configuration.attachments_whitelist"
                                               style="accent-color: white; width: 15px;"/>
                                        <span class="form-check-label" style="cursor: pointer;" >Attachments Allowlist</span>
                                    </div>
                                    <div class="policy-option gap-2"
                                         (click)="installPolicyData.policy.inbound.configuration.attachments_blacklist = !installPolicyData.policy.inbound.configuration.attachments_blacklist"
                                         [ngClass]="{'active' : installPolicyData.policy.inbound.configuration.attachments_blacklist}"
                                         style="width: 180px; cursor: pointer;">
										<div class="cover"></div>
                                        <input id="attachmentsBlockList" type="checkbox"
                                               [(ngModel)]="installPolicyData.policy.inbound.configuration.attachments_blacklist"
                                               style="accent-color: white; width: 15px;"/>
                                        <span class="form-check-label" style="cursor: pointer;" >Attachments Blocklist</span>
                                    </div>
                                    <div class="policy-option gap-2"
                                         (click)="installPolicyData.policy.inbound.configuration.block_files_types = !installPolicyData.policy.inbound.configuration.block_files_types"
                                         [ngClass]="{'active' : installPolicyData.policy.inbound.configuration.block_files_types}"
                                         style="width: 180px; cursor: pointer;">
										<div class="cover"></div>
                                        <input id="blockFileTypes" type="checkbox"
                                               [(ngModel)]="installPolicyData.policy.inbound.configuration.block_files_types"
                                               style="accent-color: white; width: 15px;"/>
                                        <span class="form-check-label" style="cursor: pointer;" >Block File Types</span>
                                    </div>
                                    <div class="policy-option gap-2"
                                         (click)="installPolicyData.policy.inbound.configuration.block_files_types_extensions = !installPolicyData.policy.inbound.configuration.block_files_types_extensions"
                                         [ngClass]="{'active' : installPolicyData.policy.inbound.configuration.block_files_types_extensions}"
                                         style="width: 180px; cursor: pointer;">
										<div class="cover"></div>
                                        <input id="blockFileTypesExtensions" type="checkbox"
                                               [(ngModel)]="installPolicyData.policy.inbound.configuration.block_files_types_extensions"
                                               style="accent-color: white; width: 15px;"/>
                                        <span class="form-check-label" style="cursor: pointer;" >Uncommon File Extensions</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

					<div class="flex-row align-items-center border">
						<div class="flex-column gap-2 justify-content-center align-items-center bs-col-2">
							<i class="fas fa-lock" style="font-size: 1rem;"></i>
							<span class="fw-bold text-center">Domain<br/>Protection</span>
						</div>
						<div class="flex-row align-items-center gap-3 flex-wrap justify-content-start" style="padding: 1rem 2rem; border-left: 1px solid lightgray;">
							<div class="policy-option gap-2"
								 (click)="installPolicyData.policy.domainProtection.domain_impersonation_enabled = !installPolicyData.policy.domainProtection.domain_impersonation_enabled"
								 [ngClass]="{'active' : installPolicyData.policy.domainProtection.domain_impersonation_enabled}"
								 style="width: 180px; cursor: pointer;">
								<div class="cover"></div>
								<input id="domain_impersonation_enabled" type="checkbox"
									   [(ngModel)]="installPolicyData.policy.domainProtection.domain_impersonation_enabled"
									   style="accent-color: white; width: 15px;"/>
								<span class="form-check-label" style="cursor: pointer;">Domain Impersonation Configuration</span>
							</div>
						</div>
					</div>

					<div class="flex-row align-items-center border">
						<div class="flex-column gap-2 justify-content-center align-items-center bs-col-2">
							<i class="fa fa-user-secret" style="font-size: 1rem;"></i>
							<span class="fw-bold text-center">Account Takeover<br/>Protection</span>
						</div>
						<div class="flex-row align-items-center gap-3 flex-wrap justify-content-start" style="padding: 1rem 2rem; border-left: 1px solid lightgray;">
							<div class="policy-option gap-2"
								 (click)="installPolicyData.policy.accountTakeover.incident_types = !installPolicyData.policy.accountTakeover.incident_types"
								 [ngClass]="{'active' : installPolicyData.policy.accountTakeover.incident_types}"
								 style="width: 180px; cursor: pointer;">
								<div class="cover"></div>
								<input id="acpConfiguration" type="checkbox"
									   [(ngModel)]="installPolicyData.policy.accountTakeover.incident_types"
									   style="accent-color: white; width: 15px;"/>
								<span class="form-check-label" style="cursor: pointer;">Configuration</span>
							</div>
						</div>
					</div>

				</div>
            </div>

            <!--STEP: Summary-->
            <div class="flxClmn f-1-0 animated fadeIn gap-3 p-2" *ngIf="installPolicyData.step === dic.CONSTANTS.installPolicySteps.summary">
                <div class="list-group list-group-flush" style="padding: 1rem 4rem;">
                    <div class="list-group-item flex-row align-items-center" style="min-height: 100px;">
                        <div class="bs-col-2">
                            <img style="width: 82px; filter: invert(.16) sepia(.8) hue-rotate(170deg) saturate(800%);" src="images/admin-list-icon.png">
                        </div>
                        <div class="bs-col-4">
                            <span>Copy policies from the admin:</span>
                        </div>
                        <div class="bs-col-5 offset-md-1">
                            <span class="fw-bold">{{installPolicyData.fromAdmin}}</span>
                        </div>
                    </div>
                    <div class="list-group-item flex-row align-items-center" style="min-height: 100px;">
                        <div class="bs-col-2">
                            <img style="width: 72px; filter: none;" src="images/triple-arrow-right.png">
                        </div>
                        <div class="bs-col-4">
                            <span>Copy these policies:</span>
                        </div>
                        <div class="bs-col-5 offset-md-1">
                            <div class="flex-column gap-3" style="max-height: 130px; overflow: auto;">
                                        <span *ngIf="installPolicyData.policy.outbound.rules"
                                              style="color: var(--bs-primary);">
                                            <i class="fas fa-check" style="font-size: 0.8em;"></i> Outbound Rules</span>
                                <span *ngIf="installPolicyData.policy.outbound.policy"
                                      style="color: var(--bs-primary);">
                                            <i class="fas fa-check" style="font-size: 0.8em;"></i> Outbound Policy</span>

								<span *ngIf="installPolicyData.policy.outbound.enforce_domain"
									  style="color: var(--bs-primary);">
                                            <i class="fas fa-check" style="font-size: 0.8em;"></i> Enforce Shared Plan Domain</span>
								<span *ngIf="installPolicyData.policy.outbound.send_welcome_email"
									  style="color: var(--bs-primary);">
                                            <i class="fas fa-check" style="font-size: 0.8em;"></i> Send Welcome Email To New Users</span>

                                <span *ngIf="installPolicyData.policy.inbound.rules"
                                      style="color: var(--bs-primary);">
                                            <i class="fas fa-check" style="font-size: 0.8em;"></i> Threat Prevention Rules</span>
                                <span *ngIf="installPolicyData.policy.inbound.inbound_email_rules"
                                      style="color: var(--bs-primary);">
                                            <i class="fas fa-check" style="font-size: 0.8em;"></i> Inbound Email Rules</span>
								<span *ngIf="installPolicyData.policy.inbound.phishing_bypass"
									  style="color: var(--bs-primary);">
                                            <i class="fas fa-check" style="font-size: 0.8em;"></i> Phishing Simulation Bypass</span>
                                <span *ngIf="installPolicyData.policy.inbound.configuration.senders_whitelist"
                                      style="color: var(--bs-primary);">
                                            <i class="fas fa-check" style="font-size: 0.8em;"></i> Sender Allowlist</span>
								<span *ngIf="installPolicyData.policy.inbound.configuration.senders_whitelist_advanced"
									  style="color: var(--bs-primary);">
                                            <i class="fas fa-check" style="font-size: 0.8em;"></i> Sender Allowlist Advanced Settings</span>
                                <span *ngIf="installPolicyData.policy.inbound.configuration.senders_blacklist"
                                      style="color: var(--bs-primary);">
                                            <i class="fas fa-check" style="font-size: 0.8em;"></i> Sender Blocklist</span>
                                <span *ngIf="installPolicyData.policy.inbound.configuration.links_whitelist"
                                      style="color: var(--bs-primary);">
                                            <i class="fas fa-check" style="font-size: 0.8em;"></i> Links Allowlist</span>
                                <span *ngIf="installPolicyData.policy.inbound.configuration.links_blacklist"
                                      style="color: var(--bs-primary);">
                                            <i class="fas fa-check" style="font-size: 0.8em;"></i> Links Blocklist</span>
                                <span *ngIf="installPolicyData.policy.inbound.configuration.attachments_whitelist"
                                      style="color: var(--bs-primary);">
                                            <i class="fas fa-check" style="font-size: 0.8em;"></i> Attachments Allowlist</span>
                                <span *ngIf="installPolicyData.policy.inbound.configuration.attachments_blacklist"
                                      style="color: var(--bs-primary);">
                                            <i class="fas fa-check" style="font-size: 0.8em;"></i> Attachments Blocklist</span>
                                <span *ngIf="installPolicyData.policy.inbound.configuration.block_files_types"
                                      style="color: var(--bs-primary);">
                                            <i class="fas fa-check" style="font-size: 0.8em;"></i> Block File Types</span>
                                <span *ngIf="installPolicyData.policy.inbound.configuration.block_files_types_extensions"
                                      style="color: var(--bs-primary);">
                                            <i class="fas fa-check" style="font-size: 0.8em;"></i> Uncommon File Extensions</span>
								<span *ngIf="installPolicyData.policy.domainProtection.domain_impersonation_enabled"
									  style="color: var(--bs-primary);">
                                            <i class="fas fa-check" style="font-size: 0.8em;"></i> Domain Impersonation Configuration</span>
								<span *ngIf="installPolicyData.policy.accountTakeover.incident_types"
									  style="color: var(--bs-primary);">
                                            <i class="fas fa-check" style="font-size: 0.8em;"></i> Account Takeover Configuration</span>
                            </div>
                        </div>
                    </div>
                    <div class="list-group-item flex-row align-items-center" style="min-height: 100px;">
                        <div class="bs-col-2">
                            <img style="width: 82px; filter: none;" src="images/admin-list-icon.png">
                        </div>
                        <div class="bs-col-4">
                            <span>Copy policies to admins:</span>
                        </div>
                        <div class="bs-col-5 offset-md-1">
                            <span *ngIf="adminSelectionTypeSelection === adminSelectionType.all">All Admins</span>
                            <div class="flex-column gap-1" *ngIf="adminSelectionTypeSelection === adminSelectionType.specific"
                                 style="max-height: 134px; overflow: auto;">
                                <span *ngFor="let admin of installPolicyData.admins">{{admin}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!--error message and buttons-->
            <div class="flex-row justify-content-between align-items-end mb-3 px-4 w-100">
                <div>
                             <span class="f-1-0 pe-3 text-danger" *ngIf="installPolicyData.error && installPolicyData.step !== dic.CONSTANTS.installPolicySteps.summary">
                                {{installPolicyData.error}}
                            </span>
                    <div class="flex-row align-items-center bg-warning border border-warning gap-3 p-2 animated fadeIn" style="--bs-bg-opacity: .15;"
                         *ngIf="installPolicyData.step === dic.CONSTANTS.installPolicySteps.summary">
                        <i class="fas fa-exclamation-circle text-warning" style="font-size: 1rem;"></i>
                        <div class="flex-column">
                            <div class="flex-row gap-1 align-items-center">
                                <i class="fas fa-circle" style="font-size:0.3em;"></i>
                                <span>These new rules will override the exiting rules in the selected sections.</span>
                            </div>
                            <div class="flex-row gap-1 align-items-center">
                                <i class="fas fa-circle" style="font-size:0.3em;"></i>
                                <span>This action is ireversible.</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex-row gap-2">
                    <btn-c class="popup-action-btn"
                         *ngIf="installPolicyData.step === dic.CONSTANTS.installPolicySteps.selectAdmins"
                         variant="outlined" (action)="showApplyPolicyPopup = false;">
                        Cancel
                    </btn-c>
                    <btn-c class="popup-action-btn"
                         *ngIf="installPolicyData.step !== dic.CONSTANTS.installPolicySteps.selectAdmins"
                         variant="outlined" (action)="installPolicyBackStep();">
                        Back
                    </btn-c>
                    <btn-c class="popup-action-btn"
                         *ngIf="installPolicyData.step !== dic.CONSTANTS.installPolicySteps.summary"
                         (action)="chooseInstallPolicyAdmins(); installPolicyNextStep();">
                        Next
                    </btn-c>
                    <btn-c class="popup-action-btn"
                         *ngIf="installPolicyData.step === dic.CONSTANTS.installPolicySteps.summary"
                         [isDisabled]="installPolicyInProcess"
                         (action)="installPolicyExecute();">
                        Apply changes
                    </btn-c>
                </div>
            </div>
        </div>
    </popup-c>
</div>


