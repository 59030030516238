<div class="flex-column gap-4">
    <span>Integrate your inbound mail flow with Trustifi to protect your mailboxes against phishing, spam, spoofing, graymail, and more.</span>
    <div class="list-group list-group-flush settings-list-container" *ngIf="config">
        <!--Settings: Threats Detection Mode -->
        <div class="list-group-item settings-list-item">
            <div class="title-section-container flex-column gap-2" style="max-width: 20vw;">
                <b>Threats Detection Mode</b>
                <span style="font-size: 0.9em;"></span>
                <span style="font-size: 0.9em;"></span>
            </div>
            <div class="content-container flex-column gap-3">
                <div class="flex-row mb-3 gap-3">
                    <div class="checkbox-button py-2 radio-style" style="width: 120px;"
                         [ngClass]="{'selected' : detectionMode === dic.CONSTANTS.inboundDetectionMode.standard}"
                         (click)="updateDetectionMode(dic.CONSTANTS.inboundDetectionMode.standard)">
                        <label class="radio-container">
                            Standard
                            <input type="radio" readonly
                                   [checked]="detectionMode === dic.CONSTANTS.inboundDetectionMode.standard">
                            <span class="checkmark"></span>
                        </label>
                    </div>
                    <div class="checkbox-button py-2 radio-style" style="width: 120px;"
                         [ngClass]="{'selected' : detectionMode === dic.CONSTANTS.inboundDetectionMode.aggressive}"
                         (click)="updateDetectionMode(dic.CONSTANTS.inboundDetectionMode.aggressive)">
                        <label class="radio-container">
                            Aggressive
                            <input type="radio" readonly
                                   [checked]="detectionMode === dic.CONSTANTS.inboundDetectionMode.aggressive">
                            <span class="checkmark"></span>
                        </label>
                    </div>
                </div>

                <ul class="ps-3" style="list-style-type: circle; line-height: 1.4;">
                    <li>
                        <span><b>Standard:</b> Provides essential security for incoming emails by using more basic threat detection method</span>
                    </li>
                    <li>
                        <span><b>Aggressive:</b> Utilizes all available threat detection methods to provide full protection against all kinds of threats</span>
                    </li>
                </ul>
            </div>
        </div>

        <!--Settings: Reviewers -->
		<div class="list-group-item settings-list-item">
			<div class="title-section-container flex-column gap-2" style="max-width: 20vw;">
				<b>Reviewers</b>
				<span style="font-size: 0.9em;">Reviewers will receive notifications about quarantined email threats sent to your protected mailboxes and will be able to take immediate actions.</span>
			</div>
			<div class="content-container flex-column gap-2" style="z-index: 1;">
				<div class="flex-row gap-2 align-items-end p-2 border border-1 border-info">
					<i class="fa fa-info-circle text-info"></i>
					<span>
							<b>Reviewers Table</b> have been moved to the
							<a class="fw-bold blue-link"
							   [routerLink]="['/' + dic.CONSTANTS.appStates.adminInbound, dic.CONSTANTS.adminPages.inbound.inboundShield, dic.CONSTANTS.inboundShieldSettingsPageTabs.reviewers]">
								Reviewers tab
							</a>
						</span>
				</div>
			</div>
		</div>

		<div class="list-group-item settings-list-item">
            <div class="title-section-container flex-column gap-2" style="max-width: 20vw;">
                <b>On-click Link Scan</b>
                <span style="font-size: 0.9em;">Scans each clicked link in real time to detect phishing and malicious content.</span>
            </div>
            <div class="content-container flex-column gap-4" style="max-width: 630px;" *ngIf="config.threat_protection.url_rewrite">
                <toggle-switch-c id="onClickLinkScanToggle"
                               [(model)]="config?.threat_protection.url_rewrite.enabled"
                               (onChange)="changeOnClickScanStatus()"
                               [isDisabled]="updateUrlRewriteSettingsInProcess"></toggle-switch-c>

                <div class="flex-row gap-3 align-items-center">
                    <span>Allow users to continue to URLs blocked by on-click scan</span>
                    <toggle-switch-c id="allowContinueToURLToggle"
                                   [(model)]="config?.threat_protection.url_rewrite.allow_malicious_onclick"
                                   (onChange)="updateUrlRewriteSettings(dic.CONSTANTS.inboundUrlRewriteActions.allowMaliciousOnClick)"
                                   [isDisabled]="!config?.threat_protection.url_rewrite.enabled || updateUrlRewriteSettingsInProcess"
                                   [hideText]="true"></toggle-switch-c>
                </div>
				<div class="flex-row gap-3 align-items-center">
					<btn-c class="animated fadeIn" style="min-width: 0;"
						   (action)="openOnclickNotificationSettingsPopup()">
						Quarantine Notification Settings
					</btn-c>
				</div>
            </div>
        </div>

		<!--Settings: defange URL -->
		<div class="list-group-item settings-list-item">
			<div class="title-section-container flex-column gap-2" style="max-width: 20vw;">
				<b>Defang Malicious URLs in Threat Analysis</b>
				<span style="font-size: 0.9em;">
                    URLs that have been detected as malicious will be defanged and become unclickable in the "Threat Analysis" view for increased security
                </span>
			</div>
			<div class="content-container flex-column gap-4" style="max-width: 630px;">
				<toggle-switch-c id="urlDefangeToggle"
								 [(model)]="config?.threat_protection.client_defang_malicious_url"
								 (onChange)="updateDefangeUrl();"
								 [isDisabled]="updateUrlRewriteSettingsInProcess"></toggle-switch-c>
			</div>
		</div>

        <!--Settings: URL Hunting -->
        <div class="list-group-item settings-list-item">
            <div class="title-section-container flex-column gap-2" style="max-width: 20vw;">
                <b>URL Hunting</b>
                <span style="font-size: 0.9em;">
                    The <a class='blue-link text-decoration-underline' [routerLink]="['/' + dic.CONSTANTS.appStates.adminInbound, dic.CONSTANTS.adminPages.inbound.urlTracking]">URL Hunting</a> tool allows security teams to track and identify users who clicked on scanned links, and provide additional details like the specific location and browser/device that was used when the link was clicked.
                </span>
            </div>
            <div class="content-container flex-column gap-4" style="max-width: 630px;" *ngIf="config.threat_protection.url_rewrite">
                <toggle-switch-c id="urlHuntingToggle"
                               [(model)]="config?.threat_protection.url_rewrite.url_tracking"
                               (onChange)="updateUrlRewriteSettings(dic.CONSTANTS.inboundUrlRewriteActions.urlHunting)"
                               [isDisabled]="updateUrlRewriteSettingsInProcess"></toggle-switch-c>
            </div>
        </div>

		<!--third_party_seg_mta -->
		<div class="list-group-item settings-list-item">
			<div class="title-section-container flex-column gap-2" style="max-width: 20vw;">
				<b>Secure Email Gateway (SEG)</b>
				<span style="font-size: 0.9em;">Enter your Secure Email Gateway (if applicable) to bypass authentication checks for its emails to avoid misclassifications (e.g. Spoofing, Source Mismatch, Authentication Failure)</span>
			</div>
			<div class="content-container flex-column gap-2">
				<div class="flex-row gap-1">
					<input type="text" style="width: 51%; margin: 0;"
						   placeholder="MTA/IP"
						   (keydown.enter)="addExisting3rdPartyMta();"
						   [(ngModel)]="mta3rdPartyAddress">
					<app-dropdown-c style="width: 150px;"
									text="{{mta3rdPartySeg?.name || mta3rdPartySeg || 'Select Type'}}"
									itemDisplayKey="name"
									[items]="mta3rdPartySegOptions"
									(onItemClicked)="mta3rdPartySeg = $event.item.name"></app-dropdown-c>
					<btn-c style="min-width: 0;"
						   endIcon="fa fa-check"
						   [isDisabled]="!mta3rdPartyAddress || !mta3rdPartySeg || actionInProcess"
						   (action)="addExisting3rdPartyMta();">
					</btn-c>
				</div>
				<div *ngFor="let addressObj of config.threat_protection.third_party_seg_mta;">
					<div class="name ellipsis"
						 style="width: 52%; display: inline-block; max-width: 800px;">{{addressObj.mta}}</div>
					<div class="name ellipsis"
						 style="width: 21%; display: inline-block; max-width: 800px;">{{addressObj.seg}}</div>
					<a class="closeRed action"
					   (click)="deleteExisting3rdPartyMta(addressObj)"
					   [ngClass]="actionInProcess ? 'closeRed disableAction' : 'approveGreen'">
						<i class="fa fa-times"></i>
					</a>
				</div>
			</div>
		</div>

		<!--third_party_simulation_skip -->
		<div class="list-group-item settings-list-item">
			<div class="title-section-container flex-column gap-2" style="max-width: 20vw;">
				<b>Phishing Simulation Bypass</b>
				<span style="font-size: 0.9em;">Enter Phishing Simulation service IPs and/or MTAs to bypass scans and quarantines</span>
			</div>
			<div class="content-container flex-column gap-2">
				<div class="flex-row gap-1">
					<input type="text" style="width: 51%; margin: 0;"
						   placeholder="MTA/IP/CIDR"
						   (keydown.enter)="addThirdPartySimulationSkipMta();"
						   [(ngModel)]="thirdPartySimulationSkipMTA">
					<btn-c style="min-width: 0;"
						   endIcon="fa fa-check"
						   [isDisabled]="!thirdPartySimulationSkipMTA || actionInProcess"
						   (action)="addThirdPartySimulationSkipMta();">
					</btn-c>
				</div>
				<div *ngFor="let address of config.threat_protection.third_party_simulation_skip;">
					<div class="name ellipsis"
						 style="width: 52%; display: inline-block; max-width: 800px;">{{address}}</div>
					<a class="closeRed action"
					   (click)="deleteThirdPartySimulationSkipMta(address)"
					   [ngClass]="actionInProcess ? 'closeRed disableAction' : 'approveGreen'">
						<i class="fa fa-times"></i>
					</a>
				</div>
			</div>
		</div>

		<!--Settings: Third-Party Reporting -->
		<div class="list-group-item settings-list-item">
			<div class="title-section-container flex-column gap-2" style="max-width: 20vw;">
				<b>Report from third-party client</b>
				<span style="font-size: 0.9em;">
					Integrate reporting from third-party email clients to update quarantined email status in Trustifi.
					Emails that have been reported as Spam/Phishing/Safe can be efficiently viewed from the quarantined emails page using built-in filters.
					To enable the integration, please add the email address which is specified to receive third-party reports in your organization.
				</span>
			</div>
			<div class="content-container flex-column gap-3" style="max-width: 630px;">
				<div class="flxRow gap-3 align-items-center" *ngFor="let vendorKey of thirdPartyClientVendors">
					<span class="text-capitalize fw-500" style="min-width: 80px;">{{vendorKey}}:</span>
					<!--normal-->
					<div class="flex-row gap-2 align-items-center" *ngIf="config?.threat_protection.third_party_report[vendorKey]">
						<input class="m-0" type="text" style="width: 300px;"
							   [(ngModel)]="config.threat_protection.third_party_report[vendorKey].mailbox"
							   (keyup)="detectThirdPartyReportingEmailChanges(vendorKey)"
							   (keyup.enter)="config.threat_protection.third_party_report[vendorKey].isChanged && updateThirdPartyReportingSettings(vendorKey)"
							   placeholder="Enter email address to enable">
						<btn-c class="animated fadeIn" style="min-width: 0;"
							   endIcon="fa fa-check"
							   [isDisabled]="!config.threat_protection.third_party_report[vendorKey].isChanged"
							   [loading]="updateThirdPartyReportingSettingsInProcess"
							   (action)="updateThirdPartyReportingSettings(vendorKey)">
						</btn-c>
					</div>
					<loader spinnerWidth="1" *ngIf="!config?.threat_protection.third_party_report[vendorKey] && getConfigDataInProcess" height="35" width="35"></loader>
				</div>
			</div>
		</div>
    </div>
</div>

<popup-c class="overflownV" *ngIf="onclickNotificationSettingsPopup?.show"
		 head="Notification Settings"
		 subTitle="Modify the text displayed in the notifications of URLs blocked by on-click scan."
		 size="dynamic"
		 (closeCb)="onclickNotificationSettingsPopup = null;"
		 (confirmCb)="updateOnclickNotificationSettings()"
		 [buttons]="{cancel: 'Cancel', confirm: {text: 'Confirm', loading: onclickNotificationSettingsPopup.inProcess}}">
	<div class="flxClmn f-1-0 vSpace1" style="overflow: auto;">
		<div class="flxClmn gap-2">
			<span>Title:</span>
			<input type="text" style="width: 100%;" [(ngModel)]="onclickNotificationSettingsPopup.title" placeholder="Malicious Website Ahead">

			<br/>

			<span>Body:</span>
			<textarea style="width: 100%; max-height: 100px;" [(ngModel)]="onclickNotificationSettingsPopup.body" placeholder="This URL has been detected as malicious by Inbound Shield and access to it has been blocked.">
			</textarea>
		</div>
	</div>
</popup-c>
