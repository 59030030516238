import _ from 'lodash';
import {GeneralService} from '../../../services/generalService';
import {RouteService} from '../../../services/routeService';
import {NotificationService} from '../../../services/notificationService';
import {DICTIONARY} from '../../../dictionary';
import {Component, OnInit, ViewChild} from "@angular/core";
import util from "util";
import {TrustifiTableComponent} from "../../uiComponents/trustifi-table/trustifi-table.component";
import {ENV_CONSTS} from "../../../constants";
import {HttpClient} from "@angular/common/http";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";

@Component({
	selector: 'threat-simulation-training-materials-component',
	templateUrl: './threat-simulation-training-materials.component.html',
})
export class ThreatSimulationTrainingMaterialsComponent implements OnInit {

	@ViewChild('materialsListEl') materialsListEl: TrustifiTableComponent;

	constructor(public gs:GeneralService,
				private rs:RouteService,
				private http:HttpClient,
				public sanitizer: DomSanitizer,
				private ns:NotificationService) {
	}

	dic = DICTIONARY;
	_ = _;
	getMaterialsInProcess;
	getMaterialFileDataInProcess;
	currentMaterial;
	previewLinkMaterial: SafeResourceUrl;
	editMaterialInProcess;
	searchMaterialTxt;
	materials: any;
	isThisFeatureSupported;
	validDrag;
	invalidDrag;
	newMaterialPopup;
	alreadyUploadedFiles;
	uploadQueue;

	ngOnInit() {
		this.getThreatSimulationMaterials();
	};

	getThreatSimulationMaterials = () => {
		this.getMaterialsInProcess = true;
		this.rs.getThreatSimulationMaterials().then((response) => {
			this.isThisFeatureSupported = true;

			this.materials = response;

			this.getMaterialsInProcess = false;
		}, (err) => {
			if (err?.data?.message && err.data.message.includes('This feature is not supported')) {
				this.isThisFeatureSupported = false;
			}

			this.getMaterialsInProcess = false;
		});
	};

	showMaterialActions = (materialObj) => {
		return _.compact([
			!materialObj.link_url && _.capitalize(this.dic.CONSTANTS.actions.download),
			_.capitalize(this.dic.CONSTANTS.actions.delete)
		]);
	}

	showBulkMaterialsActions = () => {
		return _.compact([
			_.capitalize(this.dic.CONSTANTS.actions.delete)
		]);
	}

	selectMaterialAction = (materialObj, action) => {
		switch (action.toLowerCase()) {
			case this.dic.CONSTANTS.actions.download:
				this.download([materialObj]);
				return;

			case this.dic.CONSTANTS.actions.delete:
				this.openDeleteFilesPopup([materialObj]);
				break;
		}
	}

	selectMultipleMaterialAction = (selectedMaterials, action) => {
		switch (action.toLowerCase()) {
			case this.dic.CONSTANTS.actions.delete:
				this.openDeleteFilesPopup(selectedMaterials);
				return;
		}
	}

	download(materials) {
		if (!materials?.length) {
			return;
		}

		if (materials.length === 1) {
			this.gs.downloadFile('/admin/threatSimulation/material/content/' + materials[0]._id, materials[0], true, () => {});
		}
		/*else {
			const fileObj = {name: 'secure_attachments.zip', type: 'application/zip', size: _.sumBy(materials, 'size')};
			const fileIdsStr = _.map(materials, '_id').join(',');

			this.gs.downloadFile('/admin/threatSimulation/material/file/' + fileIdsStr, fileObj, false, () => {});
		}*/
	}

	openNewMaterialPopup = () => {
		this.newMaterialPopup = {
			type: 'file',
			link: {
				name: '',
				url: '',
				description: ''
			},
			uploadFile: null,
			addMaterialsInProcess: false,
			error: false,
			show: true
		};
	}

	confirmNewMaterialPopup = () => {
		if (this.newMaterialPopup.type === 'file') {
			if (!this.newMaterialPopup.uploadFile) {
				this.ns.showWarnMessage(util.format(this.dic.ERRORS.noSelected, 'file'));
				this.newMaterialPopup.error = true;
				return;
			}
			if (_.some(this.materials, {name: this.newMaterialPopup.uploadFile.name})) {
				this.newMaterialPopup.error = true;
				this.ns.showWarnMessage(util.format(this.dic.ERRORS.itemAlreadyExist, 'A material named: <b>' + this.newMaterialPopup.uploadFile.name + '</b>'));
				return;
			}

			this.newMaterialPopup.addMaterialsInProcess = true;
			this.uploads();
			return;
		}

		if (this.newMaterialPopup.type === 'link') {
			if (!this.newMaterialPopup.link.name || !this.newMaterialPopup.link.url) {
				this.newMaterialPopup.error = true;
				this.ns.showWarnMessage(util.format(this.dic.ERRORS.cannotBeEmpty, !this.newMaterialPopup.link.name ? 'Name field' : 'URL field'))
				return;
			}
			if (_.some(this.materials, {name: this.newMaterialPopup.link.name})) {
				this.newMaterialPopup.error = true;
				this.ns.showWarnMessage(util.format(this.dic.ERRORS.itemAlreadyExist, 'A material named: <b>' + this.newMaterialPopup.link.name + '</b>'));
				return;
			}

			const linkObj = {
				name: this.newMaterialPopup.link.name,
				description: this.newMaterialPopup.link.description,
				link_url: this.newMaterialPopup.link.url,
			};

			this.newMaterialPopup.addMaterialsInProcess = true;

			this.rs.addThreatSimulationMaterialLink(linkObj).then((res) => {
				this.ns.showInfoMessage(util.format(this.dic.MESSAGES.itemAdded, 'Link <b>' + linkObj.name + '</b>'));
				this.materials.unshift({
					...linkObj,
					_id: res.id,
					type: 'link',
					created: new Date().toISOString()
				});
				this.newMaterialPopup = null;
				this.setCurrentMaterial(this.materials[0]);
				this.newMaterialPopup.addMaterialsInProcess = true;
			}, (err) => {
				this.newMaterialPopup.addMaterialsInProcess = true;
			});
		}
	}

	openDeleteFilesPopup = (materialObjs) => {
		let title, subTitle;
		if (materialObjs.length > 1) {
			title = 'Delete Materials';
			subTitle = `Please note - you are about to delete ${materialObjs.length} materials`;
		}
		else {
			title = 'Delete Material';
			subTitle = `Please note - you are about to delete ${materialObjs[0].name}`;

			if (materialObjs[0].system) {
				return this.ns.showErrorMessage(`Cannot delete system materials`);
			}
		}

		this.gs.showPopup({
			title: title,
			subTitle: subTitle,
			type: this.dic.CONSTANTS.popupWarning,
			doneBtnText: 'Delete',
			doneCb: () => {
				materialObjs.forEach((materialObj) => {
					if (materialObj.system) {
						return;
					}
					this.deleteMaterialExecute(materialObj);
				});
			}
		});
	};

	deleteMaterialExecute(materialObj) {
		this.rs.deleteThreatSimulationMaterials(materialObj._id).then(() => {
			_.remove(this.materials, materialObj);

			if (this.currentMaterial?._id === materialObj._id) {
				this.currentMaterial = null;
			}
			this.ns.showInfoMessage(this.dic.MESSAGES.operationsSuccess);
		});
	};

	uploadMultipleFiles(notIdx, cb) {
		this.gs.uploadMultipleFiles('/admin/threatSimulation/material', this.uploadQueue, notIdx, false).then((res:any) => {
			if (res.data?.error) {
				this.ns.closeMessage(notIdx);
				this.ns.showErrorMessage(res.data.message);
				this.newMaterialPopup.addMaterialsInProcess = false;
				return;
			}

			this.ns.showInfoMessage(util.format(this.dic.MESSAGES.itemAdded, 'Material <b>' + this.uploadQueue[0].name + '</b>'));
			this.materials.unshift({
				name: this.uploadQueue[0].name,
				type: this.uploadQueue[0].type,
				_id: res.data.id,
				created: new Date().toISOString()
			});
			this.newMaterialPopup = null;

			if (res.data && res.data.length) {
				cb(null, notIdx, res.data.length);
			}
			else {
				cb(null, notIdx, 0);
			}
		}, err => {
			console.error(err);
			this.ns.closeMessage(notIdx);
			cb(err);
		});
	}

	searchMaterial = (searchTerm, activeFilters) => {
		this.materials.forEach(materialObj => {
			// search
			if (this.searchMaterialTxt) {
				let isFound = searchTextExecute(materialObj, this.searchMaterialTxt);
				if (!isFound) {
					materialObj.hide = true;
					return;
				}
			}

			// filter
			// no filters in this table

			materialObj.hide = false;
		});
	};

	startEditCurrentMaterial = (fieldName) => {
		this.currentMaterial.edit = {
			...(this.currentMaterial.edit || {}),
			[fieldName]: this.currentMaterial[fieldName] || ''
		};
	}

	confirmEditCurrentMaterial = (fieldName) => {
		if (this.currentMaterial.edit[fieldName] === this.currentMaterial[fieldName]) {
			this.currentMaterial.edit[fieldName] = undefined;
			return;
		}
		if (!this.currentMaterial.edit[fieldName] || this.editMaterialInProcess) {
			return;
		}

		if (_.some(this.materials, {name: this.currentMaterial.edit[fieldName]})) {
			this.ns.showWarnMessage(util.format(this.dic.ERRORS.itemAlreadyExist, 'A material named: <b>' + this.currentMaterial.edit[fieldName] + '</b>'));
			return;
		}

		this.editMaterialInProcess = true;

		const data = {
			type: fieldName,
			value: this.currentMaterial.edit[fieldName]
		};

		this.rs.updateThreatSimulationMaterial(this.currentMaterial._id, data).then(() => {
			this.ns.showInfoMessage(this.dic.MESSAGES.operationsSuccess);
			const materialInList = _.find(this.materials, {name: this.currentMaterial.name});

			this.currentMaterial[fieldName] = this.currentMaterial.edit[fieldName];
			this.currentMaterial.edit[fieldName] = undefined;
			materialInList[fieldName] = this.currentMaterial[fieldName];
			this.editMaterialInProcess = false;

			if (fieldName === 'link_url') {
				this.previewLinkMaterial = this.sanitizer.bypassSecurityTrustResourceUrl(this.currentMaterial.link_url);
			}

		}, err => {
			this.editMaterialInProcess = false;
		});
	}

	setCurrentMaterial = (material) => {
		if (material.name === this.currentMaterial?.name) {
			return;
		}

		this.getMaterialFileDataInProcess = false;

		// empty the content (free the memory)
		if (this.currentMaterial) {
			this.currentMaterial.content = null;
			this.currentMaterial.edit = null;
		}

		this.currentMaterial = material;

		// link material
		if (material.type === 'link') {
			this.previewLinkMaterial = this.sanitizer.bypassSecurityTrustResourceUrl(this.currentMaterial.link_url);
			return;
		}

		const fileExt = (material.name.split('.').pop()).toLowerCase();
		switch (fileExt) {
			case 'pdf':
				this.getMaterialFileDataInProcess = true;
				this.http.get(ENV_CONSTS.attachmentUrl + '/admin/threatSimulation/material/content/' + material._id,{
					responseType: 'arraybuffer',
					headers: this.gs.getHeaders(),
					observe: 'response'
				}).subscribe((response) => {
					if (response && response.body.byteLength === 0) {
						this.ns.showErrorMessage(this.dic.ERRORS.fileDownloadFailed);
					}

					// first check if another item has been chosen while downloading the content
					if (this.currentMaterial.name === material.name) {
						this.currentMaterial = {
							...material,
							content: response,
							edit: null
						};
					}

					this.getMaterialFileDataInProcess = false;
				}, err => {
					console.error(err);
					this.ns.showErrorMessage(this.dic.ERRORS.fileDownloadFailed);
					this.getMaterialFileDataInProcess = false;
				});
				break;

			case 'ppt':
			case 'pptx':
				this.getMaterialFileDataInProcess = true;
				this.rs.getThreatSimulationMaterialContentEnc(material._id).then((enc) => {
					// first check if another item has been chosen while downloading the content
					if (this.currentMaterial.name === material.name) {
						const pptUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${ENV_CONSTS.outsideUrl}/ts/content/${enc}?materialId=${material._id}`;
						// console.log(ENV_CONSTS.outsideUrl, pptUrl);
						this.currentMaterial = {
							...material,
							edit: null,
							pptUrl: this.sanitizer.bypassSecurityTrustResourceUrl(pptUrl)
						};
					}

					this.getMaterialFileDataInProcess = false;
				}, err => {
					console.error(err);
					this.ns.showErrorMessage(this.dic.ERRORS.fileDownloadFailed);
					this.getMaterialFileDataInProcess = false;
				});
				break;
		}
	}

	uploads = () => {
		if (!this.newMaterialPopup.uploadFile) {
			return;
		}

		let files = [this.newMaterialPopup.uploadFile];

		let isValid;
		this.uploadQueue = [];
		isValid = this.calcBlockSizeAndValidate(files);
		if (isValid) {
			let notIdx = this.ns.showInfoMessage(this.dic.MESSAGES.filesBlockUpload, {timeout: 0, showSpinner: true});

			if (files.length < this.dic.CONSTANTS.parallelFilesUploadCount) {
				this.uploadQueue = files;
				this.uploadMultipleFiles(notIdx, (err, notIdx, uploadedFiles) => {
					if (!err) {
						this.ns.overwriteMessage(notIdx, this.dic.MESSAGES.filesUploaded, null, {showSpinner: false});
					}
				});
				return;
			}
			this.alreadyUploadedFiles = 0;
			if (files?.length) {
				this.uploadBlocks(files, notIdx, files.length, () => {});
			}
		}
	}

	uploadBlocks(files, notIdx, totalFilesNumber, cb) {
		this.uploadQueue = files.splice(0, this.dic.CONSTANTS.parallelFilesUploadCount);

		this.uploadMultipleFiles(notIdx, (err, notIdx, uploadedFiles) => {
			// error in upload
			if (err) {
				return cb(err);
			}
			this.alreadyUploadedFiles += uploadedFiles;

			// finished current upload
			if (files.length === 0) {
				this.ns.overwriteMessage(notIdx, this.dic.MESSAGES.filesUploaded, null, {showSpinner: false});
				return cb(null);
			}
			else {
				this.ns.overwriteMessage(notIdx, util.format(this.dic.MESSAGES.uploadMultiBlockProgress, this.alreadyUploadedFiles, totalFilesNumber), null, {showSpinner: true});
			}
			// recursive call on the rest of files to upload
			this.uploadBlocks(files, notIdx, totalFilesNumber, cb);
		});
	}

	calcBlockSizeAndValidate(files) {
		let total = 0;
		_.each(files, file => {
			total += file.size;
		});
		return this.gs.checkUploadFileSize(total, 'Current uploading materials');
	}
}

function searchTextExecute(materialObj, searchTerm) {
	searchTerm = searchTerm.toLowerCase();
	return materialObj.name?.toLowerCase().indexOf(searchTerm) > -1;
}
