let env;
const config = {
	dev: {
		cloudsponge: '4ed475726b3fa947698eaaf68175678c9b0c37aa',
		backendUrl: 'http://localhost:8080/api/i/v1',
		backendHerokuUrl: 'http://localhost:8080/api/i/v1',
		attachmentUrl: 'http://localhost:8080/api/i/v1',
		outsideUrl: 'http://localhost:8080/api/o/v1',
		graphUrl: 'http://localhost:8080',
		scanFilesThreatsUrl: 'http://localhost:8080/api/i/v1',
		scanLinksThreatsUrl: 'http://localhost:8080/api/i/v1',
		auth0Connection: 'Username-Password-Authentication',
		auth0AppId: 'V5Dk3W3f8S26jxCNkCrkIaHbEtbA12Xq',
		ppMode: 'sandbox',
		debug: true
	},
	stage: {
		cloudsponge: '4ed475726b3fa947698eaaf68175678c9b0c37aa',
		backendUrl: 'https://stagebe.trustifi.com/api/i/v1', // https://stagetrustifibackend.herokuapp.com/api/i/v1
		backendHerokuUrl: 'https://stagetrustifibackend.herokuapp.com/api/i/v1',
		attachmentUrl: 'https://stagetrustifiattachmentbackend.herokuapp.com/api/i/v1',
		outsideUrl: 'https://stagebe.trustifi.com/api/o/v1',
		graphUrl: 'https://stagetrustifigraphbackend.herokuapp.com',
		scanFilesThreatsUrl: 'https://stagetrustifithreatsbackend.herokuapp.com/api/i/v1',
		scanLinksThreatsUrl: 'https://stagetrustifithreatsbackend.herokuapp.com/api/i/v1',
		auth0Connection: 'Username-Password-Authentication',
		auth0AppId: 'V5Dk3W3f8S26jxCNkCrkIaHbEtbA12Xq',
		ppMode: 'sandbox',
		debug: false
	},
	stage1: {
		cloudsponge: '4ed475726b3fa947698eaaf68175678c9b0c37aa',
		backendUrl: 'https://stage1trustifibackend.herokuapp.com/api/i/v1',
		backendHerokuUrl: 'https://stage1trustifibackend.herokuapp.com/api/i/v1',
		attachmentUrl: 'https://stage1trustifibackend.herokuapp.com/api/i/v1',
		outsideUrl: 'https://stage1trustifibackend.herokuapp.com/api/o/v1',
		graphUrl: 'https://stage1trustifibackend.herokuapp.com',
		scanFilesThreatsUrl: 'https://stage1trustifibackend.herokuapp.com/api/i/v1',
		scanLinksThreatsUrl: 'https://stage1trustifibackend.herokuapp.com/api/i/v1',
		auth0Connection: 'Username-Password-Authentication',
		auth0AppId: 'V5Dk3W3f8S26jxCNkCrkIaHbEtbA12Xq',
		ppMode: 'sandbox',
		debug: false
	},
	prod: {
		cloudsponge: '4ed475726b3fa947698eaaf68175678c9b0c37aa',
		backendUrl: 'https://be.trustifi.com/api/i/v1',
		backendHerokuUrl: 'https://trustifibackend.herokuapp.com/api/i/v1',
		attachmentUrl: 'https://attachments.trustifi.com/api/i/v1',
		outsideUrl: 'https://be.trustifi.com/api/o/v1',
		graphUrl: 'https://graph.trustifi.com',
		scanFilesThreatsUrl: 'https://url-scan.trustifi.com/api/i/v1',
		scanLinksThreatsUrl: 'https://file-scan.trustifi.com/api/i/v1',
		auth0Connection: 'prod-users',
		auth0AppId: 'wo3WLDFKhoRVAd8aQKswNXGjeeyr6CYK',
		ppMode: 'live',
		debug: false
	}
};

env = location.origin.indexOf('localhost') >= 0 ? config.dev :
	location.origin.indexOf('stage1') >= 0 ? config.stage1 :
		location.origin.indexOf('stage') >= 0 ? config.stage :
			config.prod;

// webAppUrl url must be detected and not being a config param - we cannot manage entire config for each company per env.
//env.webAppUrl = location.protocol + "//" + location.hostname + (location.port ? ":" + location.port : "");
env.webAppUrl = location.origin;

console.log('Back-End uri => ', env.backendUrl);

const paypalSandboxClientId = 'AZLWIb9jGWK74CPeobK4b4fxuaahoNTV-2qPCdZNOKJ2sQyu6KrbvtPJEUAuneLKt-e_xlNh6VVxRgKk';
const paypalLiveClientId = 'AS0vjuB3_CeuNr2PfzfGX5yNPBULNbna_kYDYjEyJ9r2G2N5-Uiyb1NMsdOAO67OFLrKzZj6kjOt-dtw';

export const AUTH_EVENTS = {
	loginSuccess: 'auth-login-success',
	loginFailed: 'auth-login-failed',
	logoutSuccess: 'auth-logout-success',
	sessionTimeout: 'auth-session-timeout',
	notAuthenticated: 'auth-not-authenticated',
	notAuthorized: 'auth-not-authorized',
	twoFactorRequiredError: "two-factor-error",
	clientError: "client-error",
	serverError: "server-error",
	success: "success"
}
export const NOTIF_TIMEOUT = {
	info: 10000,
	warn: 0,
	'400': 0,
	'500': 0
}
export const ERROR_EVENTS = {
	clientError: "client-error",
	serverError: "server-error",
	success: "success",
	tooManyReq: "too-many-requests"
}
export const AUTH0_CONSTS = {
	domain: 'trustifi.auth0.com',
	clientId: env.auth0AppId,
	connection: env.auth0Connection,
	auth0RedirectUrl: env.webAppUrl
}
export const SERVERCONF = {
	paypalMode: env.ppMode,
	paypalSandboxClientId: paypalSandboxClientId,
	paypalLiveClientId: paypalLiveClientId,
	cloudspongeId: env.cloudsponge
}
export const ENV_CONSTS = {
	beBaseGraphUrl: env.graphUrl,
	beBaseUrl: env.backendUrl,
	backendHerokuUrl: env.backendHerokuUrl,
	attachmentUrl: env.attachmentUrl,
	outsideUrl: env.outsideUrl,
	scanFilesThreatsUrl: env.scanFilesThreatsUrl,
	scanLinksThreatsUrl: env.scanLinksThreatsUrl,
	webAppUrl: env.webAppUrl,
	debug: env.debug
}
