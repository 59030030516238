<div class="flxClmn bs-enabled table-filter" style="max-width: 100%; min-width: 310px; height: 420px; overflow: visible; background: white; box-shadow: 0 1px 4px rgba(0,0,0,0.6);"
	 [ngStyle]="{'width' : filterData.tableFilterTemplatesRefName ? '400px' : ''}"
	 *ngIf="filterData.show">
	<div class="flex-row justify-content-between align-items-center gap-2 p-0">
		<span class="fw-bold ps-3">Filter Results By:</span>
		<div class="flex-row align-items-center gap-2">
			<app-dropdown-c *ngIf="savedTemplates?.length" style="width: 165px;"
							[alwaysDrop]="true"
							text="{{selectedFilterTemplate?.name || 'Select template'}}"
							[enableSearch]="true"
							itemDisplayKey="name"
							[items]="savedTemplates"
							(onItemClicked)="selectFilterTemplate($event.item)"
							(onItemDelete)="deleteFilterTemplate($event.item)">
			</app-dropdown-c>
			<a class="blue-link blue-link p-3 ps-1" style="font-size: 0.9rem;" (click)="loadActiveFilters(currentAppliedFilters); filterData.show = false;">
				<i class="zmdi zmdi-close"></i>
			</a>
		</div>
	</div>
	<ul class="list-group list-group-flush accordion-list border border-2 border-start-0 border-end-0 overflow-auto" style="flex: 1 1; border-radius: 0; --accordion-list-item-max-height: 200px;">
		<li class="list-group-item p-0" *ngFor="let filter of filtersDataObj | keyvalue: gs.returnZero; trackBy: gs.trackByIndex">
			<input [id]="_.snakeCase(filter.key)" class="accordion-input" type="checkbox" name="accordion"
				   style="position: absolute; opacity: 0; z-index: -1; height: 0; width: 0;">
			<label class="w-100 px-3 py-2 cursor-pointer" style="min-height: 36px; line-height: 0;" [for]="_.snakeCase(filter.key)">
				<div class="flex-row gap-3 fw-500 align-items-center" style="min-height: 20px;">
					<span class="text-capitalize">{{filter.key}}</span>
					<div class="f-1-0"></div>
					<div class="flxRow-start md-checkbox"
						 tooltip="Make this filter negative"
						 [ngClass]="{'negative-filter-checkbox opacity-75' : !filter.value.isNegative}"
						 *ngIf="filterData.enableNegativeOptions && filter.value.allowNegative">
						<input [id]="_.snakeCase(filter.key) + '_negative_toggle'" style="height: 0;" type="checkbox"
							   [(ngModel)]="filter.value.isNegative"/>
						<label [for]="_.snakeCase(filter.key) + '_negative_toggle'" class="text-danger">Negative</label>
					</div>
					<span class="rounded-circle bg-primary color-text d-flex centralize" style="width: 20px; height: 20px;"
						  *ngIf="_.filter(filter.value.values, 'enabled').length">
						{{_.filter(filter.value.values, 'enabled').length}}
					</span>
					<i class="fa fa-chevron-down accordion-item-arrow"></i>
				</div>
			</label>
			<ul class="list-group list-group-flush accordion-item-content overflow-hidden" style="border-radius: 0; --accordion-list-item-max-height: 1800px;">
				<li class="list-group-item cursor-pointer bg-light"
					*ngFor="let option of filter.value.values; trackBy: gs.trackByIndex"
					(click)="option.enabled = !option.enabled; isSomeValueChanged = true; isDefaultFilterData = false;">
						<div class="flxRow-start md-checkbox"
							 [ngClass]="{'danger-color negative-checkbox colorful-border' : filter.value.isNegative}"
							 [ngStyle]="{'text-transform' : option.doNotCapitalize ? '' : 'capitalize'}">
							<input [id]="_.snakeCase(filter.key) + ' : ' + _.snakeCase(option.key)" style="height: 0;" type="checkbox"
								   [(ngModel)]="option.enabled"/>
							<label>{{option.key}}</label>
						</div>
				</li>
			</ul>
		</li>
	</ul>

	<br/>
	<div class="flex-row justify-content-between align-items-end p-3">
		<btn-c (action)="apply();">
			Apply Filters
		</btn-c>
		<div class="flex-column gap-2 align-items-end f-1-0">
			<a class="blue-link text-danger flex-row gap-1 align-items-center"
			   style="width: 160px;"
			   [hidden]="saveAsTemplateInput"
			   (click)="clear();">
				<i class="fa fa-check opacity-0"></i>
				<i class="fa fa-trash-alt"></i>
				<span>Clear</span>
			</a>
			<!--save as default - template-->
			<a class="blue-link flex-row gap-1 align-items-center animated fadeIn"
			   style="width: 160px;"
			   *ngIf="selectedFilterTemplate && !isSomeValueChanged && !saveAsTemplateInput"
			   (click)="updateTemplateDefault();"
			   [ngClass]="{'fw-bold' : selectedFilterTemplate.default}">
				<i class="fa fa-check" style="color: darkseagreen;" [ngClass]="{'opacity-0' : !selectedFilterTemplate.default}"></i>
				<i class="fa-save" [ngClass]="selectedFilterTemplate.default ? 'fa' : 'far'"></i>
				<span>Save as default</span>
			</a>
			<a class="blue-link flex-row gap-1 align-items-center animated fadeIn"
			   style="width: 160px;"
			   *ngIf="filterData.tableFilterTemplatesRefName && (!selectedFilterTemplate || isSomeValueChanged) && !saveAsTemplateInput"
			   (click)="saveAsTemplateInput = {name: '', error: ''}">
				<i class="fa fa-check" style="opacity: 0;"></i><!--padder-->
				<i class="fa fa-cog"></i>
				<span>Save as template</span>
			</a>
			<div class="flex-row align-items-cetner gap-1 animated fadeIn"
				 style="width: 180px;"
				 *ngIf="saveAsTemplateInput">
				<input type="text" class="f-1-0" style="min-width: 0;"
					   (keyup.enter)="saveTableFiltersTemplate();"
					   [ngClass]="{'validation-error' : saveAsTemplateInput.error}"
					   placeholder="Template name"
					   [(ngModel)]="saveAsTemplateInput.name">
				<btn-c style="min-width: 30px;"
					   endIcon="zmdi zmdi-check"
					   [loading]="updateTableFilterTemplateInProcess"
					   (action)="saveTableFiltersTemplate();">
				</btn-c>
				<btn-c style="min-width: 30px;"
					   endIcon="zmdi zmdi-close" color="danger"
					   variant="text"
					   [isDisabled]="updateTableFilterTemplateInProcess"
					   (action)="saveAsTemplateInput = null;">
				</btn-c>
			</div>
		</div>
	</div>
</div>
