import * as util from 'util';
import _ from 'lodash';
import {RouteService} from "../../../../services/routeService";
import {NotificationService} from "../../../../services/notificationService";
import {GeneralService} from "../../../../services/generalService";
import {DICTIONARY} from "../../../../dictionary";
import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Router} from "@angular/router";

@Component({
	selector: 'user-inbound-conf-component',
	templateUrl: './user-inbound-conf.component.html',
})
export class UserInboundConfComponent implements OnInit, OnChanges {
    @Input() listType = DICTIONARY.CONSTANTS.inboundConfigurationPageTabs.senderLists;

	constructor(public router:Router,
				public gs:GeneralService,
				private rs:RouteService,
                private ns:NotificationService) {
    }

    dic = DICTIONARY;

	util = util;
	_=_;
    corpname = this.gs.corpname;
    loadingAllTablesInProcess = true;
    activeTab = this.listType;

    reviewerInfo;
    threats;
    addLinkInProcess;
    removeLinkInProcess;
    removeAttachmentInProcess;
    resultFile;
    resultsToUpload;
	tabWidth = 170;
	currentUser;
	whitelistFiles = null
	validDrag;
	invalidDrag;
	validDrag2;
	invalidDrag2;
	blacklistFiles = null;

	ngOnChanges(changes: SimpleChanges) {
		if (changes.listType) {
			this.activeTab = this.listType;
		}
	}

	ngOnInit() {
        this.loadingAllTablesInProcess = true;
        this.rs.getUserThreats().then( (response) => {

            this.reviewerInfo = this.gs.reviewerInfo;
            this.threats = response.threatsPersonalization;
            this.loadingAllTablesInProcess = false;

        },(err) => {
            this.loadingAllTablesInProcess = false;
        });
    };

    searchSendersWhitelist = (searchTerm, activeFilters) => { this.searchThreatList(searchTerm,this.dic.CONSTANTS.configTpAction.whitelist); }
    searchSendersBlacklist = (searchTerm, activeFilters) => { this.searchThreatList(searchTerm,this.dic.CONSTANTS.configTpAction.blacklist); }
    searchLinksWhitelist = (searchTerm, activeFilters) => { this.searchThreatList(searchTerm,this.dic.CONSTANTS.configTpAction.linksWhitelist); }
    searchLinksBlacklist = (searchTerm, activeFilters) => { this.searchThreatList(searchTerm,this.dic.CONSTANTS.configTpAction.linksBlacklist); }
    searchAttachmentsWhitelist = (searchTerm, activeFilters) => { this.searchThreatList(searchTerm,this.dic.CONSTANTS.configTpAction.attachmentsWhitelist); }
    searchAttachmentsBlacklist = (searchTerm, activeFilters) => { this.searchThreatList(searchTerm,this.dic.CONSTANTS.configTpAction.attachmentsBlacklist); }

    searchThreatList = (searchText, type) => {
        let list;
        switch (type) {
            case this.dic.CONSTANTS.configTpAction.whitelist:
                list = this.threats.senders_whitelist;
                break;

            case this.dic.CONSTANTS.configTpAction.blacklist:
                list = this.threats.senders_blacklist;
                break;

            case this.dic.CONSTANTS.configTpAction.linksWhitelist:
                list = this.threats.links_whitelist;
                break;

            case this.dic.CONSTANTS.configTpAction.linksBlacklist:
                list = this.threats.links_blacklist;
                break;

            case this.dic.CONSTANTS.configTpAction.attachmentsWhitelist:
                list = this.threats.attachments_whitelist;
                break;

            case this.dic.CONSTANTS.configTpAction.attachmentsBlacklist:
                list = this.threats.attachments_blacklist;
                break;
        }

        list.forEach(record => {
            // search
            if (searchText) {
                const isFound = this.searchTextExecute(type, record, searchText);
                if (!isFound) {
                    record.hide = true;
                    return;
                }
            }

            // filter
            // // currently no filters for all tables in cfg page

            record.hide = false;
        });
    };

    startAddRecord = (list) => {
        if (list.isAddRecordActive) {
            return;
        }

        list.isAddRecordActive = true;

        const newRecord = {
            isEditMode: true,
            isNew: true,
            edit: {
                email: '', // for senders table
                link: '', // for links table
                matchType: this.dic.CONSTANTS.ruleMatchType.exactMatch, // for links table
                name: '', // for attachments table
                hash: '', // for attachments table
                description: '',
            }
        };

        list.unshift(newRecord);
    };

    addAEmail = (email, type, description) => {
        if (!email) {
            return;
        }

		email = email.toLowerCase();
        if (!this.gs.validateEmail(email)) {
            this.ns.showErrorMessage(this.dic.ERRORS.accountEmailInvalid);
            return;
        }

        const data = {
            type: type,
            action: this.dic.CONSTANTS.inboundConfigurationsActions.add,
            items: [
                {
                    description: description || '',
                    value: email,
                    type: 'email'
                }
            ]
        };

        switch (type) {
            case this.dic.CONSTANTS.configTpAction.whitelist:
                if (_.map(this.threats.senders_whitelist, 'email').includes(email)) {
                    this.ns.showErrorMessage(util.format(this.dic.ERRORS.emailDomainAlreadyExist, email));
                    return;
                }
                if (_.map(this.threats.senders_blacklist, 'email').includes(email)) {
                    this.ns.showErrorMessage(util.format(this.dic.ERRORS.configWhiteBlack, email));
                    return;
                }
                break;
            case this.dic.CONSTANTS.configTpAction.blacklist:
                if (_.map(this.threats.senders_blacklist, 'email').includes(email)) {
                    this.ns.showErrorMessage(util.format(this.dic.ERRORS.emailDomainAlreadyExist, email));
                    return;
                }
                if (_.map(this.threats.senders_whitelist, 'email').includes(email)) {
                    this.ns.showErrorMessage(util.format(this.dic.ERRORS.configWhiteBlack, email));
                    return;
                }
                break;
        }

        this.addAEmailExecute(email, type, data);
    };

    addAEmailExecute = (email, type, data) => {

        this.rs.updateUserThreats(data).then( (response) => {
            let list;
            switch (type) {
                case this.dic.CONSTANTS.configTpAction.whitelist:
                    list = this.threats.senders_whitelist;
                    break;

                case this.dic.CONSTANTS.configTpAction.blacklist:
                    list = this.threats.senders_blacklist;
                    break;
            }

            const newRecord = _.find(list, sender => {return sender.isNew});

            if (newRecord) {
                newRecord.created = new Date();
                newRecord.updated_by = this.reviewerInfo.email;
                _.merge(newRecord, newRecord.edit);
				newRecord.email = email;
                newRecord.edit = null;
                newRecord.isEditMode = false;
                newRecord.isNew = undefined;
            }

            list.isAddRecordActive = false;
            this.ns.showInfoMessage(util.format(this.dic.MESSAGES.itemAdded, email));
        },  (error) => {
        });
    };

    editEmail = (sender, type) => {
        if (!sender || !sender.edit) {
            return;
        }

        if (!sender.edit.email) {
            this.ns.showErrorMessage(util.format(this.dic.ERRORS.emptyField, 'Email/Domain/IP'));
            return;
        }
		sender.edit.email = sender.edit.email.toLowerCase();

        if (!this.gs.validateEmail(sender.edit.email)) {
            this.ns.showErrorMessage(this.dic.ERRORS.accountEmailInvalid);
            return;
        }

        const data = {
            type: type,
            action: this.dic.CONSTANTS.inboundConfigurationsActions.edit,
            items: [
                {
                    description: sender.edit.description || '',
                    oldEmail: sender.email,
                    value: sender.edit.email,
                    type: 'email'
                }
            ]
        };

        this.rs.updateUserThreats(data).then(() => {
            sender = _.merge(sender,sender.edit);
            sender.updated_by = this.reviewerInfo.email;
            sender.created = new Date();
            sender.isEditMode = false;
            sender.edit = null;
            this.ns.showInfoMessage(util.format(this.dic.MESSAGES.itemUpdated, sender.email));
        });
    };

    removeEmails = (emails, type) => {
        if (!emails || !emails.length) {
            return;
        }

        let title = 'Remove ';
        switch (type) {
            case this.dic.CONSTANTS.configTpAction.whitelist:
                title += ' from allowlist';
                break;

            case this.dic.CONSTANTS.configTpAction.blacklist:
                title += ' from blocklist';
                break;
        }

        emails = _.map(emails, 'email');
        const emailsRemovedText = emails.length === 1 ? emails[0] : emails.length + ' emails';

        this.gs.showPopup({
            title: title,
            subTitle: `Are you sure you want to remove ${emailsRemovedText}?`,
            type: this.dic.CONSTANTS.popupWarning,
            doneBtnText: this.dic.CONSTANTS.inboundConfigurationsActions.remove,
            doneCb: () =>{
                let data = {
                    items: [],
                    type: type,
                    action: this.dic.CONSTANTS.inboundConfigurationsActions.remove
                };

                _.each(emails, email => {
                    let item:any = {};
                    if (this.gs.validateEmail(email)) {
                        item.value = email;
                        item.type = 'email';
                    }
                    else {
                        if (this.gs.isValidDomain(email)) {
                            item.value = email;
                            item.type = 'domain';
                        }
                        else if (this.gs.isValidIPAddress(email)) {
                            item.value = email;
                            item.type = 'ip';
                        }
                        else {
                            this.ns.showErrorMessage(this.dic.ERRORS.invalidSender);
                            return;
                        }
                    }
                    data.items.push(item);
                });

                this.rs.updateUserThreats(data).then(() => {
                    switch (type) {
                        case this.dic.CONSTANTS.configTpAction.whitelist:
                            _.remove<any>(this.threats.senders_whitelist, s => emails.includes(s.email));
                            break;

                        case this.dic.CONSTANTS.configTpAction.blacklist:
                            _.remove<any>(this.threats.senders_blacklist, s => emails.includes(s.email));
                            break;
                    }
                    this.ns.showInfoMessage(util.format(this.dic.MESSAGES.itemRemoved, emailsRemovedText));
                });
            }
        });
    };


    // senders whitelist table actions control
    showWhitelistActions = (sender) => {
        return [
            this.dic.CONSTANTS.configListsActions.edit,
            this.dic.CONSTANTS.configListsActions.remove
        ];
    }
    showWhitelistBulkActions = () => {
        return [
            this.dic.CONSTANTS.configListsActions.remove
        ];
    }
    selectWhitelistAction = (whitelist, action) => {
        switch (action) {
            case this.dic.CONSTANTS.configListsActions.remove:
                this.removeEmails([whitelist], this.dic.CONSTANTS.configTpAction.whitelist);
                break;
            case this.dic.CONSTANTS.configListsActions.edit:
                this.startEditListRecord(whitelist);
                break;
        }
    };
    selectMultipleWhitelistAction = (selectedItems, action) => {
        switch (action) {
            case this.dic.CONSTANTS.configUncommonTypesAction.remove:
                this.removeEmails(selectedItems, this.dic.CONSTANTS.configTpAction.whitelist);
                break;
        }
    };

    // senders blacklist table actions control
    showBlacklistActions = (sender) => {
        return [
            this.dic.CONSTANTS.configListsActions.edit,
            this.dic.CONSTANTS.configListsActions.remove
        ];
    }
    showBlacklistBulkActions = () => {
        return [
            this.dic.CONSTANTS.configListsActions.remove
        ];
    }
    selectBlacklistAction = (blacklist, action) => {
        switch (action) {
            case this.dic.CONSTANTS.configListsActions.remove:
                this.removeEmails([blacklist], this.dic.CONSTANTS.configTpAction.blacklist);
                break;
            case this.dic.CONSTANTS.configListsActions.edit:
                this.startEditListRecord(blacklist);
                break;
        }
    };
    selectMultipleBlacklistAction = (selectedItems, action) => {
        switch (action) {
            case this.dic.CONSTANTS.configUncommonTypesAction.remove:
                this.removeEmails(selectedItems, this.dic.CONSTANTS.configTpAction.blacklist);
                break;
        }
    };

    // links whitelist table actions control
    showLinksWhitelistActions = (sender) => {
        return [
            this.dic.CONSTANTS.configListsActions.edit,
            this.dic.CONSTANTS.configListsActions.remove
        ];
    }
    showLinksWhitelistBulkActions = () => {
        return [
            this.dic.CONSTANTS.configListsActions.remove
        ];
    }
    selectLinksWhitelistAction = (whitelistLink, action) => {
        switch (action) {
            case this.dic.CONSTANTS.configUncommonTypesAction.remove:
                this.removeLinks([whitelistLink], this.dic.CONSTANTS.configTpAction.linksWhitelist);
                break;
            case this.dic.CONSTANTS.configListsActions.edit:
                this.startEditListRecord(whitelistLink);
                break;
        }
    };
    selectMultipleLinksWhitelistAction = (selectedItems, action) => {
        switch (action) {
            case this.dic.CONSTANTS.configUncommonTypesAction.remove:
                this.removeLinks(selectedItems, this.dic.CONSTANTS.configTpAction.linksWhitelist);
                break;
        }
    };

    // links blacklist table actions control
    showLinksBlacklistActions = (sender) => {
        return [
            this.dic.CONSTANTS.configListsActions.edit,
            this.dic.CONSTANTS.configListsActions.remove
        ];
    }
    showLinksBlacklistBulkActions = () => {
        return [
            this.dic.CONSTANTS.configListsActions.remove
        ];
    }
    selectLinksBlacklistAction = (blacklistLink, action) => {
        switch (action) {
            case this.dic.CONSTANTS.configUncommonTypesAction.remove:
                this.removeLinks([blacklistLink], this.dic.CONSTANTS.configTpAction.linksBlacklist);
                break;
            case this.dic.CONSTANTS.configListsActions.edit:
                this.startEditListRecord(blacklistLink);
                break;
        }
    };
    selectMultipleLinksBlacklistAction = (selectedItems, action) => {
        switch (action) {
            case this.dic.CONSTANTS.configUncommonTypesAction.remove:
                this.removeLinks(selectedItems, this.dic.CONSTANTS.configTpAction.linksBlacklist);
                break;
        }
    };

    // attachments whitelist table actions control
    showAttachmentsWhitelistActions = (sender) => {
        return [
            this.dic.CONSTANTS.configListsActions.remove
        ];
    }
    showAttachmentsWhitelistBulkActions = () => {
        return [
            this.dic.CONSTANTS.configListsActions.remove
        ];
    }
    selectAttachmentsWhitelistAction = (whitelistAttachment, action) => {
        switch (action) {
            case this.dic.CONSTANTS.configUncommonTypesAction.remove:
                this.removeAttachments([whitelistAttachment], this.dic.CONSTANTS.configTpAction.attachmentsWhitelist);
                break;
        }
    };
    selectMultipleAttachmentsWhitelistAction = (selectedItems, action) => {
        switch (action) {
            case this.dic.CONSTANTS.configUncommonTypesAction.remove:
                this.removeAttachments(selectedItems, this.dic.CONSTANTS.configTpAction.attachmentsWhitelist);
                break;
        }
    };

    // attachments blacklist table actions control
    showAttachmentsBlacklistActions = (sender) => {
        return [
            this.dic.CONSTANTS.configListsActions.remove
        ];
    }
    showAttachmentsBlacklistBulkActions = () => {
        return [
            this.dic.CONSTANTS.configListsActions.remove
        ];
    }
    selectAttachmentsBlacklistAction = (blacklistAttachment, action) => {
        switch (action) {
            case this.dic.CONSTANTS.configUncommonTypesAction.remove:
                this.removeAttachments([blacklistAttachment], this.dic.CONSTANTS.configTpAction.attachmentsBlacklist);
                break;
        }
    };
    selectMultipleAttachmentsBlacklistAction = (selectedItems, action) => {
        switch (action) {
            case this.dic.CONSTANTS.configUncommonTypesAction.remove:
                this.removeAttachments(selectedItems, this.dic.CONSTANTS.configTpAction.attachmentsBlacklist);
                break;
        }
    };

    // enter edit mode in emails/links
    startEditListRecord = (record) => {
        record.edit = {
            email: record.email, // for senders
            link: record.link, // for links
            matchType: record.matchType, // for links
            description: record.description,
        };
        record.isEditMode = true;
    }

    // handle edit confirm in emails
    confirmEditSendersWhitelist =  (sender,isApproved) => {
        // edit declined
        if (!isApproved) {
            if (sender.isNew) {
                _.remove(this.threats.senders_whitelist, sender);
                this.threats.senders_whitelist.isAddRecordActive = false;
                return;
            }

            // empty the 'edit' property which contains the temp edit values and then close edit mode
            sender.edit = null;
            sender.isEditMode = false;
            return;
        }
        //

        if (!sender.email) { // add new sender approved
            this.addAEmail(sender.edit.email, this.dic.CONSTANTS.configTpAction.whitelist, sender.edit.description)
        }
        else { // edit approved
            this.editEmail(sender, this.dic.CONSTANTS.configTpAction.whitelist);
        }
    };

    confirmEditSendersBlacklist =  (sender,isApproved) => {
        // edit declined
        if (!isApproved) {
            if (sender.isNew) {
                _.remove(this.threats.senders_blacklist, sender);
                this.threats.senders_blacklist.isAddRecordActive = false;
                return;
            }

            // empty the 'edit' property which contains the temp edit values and then close edit mode
            sender.edit = null;
            sender.isEditMode = false;
            return;
        }
        //

        if (!sender.email) { // add new sender approved
            this.addAEmail(sender.edit.email, this.dic.CONSTANTS.configTpAction.blacklist, sender.edit.description)
        }
        else { // edit approved
            this.editEmail(sender, this.dic.CONSTANTS.configTpAction.blacklist);
        }
    };

    // handle edit confirm in links
    confirmEditLinksWhitelist =  (link,isApproved) => {
        // edit declined
        if (!isApproved) {
            if (link.isNew) {
                _.remove(this.threats.links_whitelist, link);
                this.threats.links_whitelist.isAddRecordActive = false;
                return;
            }

            // empty the 'edit' property which contains the temp edit values and then close edit mode
            link.edit = null;
            link.isEditMode = false;
            return;
        }
        //

        if (!link.link) { // add new sender approved
            this.addLink(link, this.dic.CONSTANTS.configTpAction.linksWhitelist);
        }
        else { // edit approved
            this.editLink(link, this.dic.CONSTANTS.configTpAction.linksWhitelist);
        }
    };

    confirmEditLinksBlacklist =  (link,isApproved) => {
        // edit declined
        if (!isApproved) {
            if (link.isNew) {
                _.remove(this.threats.links_blacklist, link);
                this.threats.links_blacklist.isAddRecordActive = false;
                return;
            }

            // empty the 'edit' property which contains the temp edit values and then close edit mode
            link.edit = null;
            link.isEditMode = false;
            return;
        }
        //

        if (!link.link) { // add new sender approved
            this.addLink(link, this.dic.CONSTANTS.configTpAction.linksBlacklist);
        }
        else { // edit approved
            this.editLink(link, this.dic.CONSTANTS.configTpAction.linksBlacklist);
        }
    };

    addLink = (linkObj, type) => {
        if (!linkObj) {
            return;
        }

        const linkItem = {
            link: linkObj.edit.link.toLowerCase(),
            description: linkObj.edit.description,
            matchType: linkObj.edit.matchType,
            created: new Date()
        };

        let data = {
            type: type,
            links: [linkItem],
            action: this.dic.CONSTANTS.inboundConfigurationsActions.add
        };

        switch (type) {
            case this.dic.CONSTANTS.configTpAction.linksWhitelist:
                if (_.map(this.threats.links_whitelist, 'link').includes(linkItem.link)) {
                    this.ns.showErrorMessage(util.format(this.dic.ERRORS.linkAlreadyExist, linkItem.link));
                    return;
                }
                if (_.map(this.threats.links_blacklist, 'link').includes(linkItem.link)) {
                    this.ns.showErrorMessage(util.format(this.dic.ERRORS.configWhiteBlack, linkItem.link));
                    return;
                }
                break;
            case this.dic.CONSTANTS.configTpAction.linksBlacklist:
                if (_.map(this.threats.links_blacklist, 'link').includes(linkItem.link)) {
                    this.ns.showErrorMessage(util.format(this.dic.ERRORS.linkAlreadyExist, linkItem.link));
                    return;
                }
                if (_.map(this.threats.links_whitelist, 'link').includes(linkItem.link)) {
                    this.ns.showErrorMessage(util.format(this.dic.ERRORS.configWhiteBlack, linkItem.link));
                    return;
                }
                break;
        }

        this.addLinkInProcess = true;

        this.rs.updateUserThreats(data).then( (response) => {
            let list;
            switch (type) {
                case this.dic.CONSTANTS.configTpAction.linksWhitelist:
                    list = this.threats.links_whitelist;
                    break;
                case this.dic.CONSTANTS.configTpAction.linksBlacklist:
                    list = this.threats.links_blacklist;
                    break;
            }

            const newRecord = _.find(list, sender => {return sender.isNew});
            if (newRecord) {
                Object.assign(newRecord, linkItem);
                newRecord.updated_by = this.reviewerInfo.email;
                newRecord.isEditMode = false;
                newRecord.isNew = undefined;
            }

            list.isAddRecordActive = false;

            this.ns.showInfoMessage(util.format(this.dic.MESSAGES.itemAdded, linkItem.link));
        },  (error) => {
        });
    };

    editLink = (linkObj, type) => {
        if (!linkObj || !linkObj.edit) {
            return;
        }

        if (!linkObj.edit.link) {
            return this.ns.showErrorMessage(util.format(this.dic.ERRORS.emptyField, 'Link'));
        }

        const linkItem = {
			link: linkObj.edit.link.toLowerCase(),
			oldLink: linkObj.link,
			matchType: linkObj.edit.matchType,
			description: linkObj.edit.description,
			created: new Date()
		};
        if (this.threats.links_blacklist.some(itm => itm.link === linkObj.edit.link && itm.link !== linkObj.link)) {
            this.ns.showErrorMessage(util.format(type === this.dic.CONSTANTS.configTpAction.linksBlacklist ?
                this.dic.ERRORS.linkAlreadyExist :
                this.dic.ERRORS.configWhiteBlack, linkObj.edit.link));
            return;
        }
        if (this.threats.links_whitelist.some(itm => itm.link === linkObj.edit.link && itm.link !== linkObj.link)) {
            this.ns.showErrorMessage(util.format(type === this.dic.CONSTANTS.configTpAction.linksWhitelist ?
                this.dic.ERRORS.linkAlreadyExist :
                this.dic.ERRORS.configWhiteBlack, linkObj.edit.link));
            return;
        }

        let data = {
            type: type,
            links: [linkItem],
            action: this.dic.CONSTANTS.inboundConfigurationsActions.edit
        };

        this.rs.updateUserThreats(data).then(response => {
            linkObj = Object.assign(linkObj,linkObj.edit);
            linkObj.updated_by = this.reviewerInfo.email;
            linkObj.isEditMode = false;
            linkObj.edit = null;
            this.ns.showInfoMessage(util.format(this.dic.MESSAGES.itemUpdated, linkObj.link));
        });
    };

    removeLinks = (links, type) => {
        if (!links || !links.length) {
            return;
        }

        let title = 'Remove links';
        switch (type) {
            case this.dic.CONSTANTS.configTpAction.linksWhitelist:
                title += ' from allowlist';
                break;

            case this.dic.CONSTANTS.configTpAction.linksBlacklist:
                title += ' from blocklist';
                break;
        }

        let linksFlat = _.map(links, 'link');
        const linksRemovedText = linksFlat.length === 1 ? linksFlat[0] : linksFlat.length + ' links';

        this.gs.showPopup({
            title: title,
            subTitle: `Are you sure you want to remove ${linksRemovedText}?`,
            type: this.dic.CONSTANTS.popupWarning,
            doneBtnText: this.dic.CONSTANTS.inboundConfigurationsActions.remove,
            doneCb: () =>{
                let data = {
                    type: type,
                    links: links,
                    action: this.dic.CONSTANTS.inboundConfigurationsActions.remove
                };

                this.removeLinkInProcess = true;
                this.rs.updateUserThreats(data).then( () => {
                    switch (type) {
                        case this.dic.CONSTANTS.configTpAction.linksWhitelist:
                            _.remove<any>(this.threats.links_whitelist, t => linksFlat.includes(t.link));
                            break;

                        case this.dic.CONSTANTS.configTpAction.linksBlacklist:
                            _.remove<any>(this.threats.links_blacklist, t => linksFlat.includes(t.link));
                            break;
                    }
                    this.removeLinkInProcess = false;
                    this.ns.showInfoMessage(util.format(this.dic.MESSAGES.itemRemoved, linksRemovedText));
                });
            }
        });
    };

    uploads =  (files, isWhiteList) => {
        if (files && files.length) {
            let file = files[0];
            file.isValid = this.gs.checkUploadFileSize(file.size, file.name);
            if (file.isValid) {
                this.gs.fileHash(file, (fileHash) => {
                    file.hash = fileHash;
                    this.addNewAttachment(file, isWhiteList, (err) => {
                        files.splice(0, 1);
                        if (files.length) {
                            this.uploads(files, isWhiteList);
                        }
                    });
                });
            }
        }
    };

    confirmNewWhitelistAttachment =  (attachment, isApproved) => {this.confirmNewAttachment(attachment, isApproved, true);}
    confirmNewBlacklistAttachment =  (attachment, isApproved) => {this.confirmNewAttachment(attachment, isApproved, false);}

    confirmNewAttachment =  (attachment, isApproved, isWhitelist) => {
        // new attachment process was declined
        if (!isApproved) {
            if (isWhitelist) {
                _.remove<any>(this.threats.attachments_whitelist, attachment);
                this.threats.attachments_whitelist.isAddRecordActive = false;
            }
            else {
                _.remove<any>(this.threats.attachments_blacklist, attachment);
                this.threats.attachments_blacklist.isAddRecordActive = false;
            }
            return;
        }
        //

        // new attachment process approved
        // validation
        if (!attachment.edit.hash) {
            return this.ns.showErrorMessage(util.format(this.dic.ERRORS.emptyField, 'Hash'));
        }
        if (attachment.edit.hash.length !== 64 && attachment.edit.hash.length !== 32) {
            this.ns.showErrorMessage(this.dic.ERRORS.attachmentHash256Invalid);
            return;
        }

        this.addNewAttachment(attachment, isWhitelist, () => {});
    };

    addNewAttachment = (attachmentObj, isWhitelist, cb) => {
        // (attachmentObj is "Object" when added manually (so it also has edit), but is "Blob" file when is added through uploading)
        if (attachmentObj.edit) {
            attachmentObj.name = attachmentObj.edit.name;
            attachmentObj.hash = attachmentObj.edit.hash;
            attachmentObj.size = 0;
        }
        else {
            // make the Blob a readable Object
            attachmentObj = {name: attachmentObj.name, size: attachmentObj.size || 0, hash: attachmentObj.hash};
        }

        let type, list;
        if (isWhitelist) {
            if (this.threats.attachments_whitelist.some(itm => itm.hash === attachmentObj.hash && !itm.isNew)) {
                this.ns.showErrorMessage(util.format(this.dic.ERRORS.attachmentExists, attachmentObj.name));
                return;
            }

            if (this.threats.attachments_blacklist.some(itm => itm.hash === attachmentObj.hash && !itm.isNew)) {
                this.ns.showErrorMessage(util.format(this.dic.ERRORS.configWhiteBlack, attachmentObj.name));
                return;
            }
            type = this.dic.CONSTANTS.configTpAction.attachmentsWhitelist;
            list = this.threats.attachments_whitelist;
        }
        else {
            if (this.threats.attachments_blacklist.some(itm => itm.hash === attachmentObj.hash && !itm.isNew)) {
                this.ns.showErrorMessage(util.format(this.dic.ERRORS.attachmentExists, attachmentObj.name));
                return;
            }

            if (this.threats.attachments_whitelist.some(itm => itm.hash === attachmentObj.hash && !itm.isNew)) {
                this.ns.showErrorMessage(util.format(this.dic.ERRORS.configWhiteBlack, attachmentObj.name));
                return;
            }
            type = this.dic.CONSTANTS.configTpAction.attachmentsBlacklist;
            list = this.threats.attachments_blacklist;
        }

        const data = {
            type: type,
            attachments: [attachmentObj],
            action: this.dic.CONSTANTS.inboundConfigurationsActions.add
        };

        this.rs.updateUserThreats(data).then( (response) => {
            attachmentObj.created = new Date();
            attachmentObj.updated_by = this.reviewerInfo.email;
            attachmentObj.edit = null;
            attachmentObj.isEditMode = false;
            attachmentObj.isNew = undefined; // keep it 'undefined' and not 'false' for sticking the item to the top of the table only when in edit

            if (!_.find(list, attachmentObj)) {
                list.unshift(attachmentObj);
            }

            this.ns.showInfoMessage(util.format(this.dic.MESSAGES.itemAdded, attachmentObj.name));
            if (isWhitelist) {
                this.threats.attachments_whitelist.isAddRecordActive = false;
            }
            else {
                this.threats.attachments_blacklist.isAddRecordActive = false;
            }

            if (cb) {cb();}

        }, (error) => {

            if (cb) {cb(error);}

            if (isWhitelist) {
                this.threats.attachments_whitelist.isAddRecordActive = false;
            }
            else {
                this.threats.attachments_blacklist.isAddRecordActive = false;
            }
        });
    }

    removeAttachments = (attachments, type) => {
        if (!attachments || !attachments.length) {
            return;
        }

        let title = 'Remove attachments';
        switch (type) {
            case this.dic.CONSTANTS.configTpAction.attachmentsWhitelist:
                title += ' from allowlist';
                break;

            case this.dic.CONSTANTS.configTpAction.attachmentsBlacklist:
                title += ' from blocklist';
                break;
        }

        const attachmentsRemovedText = attachments.length === 1 ? attachments[0].name : attachments.length + ' attachments';

        this.gs.showPopup({
            title: title,
            subTitle: `Are you sure you want to remove  ${attachmentsRemovedText}?`,
            type: this.dic.CONSTANTS.popupWarning,
            doneBtnText: this.dic.CONSTANTS.inboundConfigurationsActions.remove,
            doneCb: () =>{
                let data = {
                    type: type,
                    attachments: attachments,
                    action: this.dic.CONSTANTS.inboundConfigurationsActions.remove
                };

                attachments = _.map(attachments, 'hash');

                this.removeAttachmentInProcess = true;
                this.rs.updateUserThreats(data).then( () => {
                    switch (type) {
                        case this.dic.CONSTANTS.configTpAction.attachmentsWhitelist:
                            _.remove<any>(this.threats.attachments_whitelist, t => attachments.includes(t.hash));
                            break;

                        case this.dic.CONSTANTS.configTpAction.attachmentsBlacklist:
                            _.remove<any>(this.threats.attachments_blacklist, t => attachments.includes(t.hash));
                            break;
                    }
                    this.removeAttachmentInProcess = false;
                    this.ns.showInfoMessage(util.format(this.dic.MESSAGES.itemRemoved, attachmentsRemovedText));
                });
            }
        });
    };

    exportSendersWhitelistToCsv = (sortBy) => { this.exportSendersListToCsv(this.threats.senders_whitelist, true, sortBy); }
    exportSendersBlacklistToCsv = (sortBy) => { this.exportSendersListToCsv(this.threats.senders_blacklist, false, sortBy); }

	exportLinksWhitelistToCsv = (sortBy) => this.exportLinkslistToCsv(this.threats.links_whitelist, true, sortBy);
	exportLinksBlacklistToCsv = (sortBy) => this.exportLinkslistToCsv(this.threats.links_blacklist, false, sortBy);

    exportAttachmentsWhitelistToCsv = (sortBy) => { this.exportAttachmentListToCsv(this.threats.attachments_whitelist, true, sortBy); }
    exportAttachmentsBlacklistToCsv = (sortBy) => { this.exportAttachmentListToCsv(this.threats.attachments_blacklist, false, sortBy); }

	exportLinkslistToCsv = (list, isWhiteList, sortBy) => {
		if (!list.length) {
			return;
		}

		let sortedTable = _.filter(list,record => {return !record.hide && !record.isNew});
		if (sortBy) {
			sortedTable = this.gs.sortTable(sortedTable, sortBy);
		}

		let csvString = "link, match type, description, updated by, updated\n";

		sortedTable.forEach((item) => {
			if (item.description && /^[\r\t@+=-]/.test(item.description)) {
				item.description = ' ' + item.description + ' ';
			}
			csvString += `${item.link},"${item.matchType || ''}","${item.description || ''}",${item.updated_by || ''}, ${item.created || ''}\n`;
		});

		this.gs.exportCsv(csvString, `configuration_links_${isWhiteList ? 'allow': 'block'}list.csv`);
	}

	exportSendersListToCsv = (list, isWhiteList, sortBy) => {
        if (!list.length) {
            return;
        }

        let sortedTable = _.filter(list,record => {return !record.hide});
        if (sortBy) {
            sortedTable = this.gs.sortTable(sortedTable, sortBy);
        }

        let csvString = "sender, description, updated by, updated\n";

        sortedTable.forEach((item) => {
            if (item.description && /^[\r\t@+=-]/.test(item.description)) {
                item.description = ' ' + item.description + ' ';
            }
            csvString += `${item.email},` + (item.description ? `"${item.description}"` : '') + `,${item.updated_by || ''}, ${item.created || ''}\n`;
        });

        this.gs.exportCsv(csvString, `configuration_senders_${isWhiteList ? 'allow' : 'block'}list.csv`);
    };

    exportAttachmentListToCsv = (list, isWhiteList, sortBy) => {
        if (!list.length) {
            return;
        }

        let sortedTable = _.filter(list,record => {return !record.hide});
        if (sortBy) {
            sortedTable = this.gs.sortTable(sortedTable, sortBy);
        }

        let csvString = "name,hash,updated by,updated\n";

        sortedTable.forEach((item) => {
            csvString += `${item.name || ''},"${item.hash}",${item.updated_by || ''}, ${item.created || ''}\n`;
        });

		this.gs.exportCsv(csvString, `configuration_attachments_${isWhiteList ? 'allow' : 'block'}list.csv`);
    };


    uploadSendersWhitelistFromFile = (file) => { this.importFromFile(file, this.dic.CONSTANTS.configTpAction.whitelist); }
    uploadSendersBlacklistFromFile = (file) => { this.importFromFile(file, this.dic.CONSTANTS.configTpAction.blacklist); }
    uploadLinksWhitelistFromFile = (file) => { this.importFromFile(file, this.dic.CONSTANTS.configTpAction.linksWhitelist); }
    uploadLinksBlacklistFromFile = (file) => { this.importFromFile(file, this.dic.CONSTANTS.configTpAction.linksBlacklist); }
    uploadAttachmentsWhitelistFromFile = (file) => { this.importFromFile(file, this.dic.CONSTANTS.configTpAction.attachmentsWhitelist); }
    uploadAttachmentsBlacklistFromFile = (file) => { this.importFromFile(file, this.dic.CONSTANTS.configTpAction.attachmentsBlacklist); }

    importFromFile = (file, type) => {
        if (!file) return;
        let header, regex, originalList, oppositeList, optionalHeaders;
        const validationFuncAlwaysTrue = () => {return true};
        let validationFunctions:any = [validationFuncAlwaysTrue];
        switch (type) {
            case this.dic.CONSTANTS.configTpAction.whitelist:
                header = 'sender';
                optionalHeaders = ['description', 'allSources'];
                validationFunctions = [this.gs.validateEmail];
                originalList = _.map(this.threats.senders_whitelist, 'email');
                oppositeList = _.map(this.threats.senders_blacklist, 'email');
                break;

            case this.dic.CONSTANTS.configTpAction.blacklist:
                header = 'sender';
                optionalHeaders = ['description', 'allSources'];
                validationFunctions = [this.gs.validateEmail];
                originalList = _.map(this.threats.senders_blacklist, 'email');
                oppositeList = _.map(this.threats.senders_whitelist, 'email');
                break;

            case this.dic.CONSTANTS.configTpAction.linksWhitelist:
                header = 'link';
                optionalHeaders = ['match type', 'description'];
                originalList = _.map(this.threats.links_whitelist, 'link');
                oppositeList = _.map(this.threats.links_blacklist, 'link');
                break;

            case this.dic.CONSTANTS.configTpAction.linksBlacklist:
                header = 'link';
                optionalHeaders = ['match type', 'description'];
                originalList = _.map(this.threats.links_blacklist, 'link');
                oppositeList = _.map(this.threats.links_whitelist, 'link');
                break;

            case this.dic.CONSTANTS.configTpAction.attachmentsWhitelist:
                header = 'hash';
                optionalHeaders = ['name'];
                validationFunctions.push(this.gs.isValidHash);
                originalList = _.map(this.threats.attachments_whitelist, 'hash');
                oppositeList = _.map(this.threats.attachments_blacklist, 'hash');
                break;

            case this.dic.CONSTANTS.configTpAction.attachmentsBlacklist:
                header = 'hash';
                optionalHeaders = ['name'];
                validationFunctions.push(this.gs.isValidHash);
                originalList = _.map(this.threats.attachments_blacklist, 'hash');
                oppositeList = _.map(this.threats.attachments_whitelist, 'hash');
                break;
        }


        this.gs.readCsv(file, [header], optionalHeaders, regex, validationFunctions, (err, results) => {
            if (err) {
                return;
            }
            if (!results || !results[header] || !results[header].length) {
                this.ns.showWarnMessage(this.dic.ERRORS.noNewResult);
                return;
            }

            let data:any = {
                webApp: true,
                type: type,
                action: this.dic.CONSTANTS.inboundConfigurationsActions.add,
                items: []
            };

            switch (type) {
                case this.dic.CONSTANTS.configTpAction.whitelist:
                case this.dic.CONSTANTS.configTpAction.blacklist:
                    data.items = [];
                    for (let i = 0; i < results[header].length; i++) {
                        let item:any = {updated_by: this.reviewerInfo.email};
                        if (this.gs.validateEmail(results[header][i])) {
                            item.type = 'email';
                            item.value = results[header][i];
                        }
                        else if (this.gs.isValidDomain(results[header][i])) {
                            item.type = 'domain';
                            item.value = results[header][i];                    }
                        else {
                            item.type = 'ip';
                            item.value = results[header][i];
                        }
                        if (results['description'] && results['description'].length) {
                            item.description = results['description'][i];
                        }
                        if (results['allSources'] && results['allSources'].length) {
                            item.allSources = results['allSources'][i] === "TRUE";
                        }

                        if (originalList.includes(item.value)) {
                            this.ns.showErrorMessage(util.format(this.dic.ERRORS.emailDomainAlreadyExist, item.value));
							continue;
						}
                        if (oppositeList.includes(item.value)) {
                            this.ns.showErrorMessage(util.format(this.dic.ERRORS.configWhiteBlack, item.value));
							continue;
						}
                        data.items.push(item);
                    }

                    if (!data.items.length) {
                        this.resultFile = null;
                        this.ns.showWarnMessage(util.format(this.dic.ERRORS.noNewDomainsAddressesLinks, 'domains/addresses/ips'));
                        return;
                    }
                    break;

                case this.dic.CONSTANTS.configTpAction.linksWhitelist:
                case this.dic.CONSTANTS.configTpAction.linksBlacklist:
                    data.links = [];
                    for (let i = 0; i < results[header].length; i++) {
                        let item:any = {link: results['link'][i], matchType: this.dic.CONSTANTS.ruleMatchType.exactMatch, updated_by: this.reviewerInfo.email};
                        if (results['description'] && results['description'].length) {
                            item.description = results['description'][i];
                        }
                        if (results['match type'] && results['match type'].length) {
                            item.matchType = results['match type'][i];
                        }
                        if (originalList.includes(item.link)) {
                            this.ns.showErrorMessage(util.format(this.dic.ERRORS.emailDomainAlreadyExist, item.link));
							continue;
                        }
                        if (oppositeList.includes(item.link)) {
                            this.ns.showErrorMessage(util.format(this.dic.ERRORS.configWhiteBlack, item.link));
							continue;
                        }

                        data.links.push(item);
                    }

                    if (!data.links.length) {
                        this.resultFile = null;
                        this.ns.showWarnMessage(util.format(this.dic.ERRORS.noNewDomainsAddressesLinks, 'links'));
                        return;
                    }
                    break;

                case this.dic.CONSTANTS.configTpAction.attachmentsBlacklist:
                case this.dic.CONSTANTS.configTpAction.attachmentsWhitelist:
                    data.attachments = [];
                    for (let i = 0; i < results[header].length; i++) {
                        let item:any = {hash: results['hash'][i], created: new Date(), updated_by: this.reviewerInfo.email};

                        if (results['name'] && results['name'].length) {
                            item.name = results['name'][i];
                        }

                        if (originalList.includes(item.hash)) {
                            this.ns.showErrorMessage(util.format(this.dic.ERRORS.emailDomainAlreadyExist, item.hash));
							continue;
                        }
                        if (oppositeList.includes(item.hash)) {
                            this.ns.showErrorMessage(util.format(this.dic.ERRORS.configWhiteBlack, item.hash));
							continue;
                        }
                        data.attachments.push(item);
                    }
                    break;

                default:
                    this.resultsToUpload = [];
                    for (let i = 0; i < results[header].length; i++) {
                        if (!originalList.includes(results[header][i])) {
                            this.resultsToUpload.push(results[header][i]);
                        }
                    }
            }

            this.addFromFile(data);
        });
    };

    addFromFile = (data) => {
        this.rs.updateUserThreats(data).then( (response) => {
            switch (data.type) {
                case this.dic.CONSTANTS.configTpAction.whitelist:
                    _.each(data.items, emailObj => {
                        this.threats.senders_whitelist.push({email: emailObj.value, description: emailObj.description, created: new Date(), updated_by: this.reviewerInfo.email});
                    });
                    this.ns.showInfoMessage(util.format(this.dic.MESSAGES.itemAdded, 'Addresses'));
                    break;

                case this.dic.CONSTANTS.configTpAction.blacklist:
                    _.each(data.items, emailObj => {
                        this.threats.senders_blacklist.push({email: emailObj.value, description: emailObj.description, created: new Date(), updated_by: this.reviewerInfo.email});
                    });
                    this.ns.showInfoMessage(util.format(this.dic.MESSAGES.itemAdded, 'Addresses'));
                    break;

                case this.dic.CONSTANTS.configTpAction.linksWhitelist:
                    _.each(data.links, linkObj => {
                        linkObj.created = new Date();
                        linkObj.link = linkObj.link.toLowerCase();
                        this.threats.links_whitelist.push(linkObj);
                    });
                    this.ns.showInfoMessage(util.format(this.dic.MESSAGES.itemAdded, 'Links'));
                    break;

                case this.dic.CONSTANTS.configTpAction.linksBlacklist:
                    _.each(data.links, linkObj => {
                        linkObj.created = new Date();
                        linkObj.link = linkObj.link.toLowerCase();
                        this.threats.links_blacklist.push(linkObj);
                    });
                    this.ns.showInfoMessage(util.format(this.dic.MESSAGES.itemAdded, 'Links'));
                    break;

                case this.dic.CONSTANTS.configTpAction.attachmentsWhitelist:
                    _.each(data.attachments, attachmentObj => {
                        this.threats.attachments_whitelist.push(attachmentObj);
                    });
                    this.ns.showInfoMessage(util.format(this.dic.MESSAGES.itemAdded, 'Attachments'));
                    break;

                case this.dic.CONSTANTS.configTpAction.attachmentsBlacklist:
                    _.each(data.attachments, attachmentObj => {
                        this.threats.attachments_blacklist.push(attachmentObj);
                    });
                    this.ns.showInfoMessage(util.format(this.dic.MESSAGES.itemAdded, 'Attachments'));
                    break;
            }
            this.resultFile = null;

        },  (error) => {
        });
    };

    searchTextExecute = (listType, record, searchTerm) => {
        searchTerm = searchTerm.toLowerCase();

        if (record.description && record.description.toLowerCase().indexOf(searchTerm) >= 0) {
            return true;
        }

        switch (listType) {
            case this.dic.CONSTANTS.configTpAction.whitelist:
                return (record.email && record.email.toLowerCase().indexOf(searchTerm) >= 0);

            case this.dic.CONSTANTS.configTpAction.blacklist:
                return (record.email && record.email.toLowerCase().indexOf(searchTerm) >= 0);

            case this.dic.CONSTANTS.configTpAction.linksWhitelist:
                return ((record.link && record.link.toLowerCase().indexOf(searchTerm) >= 0) ||
                    (record.matchType && record.matchType.toLowerCase().indexOf(searchTerm) >= 0));

            case this.dic.CONSTANTS.configTpAction.linksBlacklist:
                return ((record.link && record.link.toLowerCase().indexOf(searchTerm) >= 0) ||
                    (record.matchType && record.matchType.toLowerCase().indexOf(searchTerm) >= 0));

            case this.dic.CONSTANTS.configTpAction.attachmentsWhitelist:
                return (record.name && record.name.toLowerCase().indexOf(searchTerm) >= 0) ||
					(record.hash && record.hash.toLowerCase().indexOf(searchTerm) >= 0 );

            case this.dic.CONSTANTS.configTpAction.attachmentsBlacklist:
                return (record.name && record.name.toLowerCase().indexOf(searchTerm) >= 0) ||
					(record.hash && record.hash.toLowerCase().indexOf(searchTerm) >= 0 );
        }
    }


}
