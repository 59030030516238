import {Component} from "@angular/core";
import _ from 'lodash';
import {DICTIONARY} from '../../../dictionary';
import {RouteService} from '../../../services/routeService';
import {NotificationService} from '../../../services/notificationService';
import {GeneralService} from '../../../services/generalService';
import {AccountCompromisedService} from '../../../services/accountCompromisedService';

@Component({
	selector: 'user-incidents-info-component',
	templateUrl: './user-incidents-info.component.html',
})
export class AccountCompromisedUserIncidentsInfoComponent {

    dic = DICTIONARY;
	incidentInfo;
	_ = _;
	getEmailContentInProcess;
	previewAttachment;
	showMore = {
		to: false,
		cc: false,
		bcc: false
	}

	conditionDescriptors = [
		{ propKey: 'bodyContains',              label: 'Body contains',              type: 'array_of_strings' },
		{ propKey: 'bodyOrSubjectContains',     label: 'Body or Subject contains',   type: 'array_of_strings' },
		{ propKey: 'categories',               label: 'Categories',                  type: 'array_of_strings' },
		{ propKey: 'fromAddresses',            label: 'From addresses',              type: 'array_of_recipients' },
		{ propKey: 'hasAttachments',           label: 'Has attachments',             type: 'boolean' },
		{ propKey: 'headerContains',           label: 'Header contains',             type: 'array_of_strings' },
		{ propKey: 'importance',               label: 'Importance',                  type: 'string' },
		{ propKey: 'isApprovalRequest',        label: 'Is approval request',         type: 'boolean' },
		{ propKey: 'isAutomaticForward',       label: 'Is automatic forward',        type: 'boolean' },
		{ propKey: 'isAutomaticReply',         label: 'Is automatic reply',          type: 'boolean' },
		{ propKey: 'isEncrypted',              label: 'Is encrypted',                type: 'boolean' },
		{ propKey: 'isMeetingRequest',         label: 'Is meeting request',          type: 'boolean' },
		{ propKey: 'isMeetingResponse',        label: 'Is meeting response',         type: 'boolean' },
		{ propKey: 'isNonDeliveryReport',      label: 'Is non-delivery report',      type: 'boolean' },
		{ propKey: 'isPermissionControlled',   label: 'Is permission controlled',    type: 'boolean' },
		{ propKey: 'isReadReceipt',            label: 'Is read receipt',             type: 'boolean' },
		{ propKey: 'isSigned',                 label: 'Is signed',                   type: 'boolean' },
		{ propKey: 'isVoicemail',              label: 'Is voicemail',                type: 'boolean' },
		{ propKey: 'messageActionFlag',        label: 'Message action flag',         type: 'string' },
		{ propKey: 'notSentToMe',              label: 'Not sent to me',             type: 'boolean' },
		{ propKey: 'recipientContains',        label: 'Recipient contains',          type: 'array_of_strings' },
		{ propKey: 'senderContains',           label: 'Sender contains',             type: 'array_of_strings' },
		{ propKey: 'sensitivity',              label: 'Sensitivity',                 type: 'string' },
		{ propKey: 'sentCcMe',                 label: 'Sent CC me',                  type: 'boolean' },
		{ propKey: 'sentOnlyToMe',             label: 'Sent only to me',             type: 'boolean' },
		{ propKey: 'sentToAddresses',          label: 'Sent to addresses',           type: 'array_of_recipients' },
		{ propKey: 'sentToMe',                 label: 'Sent to me',                  type: 'boolean' },
		{ propKey: 'sentToOrCcMe',             label: 'Sent to or CC me',            type: 'boolean' },
		{ propKey: 'subjectContains',          label: 'Subject contains',            type: 'array_of_strings' },
		{ propKey: 'withinSizeRange',          label: 'Within size range',           type: 'sizeRange' },
	];

	actionDescriptors = [
		{ propKey: 'assignCategories',          label: 'Assign categories',           type: 'array_of_strings' },
		{ propKey: 'copyToFolder',             label: 'Copy to folder',              type: 'string' },
		{ propKey: 'delete',                   label: 'Delete',                       type: 'boolean' },
		{ propKey: 'forwardAsAttachmentTo',    label: 'Forward as attachment to',     type: 'array_of_recipients' },
		{ propKey: 'forwardTo',                label: 'Forward to',                   type: 'array_of_recipients' },
		{ propKey: 'markAsRead',               label: 'Mark as read',                 type: 'boolean' },
		{ propKey: 'markImportance',           label: 'Mark importance',              type: 'string' },
		{ propKey: 'moveToFolder',             label: 'Move to folder',               type: 'string' },
		{ propKey: 'permanentDelete',          label: 'Permanent delete',             type: 'boolean' },
		{ propKey: 'redirectTo',               label: 'Redirect to',                  type: 'array_of_recipients' },
		{ propKey: 'stopProcessingRules',      label: 'Stop processing rules',        type: 'boolean' },
	];

    constructor(
		public accountCompromisedService:AccountCompromisedService,
		private rs:RouteService,
		private ns:NotificationService,
		private gs:GeneralService) {
    }

    openLocation(ll) {
        this.gs.openLocation(ll);
    }

    selectIncidentAction = (incidentObj, action) => {
        switch (action) {
            case this.dic.CONSTANTS.accountCompromisedIncidentActions.info.display:
                this.accountCompromisedService.getUserIncidentInfo(incidentObj);
                break;

            case this.dic.CONSTANTS.accountCompromisedIncidentActions.delete.display:
                this.accountCompromisedService.deleteIncident(incidentObj, () => {
                    _.remove<any>(this.accountCompromisedService.userIncidentsPopup.userIncidentsInfo, (incidentItem:any) => incidentItem._id === incidentObj._id);
                });
                break;
        }
    }

	setStatusConfirm = (incidentObj, newStatus) => {
		const incidentInfo = {
			incidentId: incidentObj._id,
			status: newStatus.toLowerCase(),
			action: this.dic.CONSTANTS.accountCompromisedIncidentActions.status.value
		};

		this.rs.incidentAction(incidentObj.user_email, incidentInfo).then(response => {
			this.accountCompromisedService.setStatusPopup = null;
			incidentObj.status = incidentInfo.status;
			this.ns.showInfoMessage('Incident updated successfully');

			this.accountCompromisedService.addIncidentAction(newStatus.toLowerCase(), incidentObj);
		});
	}

    showIncidentInfoActions = () => {
        return [
			this.dic.CONSTANTS.accountCompromisedIncidentActions.info.display,
			this.dic.CONSTANTS.accountCompromisedIncidentActions.delete.display
		];
    }

    searchIncidents = (searchTerm, activeFilters) => {
		this.accountCompromisedService.userIncidentsPopup.userIncidentsInfo.forEach(record => {
			// search
			if (searchTerm) {
				const isFound = this.searchTextExecute(record, searchTerm);
				if (!isFound) {
					record.hide = true;
					return;
				}
			}

			// filter
			// // no filters for this table

			record.hide = false;
		});
    }

    searchTextExecute(incident, searchTerm) {
        searchTerm = searchTerm.toLowerCase();
        return ((incident.incident_type && this.dic.CONSTANTS.accountCompromisedIncidentTypes[incident.incident_type] &&
                this.dic.CONSTANTS.accountCompromisedIncidentTypes[incident.incident_type].name &&
                this.dic.CONSTANTS.accountCompromisedIncidentTypes[incident.incident_type].name.toLowerCase().indexOf(searchTerm) > -1));
    }

	getIncidentEmailContent = () => {
		this.getEmailContentInProcess = true;

		const actionInfo = {
			action: this.dic.CONSTANTS.accountCompromisedIncidentActions.viewContent.value,
			incidentId: this.accountCompromisedService.incidentInfoPopup.IncidentInfo._id
		};

		this.rs.incidentAction(this.accountCompromisedService.incidentInfoPopup.IncidentInfo.user_email, actionInfo).then(response => {
			this.incidentInfo['emailInfo'] = response;
			this.accountCompromisedService.incidentInfoPopup.emailContent = '<hr/><br/>' + response.content;
			this.getEmailContentInProcess = false;
		}, () => {
			this.getEmailContentInProcess = undefined;
		});
	}

	downloadAttachment = (attachmentObj) => {
		if (attachmentObj.inProcess) return;

		const url = `/admin/compromised/incidents/attachment/${this.accountCompromisedService.incidentInfoPopup.IncidentInfo.user_email}/${this.accountCompromisedService.incidentInfoPopup.IncidentInfo._id}/${attachmentObj._id}`;

		this.gs.downloadFile(url, attachmentObj, false, () => {});
	};
}
