import _ from 'lodash';
import {RouteService} from "../../services/routeService";
import {NotificationService} from "../../services/notificationService";
import {GeneralService} from "../../services/generalService";
import {DICTIONARY} from "../../dictionary";
import {Component, OnInit} from "@angular/core";
import {ArchiveService} from "../../services/archiveService";
import JSZip from "jszip";
import {eachLimit} from 'async';
import {saveAs} from 'file-saver';


@Component({
	selector: 'inbox-component',
	templateUrl: './inbox.component.html',
})
export class InboxComponent implements OnInit {
	constructor(private rs:RouteService,
				private ns:NotificationService,
				public gs:GeneralService,
				public archiveService:ArchiveService){
	}

	dic = DICTIONARY;

	isLoaded = false;
	archiveEnabled = false;
	getEmailsInProcess = false;
	abortLoadingFlag = false;

	searchPeriod = [
		this.dic.CONSTANTS.trendsPeriod.lastDay,
		this.dic.CONSTANTS.trendsPeriod.last3Days,
		this.dic.CONSTANTS.trendsPeriod.lastWeek,
		this.dic.CONSTANTS.trendsPeriod.lastMonth,
		this.dic.CONSTANTS.trendsPeriod.range
	];

	compliances: any = [{name: "GDPR"}, {name: "FERPA"}, {name: "HIPAA"}, {name: "PCI"},
		{name: "CCPA"}, {name: "POPI"}, {name: "LGPD"}, {name: "PDPO"}, {name: "GLBA"}];

	emailQueryDisplay;
	queryType;
	queryTypes = {
		normal: 'normal',
		messageIdOnly: 'messageIdOnly'
	};
	resultsPeriod = this.dic.CONSTANTS.trendsPeriod.lastDay;
	range = {start: new Date(Date.now() - (1000 * 60 * 60 * 24)), end: new Date()}; // (past one day)
	isQueryError;
	showQueryModal;
	emailQuery;

	searchInboxEmailTxt;
	isSelectedAll;
	selectedEmails;
	_ = _;

	activeCase;

	previewEmailInProcess;
	downloadEml;

	ngOnInit() {
		this.archiveService.currentEmailsList = [];
		this.archiveService.currentFilteredEmailsList = [];
		this.archiveService.setCurrentEmail(null);

		this.initEmailQuery();

		this.rs.getInboxSettings().then((response) => {
			this.isLoaded = true;
			this.archiveEnabled = response.archive?.enabled;
		},(err)=>{
		});
	};

	ngOnDestroy(): void {
		this.abortLoadingFlag = true;
	}

	initEmailQuery = () => {
		this.queryType = this.queryTypes.normal;
		this.resultsPeriod = this.dic.CONSTANTS.trendsPeriod.lastDay;
		this.range = {start: new Date(Date.now() - (1000 * 60 * 60 * 24)), end: new Date()}; // (past one day)

		this.emailQuery = {
			subject: '',
			message_id: '',
		};

		this.emailQueryDisplay = [
			{
				title: this.gs.toCapitalize(this.resultsPeriod.display),
				tooltip: this.gs.toCapitalize(this.resultsPeriod.display)
			}
		];
	}

	changePeriod = (period) => {
		this.resultsPeriod = period;
	};

	abortLoadingEmails = () => {
		this.abortLoadingFlag = true;
		this.getEmailsInProcess = false;
	}

	applyQuery = () => {
		if (this.getEmailsInProcess) {
			return;
		}
		this.getEmailsInProcess = true;
		this.abortLoadingFlag = false;

		const params:any = this.getQueryForEmail();

		this.calculateQueryDisplay();

		this.showQueryModal = false;
		this.isQueryError = false;
		this.activeCase = true;

		this.archiveService.currentEmailsList = [];
		this.archiveService.currentFilteredEmailsList = [];

		this.rs.searchUserArchive(params).then((response) => {
			this.archiveService.setCurrentEmail(null);

			const emails = this.archiveService.currentEmailsList.concat((response.emails || []));

			this.archiveService.currentEmailsList = _.orderBy(emails, ['_source.created'], ['desc']);
			this.searchEmailInResults(this.searchInboxEmailTxt);

			if (response.scrollId) {
				this.applyCaseBatch(params, response.scrollId);
			}
			else {
				this.getEmailsInProcess = false;
			}
		},(err)=>{
			this.getEmailsInProcess = false;
		});
	}

	applyCaseBatch = (params, scrollId) => {
		if (this.abortLoadingFlag) {
			return;
		}
		if (scrollId) {
			params.scrollId = scrollId;
		}

		this.rs.searchUserArchiveWithScroll(params).then((response) => {

			if (response.emails?.length) {
				const emails = this.archiveService.currentEmailsList.concat(response.emails || []);

				this.archiveService.currentEmailsList = _.orderBy(emails, ['_source.created'], ['desc']);
				this.searchEmailInResults(this.searchInboxEmailTxt);
			}

			if (response.scrollId) {
				this.applyCaseBatch(params, response.scrollId);
			}
			else {
				this.getEmailsInProcess = false;
			}
		},(err)=>{
			this.getEmailsInProcess = false;
		});
	}

	getQueryForEmail = () => {
		if (this.queryType ===  this.queryTypes.messageIdOnly) {
			return {
				message_id: this.emailQuery.message_id || null
			}
		}
		else {
			const query: any = {
				period: this.resultsPeriod.value,
			};

			if (query.period === this.dic.CONSTANTS.trendsPeriod.range.value) {
				query.range = this.range;
			}

			if (this.emailQuery.subject) {
				query.subject = this.emailQuery.subject;
			}

			if (this.emailQuery.text) {
				query.text = this.emailQuery.text;
			}
			if (this.emailQuery.from) {
				query.from = this.emailQuery.from;
			}
			if (this.emailQuery.rcptTo) {
				query.rcptTo = this.emailQuery.rcptTo;
			}

			if (this.emailQuery.hasAttachments) {
				query.hasAttachments = this.emailQuery.hasAttachments;
			}

			const compliances = [];
			this.compliances.forEach((comp) => {
				if (comp.selected) {
					compliances.push(comp.name);
				}
			});
			this.emailQuery.compliance = compliances;
			if (compliances.length) {
				query.compliance = compliances;
			}

			return query;
		}
	}

	// determine how query properties look in the UI, in the query box
	calculateQueryDisplay = () => {
		// when query is through message ID only
		if (this.queryType ===  this.queryTypes.messageIdOnly) {
			this.emailQueryDisplay = [
				{
					title: 'message ID',
					tooltip: this.emailQuery.message_id
				}
			];
		}
		// when normal query
		else {
			this.emailQueryDisplay = [];

			// calculate period title and tooltip
			let periodTooltip = this.gs.toCapitalize(this.resultsPeriod.display);
			if (this.gs.equals(this.resultsPeriod, this.dic.CONSTANTS.trendsPeriod.range)) {
				periodTooltip = 'From ' + this.range.start + ' until ' + this.range.end;
			}
			this.emailQueryDisplay.push(
				{
					title: this.gs.toCapitalize(this.resultsPeriod.display),
					tooltip: periodTooltip
				}
			);
			//

			_.mapValues<any>(this.emailQuery,(value, queryKey) => {
				if (value) {
					switch (queryKey) {
						case 'message_id':
							break;

						case 'hasAttachments':
							this.emailQueryDisplay.push({title: 'Has Attachments', tooltip: 'true'});
							break;

						case 'rcptTo':
							this.emailQueryDisplay.push({title: 'Recipients', tooltip: value});
							break;

						case 'compliance':
							if (value?.length) {
								this.emailQueryDisplay.push({title: 'Compliance', tooltip: 'true'});
							}
							break;

						default:
							this.emailQueryDisplay.push({title: queryKey, tooltip: value});

					}
				}
			});
		}
	}

	getArchiveEmailInfo = (email) => {
		if (this.archiveService.currentMessage) {
			this.archiveService.currentMessage.show = false;
		}

		email.show = true;
		if (email.content) {
			this.archiveService.setCurrentEmail(email);
			this.archiveService.updateEmailFrame();
			return;
		}
		this.previewEmailInProcess = true;
		this.rs.getUserArchiveEmailInfo(encodeURIComponent(email._id)).then((response) => {
			email.content = response;

			if (email.content) {
				// calc Bcc according to rcptTo
				email.content.parsedContent.bcc = {value: []};
				email._source.rcptTo.forEach((recipientEmail) => {
					// if not in To/Cc then it's Bcc
					if (!((email.content.parsedContent.to?.text?.toLowerCase().indexOf(recipientEmail) > -1) ||
						(email.content.parsedContent.cc?.text?.toLowerCase().indexOf(recipientEmail) > -1))) {

						email.content.parsedContent.bcc.value.push({address: recipientEmail});
					}
				});
			}

			this.archiveService.setCurrentEmail(email);
			this.archiveService.updateEmailFrame();

			this.previewEmailInProcess = false;
		}, (err)=>{
			this.previewEmailInProcess = false;
		});
	}

	clearSearchEmail() {
		this.archiveService.currentEmailsList.forEach(email => {
			email.hide = false;
		});
		this.searchInboxEmailTxt = null;
		this.archiveService.currentFilteredEmailsList = _.reject(this.archiveService.currentEmailsList, 'hide');
		this.filterSelectedEmails();
	};

	searchEmailInResults(searchTerm) {
		if (!searchTerm) {
			this.clearSearchEmail();
			return;
		}

		searchTerm = searchTerm.toLowerCase();

		this.archiveService.currentEmailsList.forEach(email => {
			const isFound = searchTextExecute(email, searchTerm);
			email.hide = !isFound;
		});
		this.archiveService.currentFilteredEmailsList = _.reject(this.archiveService.currentEmailsList, 'hide');
		this.filterSelectedEmails();
	};

	exportResultsToCsv() {
		if (!this.archiveService.currentFilteredEmailsList.length) {
			this.ns.showWarnMessage(this.dic.ERRORS.noDataToExportCsv);
			return;
		}

		let csvString = "Subject,Date,From,Recipients,Attachments,Message ID\n";
		this.archiveService.currentFilteredEmailsList.forEach((email) => {
			csvString += `${email._source.subject},"${email._source.created}","${email._source.from}","${email._source.rcptTo && email._source.rcptTo.join(', ')}","${email._source.attachments && email._source.attachments.join(', ')}","${email._source.messageId}"\n`;
		});

		this.gs.exportCsv(csvString, `inbox_emails_list.csv`);
	};

	downloadMultipleEmls = () => {
		if (!this.selectedEmails.length) {
			return;
		}

		if (this.selectedEmails.length === 1) {
			this.rs.getUserArchiveEmailInfo(encodeURIComponent(this.selectedEmails[0]._id)).then((response) => {
				const attachment = {
					content: response.rawMail,
					name: `${response.parsedContent.subject}.eml`,
					contentType: 'message/rfc822'
				};
				this.gs.downloadData(attachment.content, attachment.name, attachment.contentType);
				this.ns.showInfoMessage(`Downloaded EML`);
			});
			return;
		}

		this.ns.showInfoMessage(`Downloading ${this.selectedEmails.length} EMLs... This may take a moment. Please wait.`);

		const zip = new JSZip();
		let filenames = {};
		eachLimit(this.selectedEmails, 10, (emailObj, callback) => {
			this.rs.getUserArchiveEmailInfo(encodeURIComponent(emailObj._id)).then((response) => {
				let emlFileName = removeNonAlphaNumeric(response.parsedContent.subject);
				if (!emlFileName) {
					emlFileName = `(No Subject)`;
				}
				if (filenames.hasOwnProperty(emlFileName)) {
					filenames[emlFileName]++;
					emlFileName = `${emlFileName}(${filenames[emlFileName]})`;
				}
				else {
					filenames[emlFileName] = 0;
				}
				zip.file(`${emlFileName}.eml`, response.rawMail);
				callback();
			});
		}, (err) => {
			filenames = null;

			zip.generateAsync({ type: 'blob' }).then((content) => {
				// Save the zip file
				saveAs(content, `inbox_emails.zip`);
				this.ns.showInfoMessage(`Download completed`);
			});
		})
	}

	toggleAllEmails = () => {
		this.archiveService.currentFilteredEmailsList.forEach(email => {
			email.selected = this.isSelectedAll;
		});
		this.filterSelectedEmails();
	};


	filterSelectedEmails = () => {
		this.selectedEmails = _.filter(this.archiveService.currentFilteredEmailsList, 'selected');
		this.isSelectedAll = this.selectedEmails.length === this.archiveService.currentFilteredEmailsList.length;
	};
}

function searchTextExecute(email, searchTerm) {
	searchTerm = searchTerm.toLowerCase();
	if (email._source.rcptTo) {
		for (let i = 0; i < email._source.rcptTo.length; i++) {
			if (email._source.rcptTo[i].indexOf(searchTerm) > -1) {
				return true;
			}
		}
	}
	return ((email._source.subject && email._source.subject.toLowerCase().indexOf(searchTerm) > -1) ||
		(email._source.from && email._source.from.toLowerCase().indexOf(searchTerm) > -1));
}

function removeNonAlphaNumeric(str) {
	return str?.replace(/[^a-z0-9\s_@]/gi, '');
}
