import * as util from 'util';
import _ from 'lodash';
import {eachLimit} from 'async';
import {RouteService} from "../../../../services/routeService";
import {NotificationService} from "../../../../services/notificationService";
import {GeneralService} from "../../../../services/generalService";
import {AuthService} from "../../../../services/authService";
import {DICTIONARY} from "../../../../dictionary";
import {Component, OnInit} from "@angular/core";
import {Router} from "@angular/router";

@Component({
	selector: 'inbound-mailboxes-component',
	templateUrl: './inbound-mailboxes.component.html',
})
export class InboundMailboxesComponent implements OnInit {
    constructor(private router:Router,
				public gs:GeneralService,
				private rs:RouteService,
				private ns:NotificationService,
				private authService:AuthService) {
    }

    dic = DICTIONARY;
	_=_;
    corpname = this.gs.corpname;
	mailboxMetricsPopup: any;
	viewContentPermissionsPopup: any;
	isLoaded = false;
    groups = [];
    subscriptionUsers = [];
    mailboxListView = this.dic.CONSTANTS.mailboxListViews.all;
    protectedUsersCount = 0;
    orderBy = '-is_protected';
    sortDomainBy = '-domainName'; // top-level items sorting when on "view per domain" mode
    mailServerConnected;
    graphConnected;
    gsuiteConnected;
    filterData;
    activeFilters;
    userInfo;
    planInfo;
    smtpConnected;
    threatProtectionAllowedUsers;
    threatProtectionEnabled;
    multipleEmailsActions;
    subscriptionUsersNotificationIdx;
    loadingInProcess;
    mailboxesSubscriptions;
    activeMailboxList;
    searchMailboxTxt;
    displayedUsersCount;
    curMailbox;
    mailboxAliasPopup;
    updateAliasInProcess;
    selectedUsers = [];
    createSubscriptionInProcess;
    selectedAll;
    showProtectGroups;
	showEnableApiProtectionPopup;
    mailboxThreatsPopup;
    personalReviewerPopup;
	notifyOptionsPopup;
    updatePersonalReviewerInProcess;
    importType;
    mailboxIndexInProcess;
    domains;
    showGroups;
    showAliases;
	mailboxesFile;
	showTableViewMenu;
	sortDomain;
	sortName;
	sortJobRole;
	sortEmail;
	sortStatus;
	sortLicense;
	sortPriority;
	sortThreats;
	filteredActiveMailboxList;
	hoursArr = [ "00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"];
	threatsPeriod = [
		this.dic.CONSTANTS.trendsPeriod.lastDay,
		this.dic.CONSTANTS.trendsPeriod.last3Days,
		this.dic.CONSTANTS.trendsPeriod.lastWeek,
		this.dic.CONSTANTS.trendsPeriod.lastMonth,
		this.dic.CONSTANTS.trendsPeriod.last3Months,
	];

	ngOnInit() {
        this.mailServerConnected = false;
        this.graphConnected = false;
        this.gsuiteConnected = false;
        this.initFilters();
        this.gs.getUserInfo(false, userInfo => {
            this.userInfo = userInfo;
        });

        this.rs.getInboundPlanSettings().then((planInfo) => {
            this.planInfo = planInfo;

            if (planInfo.exchange_server && planInfo.exchange_server.tid && planInfo.exchange_server.enabled) {
                this.mailServerConnected = true;
                this.graphConnected = true;
            }
            else if (planInfo.gsuite_server && planInfo.gsuite_server.enabled) {
                this.gsuiteConnected = true;
                this.mailServerConnected = true;
            }

            if (this.planInfo.inbound_relay &&
                (this.planInfo.inbound_relay.enabled || this.planInfo.inbound_relay.sending_architecture === this.dic.CONSTANTS.inboundSendingArchitecture.mx)) {
                this.mailServerConnected = true;
                this.smtpConnected = true;
            }

            this.threatProtectionAllowedUsers = 0;
            this.threatProtectionEnabled = planInfo.threat_protection.enabled;
            this.threatProtectionAllowedUsers = planInfo.threat_protection.enabled && planInfo.threat_protection.allowed_users || 0;
            this.protectedUsersCount = planInfo.threat_protection.used_users;

            if (this.mailServerConnected) {
                this.getMailboxesInfo();
            }
            else {
                this.isLoaded = true;
            }

            this.initActions();
        }, (err) => {
			this.isLoaded = true;
		});
    };

    initActions = () => {
        this.multipleEmailsActions = [
            this.dic.CONSTANTS.inboundMailboxAction.disable,
            this.dic.CONSTANTS.inboundMailboxAction.importContacts,
            this.dic.CONSTANTS.inboundMailboxAction.checkImpersonation,
            this.dic.CONSTANTS.inboundMailboxAction.scanThreatAI,
			this.dic.CONSTANTS.inboundMailboxAction.delete,
        ];

        if (this.graphConnected) {
            this.multipleEmailsActions.unshift(this.dic.CONSTANTS.inboundMailboxAction.enable);
        }
    };

    startLoadExchangeUsers =  () => {
		if (!this.graphConnected && !this.gsuiteConnected) {
			this.isLoaded = true;
			this.loadingInProcess = false;
			return;
		}

        this.subscriptionUsersNotificationIdx = this.ns.showInfoMessage('Retrieving mailboxes...', {timeout: 0});

		this.getExchangeMailboxesExecute(null);
    };

    getMailboxesInfo = () => {
        this.rs.getMailboxesInfo().then(data => {
            this.mailboxesSubscriptions = data;

            this.subscriptionUsers = [];

            if (!this.mailboxesSubscriptions.length) {
                return this.getMailboxesFromExchange();
            }

            let inactiveLimitDate = new Date();
            inactiveLimitDate.setDate(inactiveLimitDate.getDate() - this.dic.CONSTANTS.inactiveUsers);
            this.mailboxesSubscriptions.forEach((mailboxObj) => {
                if (mailboxObj.is_protected) {
                    mailboxObj.status = this.dic.CONSTANTS.inboundMailBoxStatus.protect;
                    if (mailboxObj.last_email && new Date(mailboxObj.last_email) < inactiveLimitDate) {
                        mailboxObj.status = this.dic.CONSTANTS.inboundMailBoxStatus.inactive;
                    }

					if (mailboxObj.is_fes) {
						if (this.planInfo.threat_protection.is_monitoring) {
							mailboxObj.protectedType = 'Monitor';
						}
						else {
							mailboxObj.protectedType = this.planInfo.inbound_relay.sending_architecture === this.dic.CONSTANTS.inboundSendingArchitecture.journal ? 'Journal' : 'Relay';
						}
					}
					else {
						mailboxObj.protectedType = 'API';
					}
                }
                else {
                    mailboxObj.status = this.dic.CONSTANTS.inboundMailBoxStatus.exposed;
                }
                mailboxObj.last_email_priority = !mailboxObj.last_email ? -1 : mailboxObj.last_email;
                mailboxObj.threats_priority = !mailboxObj.threats ? -1: mailboxObj.threats;

                this.subscriptionUsers.push(mailboxObj);
            });

            this.isLoaded = true;

            this.searchMailbox();
            this.updateListView(this.mailboxListView);
        });
    };


    getMailboxesFromExchange = () => {
        this.rs.getMailboxesInfo().then(data => {
            this.mailboxesSubscriptions = data;
            this.subscriptionUsers = [];
            this.activeMailboxList = [];
            this.startLoadExchangeUsers();
        });
    };

    getMailboxesRefresh = () => {
        if (this.graphConnected || this.gsuiteConnected) {
            if (this.loadingInProcess) {
                return;
            }
            this.loadingInProcess = true;
            this.getMailboxesFromExchange();
        }
        else {
            this.getMailboxesInfo();
        }

        // sync DB and exchange mailboxes
        this.rs.updateMailboxInfo({action: this.dic.CONSTANTS.inboundMailboxAction.syncMailboxes.value}).then(() => {});

        this.ns.showInfoMessage(this.dic.MESSAGES.operationsSuccess);
    };

    getExchangeMailboxesExecute = (nextLink) => {
        if (!this.mailServerConnected) {
            this.loadingInProcess = false;
            return;
        }

        const getUsers = this.gsuiteConnected ?
            this.rs.getGSuiteUsers() :
            this.rs.getExchangeUsers({nextLink: nextLink || ''});

        getUsers.then(data => {
            data.mailboxes.forEach(user => {
                let userSubscription = this.mailboxesSubscriptions && this.mailboxesSubscriptions.find(itm => itm.email.toLowerCase() === user.email.toLowerCase());
                if (userSubscription) {
                    user.is_protected = userSubscription.is_protected;
                    user.threats = userSubscription.threats;
                    user.last_email = userSubscription.last_email;
                    user.is_fes = userSubscription.is_fes || false;
                    user.is_personal_reviewer = userSubscription.is_personal_reviewer || false;
                    user.check_impersonation = userSubscription.check_impersonation || false;
                }
                else {
                    user.is_protected = false;
                    user.is_fes = false;
                }

                user.status = user.is_protected ? this.dic.CONSTANTS.inboundMailBoxStatus.protect : this.dic.CONSTANTS.inboundMailBoxStatus.exposed;
                user.last_email_priority = !user.last_email ? -1 : user.last_email;
                user.threats_priority = !user.threats ? -1: user.threats;
            });

            this.subscriptionUsers = this.subscriptionUsers.concat(data.mailboxes);
            this.updateListView(this.mailboxListView);

            if (this.searchMailboxTxt || this.activeFilters) {
                this.searchMailbox();
            }
            else {
                this.displayedUsersCount = this.subscriptionUsers.length;
                this.checkSelectAllUsers();
            }

            if (data.nextLink) {
                setTimeout(() => {
                    this.getExchangeMailboxesExecute(data.nextLink);
                }, 1000);
            }
            else {
                this.isLoaded = true;
                this.loadingInProcess = false;
                this.ns.overwriteMessage(this.subscriptionUsersNotificationIdx, `Finished retrieving mailboxes`);
            }
        }, err => {
            this.loadingInProcess = false;
        });
    };

	enableApiProtection = (mailboxes, options) => {
		this.showEnableApiProtectionPopup = {
			mailboxes
		};

		if (options?.limitedMailboxes) {
			this.showEnableApiProtectionPopup.showLimitedMailboxes = true;

			mailboxes = [];
			for (let i = 0; i < this.showEnableApiProtectionPopup.mailboxes.length; i++) {
				if (this.showEnableApiProtectionPopup.mailboxes[i].license === this.dic.CONSTANTS.inboundMailboxTypes.member &&
					!this.showEnableApiProtectionPopup.mailboxes[i].is_protected) {
					mailboxes.push(this.showEnableApiProtectionPopup.mailboxes[i]);
				}
			}
			if (!mailboxes?.length) {
				this.ns.showErrorMessage("Your plan currently does not have any unprotected licenses mailboxes");
				return;
			}
			this.showEnableApiProtectionPopup.mailboxes = mailboxes;
			this.showEnableApiProtectionPopup.limitedMailboxes = mailboxes.length;

		}

		if (!this.planInfo.exchange_server?.contacts_enabled) {
			this.showEnableApiProtectionPopup.importContacts = true;
		}

		this.showEnableApiProtectionPopup.show = true;
	}


	enableApiProtectionExecute = () => {
		let mailboxes = this.showEnableApiProtectionPopup.mailboxes;
		if (this.showEnableApiProtectionPopup.showLimitedMailboxes) {
			mailboxes = [];
			for (let i = 0; i < this.showEnableApiProtectionPopup.mailboxes.length && i < this.showEnableApiProtectionPopup.limitedMailboxes; i++) {
				if (this.showEnableApiProtectionPopup.mailboxes[i].license === this.dic.CONSTANTS.inboundMailboxTypes.member) {
					mailboxes.push(this.showEnableApiProtectionPopup.mailboxes[i]);
				}
			}
		}

		if (!mailboxes?.length) {
			this.ns.showErrorMessage("No mailboxes have been selected for API protection activation");
			return;
		}

		this.showEnableApiProtectionPopup.show = false;

		if (this.showEnableApiProtectionPopup.importContacts) {
			this.importContactsFromExchangeWithoutPopup(mailboxes);
		}

		let notIdx;
		if (mailboxes.length > 1) {
			notIdx = this.ns.showInfoMessage(`Going to enable API protection on ${mailboxes.length} mailbox${mailboxes.length > 1 ? 'es' : ''}. This action can take a while, please do not close this page.`, {timeout: 0});
		}

		let enabledMailboxes = 0;
		eachLimit(mailboxes, 5, (mailboxObj, callback) => {
			if (mailboxObj.is_protected) {
				return callback();
			}

			const updateData = {
				action: this.dic.CONSTANTS.inboundMailboxAction.enable.value,
				email: mailboxObj.email
			};

			this.createSubscriptionInProcess = true;
			this.rs.updateMailboxInfo(updateData).then(response => {
				this.createSubscriptionInProcess = false;
				mailboxObj.is_protected = true;
				mailboxObj.protectedType = 'API';
				mailboxObj.status = this.dic.CONSTANTS.inboundMailBoxStatus.protect;
				this.protectedUsersCount++;
				enabledMailboxes++;
				if (notIdx) {
				}					this.ns.overwriteMessage(notIdx, `API protection has been enabled for ${enabledMailboxes} mailboxes...`);

				callback();
			}, err => {
				console.log('Err: ', err);
				callback();
			});
		}, (err) => {
			if (enabledMailboxes) {
				this.ns.showInfoMessage(this.dic.MESSAGES.operationsSuccess);
			}
			this.createSubscriptionInProcess = false;
		});
	}

	disableApiProtection = (users) => {
		this.gs.showPopup({
			title: `Disable Protection`,
			subTitle: `Are you sure you want to disable protection for ${users.length > 1 ? `${users.length} mailboxes` : users[0].email}?`,
			body: ['For mailboxes that are protected via the inbound relay, emails sent to this mailbox will automatically enable protection on it again.' +
			' To resolve this, you can exclude the mailbox(es) from your Trustifi inbound mail flow rule.'],
			type: this.dic.CONSTANTS.popupWarning,
			doneBtnText: 'Disable',
			doneCb: () => {
				eachLimit(users, 1, (mailboxObj, callback) => {
					if (!mailboxObj.is_protected) {
						return callback();
					}

					if (mailboxObj.is_fes) {
						this.resetInboundRelayExecute(mailboxObj);
						return callback();
					}

					const updateData = {
						action: this.dic.CONSTANTS.inboundMailboxAction.disable.value,
						email: mailboxObj.email
					};

					this.createSubscriptionInProcess = true;
					this.rs.updateMailboxInfo(updateData).then(response => {
						this.createSubscriptionInProcess = false;
						mailboxObj.is_protected = false;
						mailboxObj.status = this.dic.CONSTANTS.inboundMailBoxStatus.exposed;
						this.protectedUsersCount--;
						this.ns.showInfoMessage(this.dic.MESSAGES.operationsSuccess);
						callback();
					}, err => {
						console.log('Err: ', err);
						callback();
					});
				}, (err) => {
					this.ns.showInfoMessage(this.dic.MESSAGES.operationsSuccess);
					this.createSubscriptionInProcess = false;
				});
			}
		});
	}

    showMailboxActions = (mailbox) => {
        mailbox.showActions = !mailbox.showActions;
        mailbox.actions = [];
        if (mailbox.showActions) {
            if (mailbox.is_protected) {
                if (mailbox.is_fes) {
                    mailbox.actions.push(this.dic.CONSTANTS.inboundMailboxAction.reset);
                }
                else {
                    mailbox.actions.push(this.dic.CONSTANTS.inboundMailboxAction.disable);
                }
                mailbox.actions.push(this.dic.CONSTANTS.inboundMailboxAction.setAlias, this.dic.CONSTANTS.inboundMailboxAction.importContacts);
            }
            else {
                if (this.graphConnected) {
                    mailbox.actions.push(this.dic.CONSTANTS.inboundMailboxAction.enable);
                }
            }

            mailbox.actions.push(this.dic.CONSTANTS.inboundMailboxAction.editPersonalReviewer,
				this.dic.CONSTANTS.inboundMailboxAction.editMailboxNotifyPeriods,
				this.dic.CONSTANTS.inboundMailboxAction.viewContentPermission,
				this.dic.CONSTANTS.inboundMailboxAction.mailboxMetrics,
				this.dic.CONSTANTS.inboundMailboxAction.threatDistribution,
				this.dic.CONSTANTS.inboundMailboxAction.delete);

            this.closeOtherActions(mailbox);
        }
    };

    selectMailboxItem = () => {
        this.closeItemActions(this.curMailbox);
        this.checkSelectAllUsers();
    };

    showHideDomainMailboxes = (domainObj, forceShow = null) => {
        if (!domainObj.users || !domainObj.users.length) {
            return;
        }

        domainObj.showUsers = forceShow || !domainObj.showUsers;

        const index = this.activeMailboxList.findIndex((domainOrMailbox) => domainOrMailbox.domainName === domainObj.domainName);

        // show
        if (domainObj.showUsers) {
            domainObj.users.forEach(userObj => {
                userObj.parent_domain = domainObj.domainName;
            });
            this.activeMailboxList.splice(index + 1, 0, ...domainObj.users);
        }
        // hide
        else {
            this.activeMailboxList.splice(index + 1, domainObj.users.length);
        }

        this.sortUpdate(this.orderBy, () => {});
    }

    sortUpdate = (sortKey, isDomainLevel = null) => {
        if (isDomainLevel) {
            this.sortDomainBy = sortKey;
        }
        else {
            this.orderBy = sortKey;
        }
        const orderDomainDirection = this.sortDomainBy.startsWith('-') ? 'desc' : 'asc';
        const orderByDirection = this.orderBy.startsWith('-') ? 'desc' : 'asc';
        let orderBy = this.orderBy;
        if (this.orderBy.startsWith('-')) {
            orderBy = orderBy.substring(1);
        }

        if (this.mailboxListView === this.dic.CONSTANTS.mailboxListViews.domains) {
            this.activeMailboxList = _.orderBy(this.activeMailboxList, [this.getDomainName, 'domainName', orderBy], [orderDomainDirection, 'asc', orderByDirection]);
        }
        else {
            this.activeMailboxList = _.orderBy(this.activeMailboxList, [orderBy], [orderByDirection]);
        }
		this.updateFilteredList();
    }

    getDomainName = (domainOrMailbox) => {
        return (domainOrMailbox.domainName && domainOrMailbox.domainName.toLowerCase()) || (domainOrMailbox.parent_domain && domainOrMailbox.parent_domain.toLowerCase());
    }

    closeOtherActions = (mailbox) => {
        if (!mailbox) {
            return;
        }

        if (this.curMailbox) {
            if (this.curMailbox.email === mailbox.email) {
                return;
            }
            else {
                // close other email actions
                this.closeItemActions(this.curMailbox);
            }
        }
        this.curMailbox = mailbox;
    };

    closeItemActions = (curMailbox) => {
        if (!curMailbox) {
            return;
        }
        curMailbox.showActions = false;
        curMailbox.actions = [];
    }

    selectAction =  (user, action) => {
        if (!user) {
            return;
        }

        switch (action) {
            case this.dic.CONSTANTS.inboundMailboxAction.threatDistribution.value:
                this.openThreatsGraph(user);
                break;

            case this.dic.CONSTANTS.inboundMailboxAction.enable.value:
				this.enableApiProtection([user], null);
				break;

            case this.dic.CONSTANTS.inboundMailboxAction.disable.value:
				this.disableApiProtection([user]);
                break;

            case this.dic.CONSTANTS.inboundMailboxAction.setAlias.value:
                this.setMailboxAliasPopup(user);
                break;

			case this.dic.CONSTANTS.inboundMailboxAction.mailboxMetrics.value:
				this.openMailboxMetricsPopup(user);
				break;

			case this.dic.CONSTANTS.inboundMailboxAction.viewContentPermission.value:
				this.openViewContentPermissionPopup(user);
				break;

            case this.dic.CONSTANTS.inboundMailboxAction.reset.value:
                this.resetInboundRelay(user);
                break;

			case this.dic.CONSTANTS.inboundMailboxAction.delete.value:
				this.deleteMailbox([user]);
				break;

            case this.dic.CONSTANTS.inboundMailboxAction.editPersonalReviewer.value:
                this.openPersonalReviewerPopup(user);
                break;

			case this.dic.CONSTANTS.inboundMailboxAction.editMailboxNotifyPeriods.value:
				this.openNotifyOptionsPopup(user);
				break;

            case this.dic.CONSTANTS.inboundMailboxAction.importContacts.value:
                this.importContacts([user]);
                break;
        }
    };

    selectMultipleEmailsAction = (selectedUsers, action, enabled = null) => {
        if (!selectedUsers?.length) {
            return;
        }

        switch (action) {
            case this.dic.CONSTANTS.inboundMailboxAction.enable.value:
				this.enableApiProtection(selectedUsers, null);
				break;

            case this.dic.CONSTANTS.inboundMailboxAction.disable.value:
                this.disableApiProtection(selectedUsers);
                break;

            case this.dic.CONSTANTS.inboundMailboxAction.importContacts.value:
                this.importContacts(selectedUsers);
                break;

            case this.dic.CONSTANTS.inboundMailboxAction.checkImpersonation.value:
                this.setMultipleCheckImpersonation(selectedUsers, enabled);
                break;

			case this.dic.CONSTANTS.inboundMailboxAction.scanThreatAI.value:
				this.setMultipleScanThreatAI(selectedUsers, enabled);
				break;

			case this.dic.CONSTANTS.inboundMailboxAction.delete.value:
				this.deleteMailbox(selectedUsers);
				break;
        }
    };

	openNotifyOptionsPopup = (mailboxInfo) => {
		// create model for notifyReviewerPeriods dropdown selection (initial values from this.config)
		const notifyReviewerPeriods = {};
		_.forEach(this.dic.CONSTANTS.tpRule.notifyOptions,(periodName, periodKey) => {
			notifyReviewerPeriods[periodName] = mailboxInfo.notify_reviewer_periods && mailboxInfo.notify_reviewer_periods.includes(periodName);
		});
		let notifyReviewerDailyHours = [];
		if (mailboxInfo.notify_reviewer_daily_hour) {
			notifyReviewerDailyHours = mailboxInfo.notify_reviewer_daily_hour.split(', ');
			for (let i = 0; i < notifyReviewerDailyHours.length; i++) {
				let formattedHour:any = new Date();
				formattedHour.setUTCHours(notifyReviewerDailyHours[i]);
				formattedHour = (formattedHour.getHours() < 10 ? '0' : '') + formattedHour.getHours();
				notifyReviewerDailyHours[i] = formattedHour;
			}
		}

		this.notifyOptionsPopup = {
			mailboxInfo,
			periods: notifyReviewerPeriods,
			dailyHours: notifyReviewerDailyHours,
			isNotifyReviewerPeriodsError: false,
			isNotifyReviewerDailyHoursError: false,
			changeNotifyPeriodInProcess: false,
			notifyRecipientAllowChangeTime: this.planInfo.threat_protection.rules.notify_recipient_allow_change_time,
			show: true,
		};
	}

	changeNotifyPeriod = () => {
		if (!this.notifyOptionsPopup.notifyRecipientAllowChangeTime) {
			this.ns.showErrorMessage('You need to enable checkbox: Allow users to set their own custom notification times');
			return;
		}

		if (this.notifyOptionsPopup.changeNotifyPeriodInProcess) {
			return;
		}

		// periods validation
		const isOneReviewerPeriodEnabled = _.some(this.notifyOptionsPopup.periods, period => {return !!period});
		if (!isOneReviewerPeriodEnabled) {
			this.notifyOptionsPopup.isNotifyReviewerPeriodsError = true;
		}

		this.notifyOptionsPopup.isNotifyReviewerDailyHoursError = this.notifyOptionsPopup.periods[this.dic.CONSTANTS.tpRule.notifyOptions.everyDay] && !this.notifyOptionsPopup.dailyHours.length;

		if (!isOneReviewerPeriodEnabled || this.notifyOptionsPopup.isNotifyReviewerDailyHoursError) {
			return;
		}
		//

		// make arrays of strings from the periods objects (pick only enabled periods)
		const notifyReviewerPeriodsArr = _.keys(_.pickBy(this.notifyOptionsPopup.periods));

		// turn the local hours in the "daily_hour" models into UTC hours for the BE
		let notifyReviewerDailyHoursUTC = _.cloneDeep(this.notifyOptionsPopup.dailyHours);
		for (let i =0 ; i < notifyReviewerDailyHoursUTC.length; i++) {
			let formattedHour:any = new Date();
			formattedHour.setHours(notifyReviewerDailyHoursUTC[i]);
			formattedHour = (formattedHour.getUTCHours() < 10 ? '0' : '') + formattedHour.getUTCHours();
			notifyReviewerDailyHoursUTC[i] = formattedHour;
		}
		//

		const data = {
			email: this.notifyOptionsPopup.mailboxInfo.email,
			action: this.dic.CONSTANTS.inboundMailboxAction.editMailboxNotifyPeriods.value,
			notifyReviewerPeriods: notifyReviewerPeriodsArr,
			notify_reviewer_daily_hour: notifyReviewerDailyHoursUTC.join(', '),
		};

		this.notifyOptionsPopup.changeNotifyPeriodInProcess = true;

		this.rs.updateMailboxInfo(data).then( (response) => {
			this.notifyOptionsPopup.mailboxInfo.notify_reviewer_periods = notifyReviewerPeriodsArr;
			this.notifyOptionsPopup.mailboxInfo.notify_reviewer_daily_hour = notifyReviewerDailyHoursUTC.join(', ');
			this.planInfo.threat_protection.rules.notify_recipient_allow_change_time = true;
			this.ns.showInfoMessage(this.dic.MESSAGES.changedSuccessfully);

			this.notifyOptionsPopup = null;
		}, (err) => {
			if (err && err.data && err.data.message) {
				this.ns.showErrorMessage(err.data.message);
			}
			this.notifyOptionsPopup.changeNotifyPeriodInProcess = false;
		});
	};

	openMailboxMetricsPopup = (mailbox) => {
		this.mailboxMetricsPopup = {
			mailboxObj: mailbox,
			show: true
		};
	}

	setCheckImpersonationForMailbox = (mailboxObj) => {
		const updateData = {
			action: this.dic.CONSTANTS.inboundMailboxAction.checkImpersonation.value,
			email: mailboxObj.email,
			enabled: mailboxObj.check_impersonation
		};

		this.rs.updateMailboxInfo(updateData).then(response => {
		}, err => {
			console.log('Err: ', err);
		});
	};

	setScanThreatAIForMailbox = (mailboxObj) => {
		const updateData = {
			action: this.dic.CONSTANTS.inboundMailboxAction.scanThreatAI.value,
			email: mailboxObj.email,
			enabled: mailboxObj.scan_threat_ai
		};

		this.rs.updateMailboxInfo(updateData).then(response => {
		}, err => {
			console.log('Err: ', err);
		});
	}

	openViewContentPermissionPopup = (mailbox) => {
		this.viewContentPermissionsPopup = {
			mailboxObj: mailbox,
			show: true
		};
	}

	setViewContentPermissionReviewers = (mailboxObj) => {
		const updateData = {
			action: this.dic.CONSTANTS.inboundMailboxAction.viewContentPermission.value,
			email: mailboxObj.email,
			enabled: mailboxObj.view_content.allow_reviewers,
			type: 'allow_reviewers'
		};

		this.rs.updateMailboxInfo(updateData).then(response => {
		}, err => {
			console.log('Err: ', err);
		});
	}

	setViewContentPermissionAdmins = (mailboxObj) => {
		const updateData = {
			action: this.dic.CONSTANTS.inboundMailboxAction.viewContentPermission.value,
			email: mailboxObj.email,
			enabled: mailboxObj.view_content.allow_admins,
			type: 'allow_admins'
		};

		this.rs.updateMailboxInfo(updateData).then(response => {
		}, err => {
			console.log('Err: ', err);
		});
	}

    openProtectGroupsPopup = (show) => {
        this.showProtectGroups = show;
    };

    applyProtectGroupsPopup = (groups) => {
		const usersToApply = [];
        groups.forEach((groupObj) => {
            if (groupObj.selected && groupObj.members?.length) {
				groupObj.members.forEach((memberObj) => {
					this.subscriptionUsers.forEach((mailboxObj) => {
						if (memberObj.email === mailboxObj.email.toLowerCase()) {
							usersToApply.push(mailboxObj);
						}
					});
				});
            }
        });

        this.showProtectGroups = false;
		this.selectMultipleEmailsAction(usersToApply, this.dic.CONSTANTS.inboundMailboxAction.enable.value);
    };


    protectMailboxesFromCsv = (file) => {
        if (!file) return;
        const validationFunctions = [this.gs.validateEmail];
        this.gs.readCsv(file, ['email'], null, this.dic.CONSTANTS.EMAIL_REGEX, validationFunctions, (err, results) => {
            if (err) {
                return;
            }

			const usersToApply = [];
			results['email'].forEach((emailAddress) => {
				this.subscriptionUsers.forEach((mailboxObj) => {
					if (emailAddress === mailboxObj.email.toLowerCase()) {
						usersToApply.push(mailboxObj);
					}
				});
			});

			this.selectMultipleEmailsAction(usersToApply, this.dic.CONSTANTS.inboundMailboxAction.enable.value);
        });
    };

    clearSearchMailbox =  () => {
        this.searchMailboxTxt = null;
        this.subscriptionUsers.forEach(user => {
            user.hide = false;
        });
        if (this.activeFilters) {
            this.searchMailbox();
        }
        else {
            this.filterSelectedUsers();
            this.displayedUsersCount = this.subscriptionUsers.length;
            return;
        }

        this.checkSelectAllUsers();
    };

    searchMailbox =  () => {
        let searchTxt = this.searchMailboxTxt;
        if (!searchTxt && !this.activeFilters) {
            this.subscriptionUsers.forEach(user => {
                user.hide = false;
            });
            this.filterSelectedUsers();
            this.displayedUsersCount = this.subscriptionUsers.length;
            return;
        }

        if (searchTxt) {
            searchTxt = searchTxt.toLowerCase();
        }
        this.displayedUsersCount = 0;
        this.subscriptionUsers.forEach(user => {
            if (searchTxt) {
                if ((!user.name || !user.name.toLowerCase().includes(searchTxt))
					&& (!user.job_title || !user.job_title.toLowerCase().includes(searchTxt))
                    && (!user.email || !user.email.toLowerCase().includes(searchTxt))) {
                    user.hide = true;
                    return;
                }
            }

            if (this.activeFilters) {
                if (this.activeFilters.status && this.activeFilters.status.length) {
                    if (!this.activeFilters.status.includes(user.status)) {
                        user.hide = true;
                        return;
                    }
                }
                if (this.activeFilters['email relay'] && this.activeFilters['email relay'].length) {
                    if (!this.activeFilters['email relay'].includes(user.is_fes)) {
                        user.hide = true;
                        return;
                    }
                }
				if (this.activeFilters['executives'] && this.activeFilters['executives'].length) {
					if (!this.activeFilters['executives'].includes(user.is_executive)) {
						user.hide = true;
						return;
					}
				}
                if (this.activeFilters['impersonation check'] && this.activeFilters['impersonation check'].length) {
                    if (!this.activeFilters['impersonation check'].includes(user.check_impersonation)) {
                        user.hide = true;
                        return;
                    }
                }
                if (this.activeFilters['personal reviewer'] && this.activeFilters['personal reviewer'].length) {
                    if (this.activeFilters['personal reviewer'].includes('Allow Release Malicious') && !user.reviewer_allow_release_malicious) {
                        user.hide = true;
                        return;
                    }
                    if (!this.activeFilters['personal reviewer'].includes(user.is_personal_reviewer)) {
                        user.hide = true;
                        return;
                    }
                }
                if (this.activeFilters.license && this.activeFilters.license.length) {
                    if (!this.activeFilters.license.includes(user.license)) {
                        user.hide = true;
                        return;
                    }
                }
            }

            user.hide = false;
            this.displayedUsersCount += 1;
        });
        this.filterSelectedUsers();
    };

    exportMailboxesCsv =  () => {
        const displayedTable = _.filter(this.subscriptionUsers, user => { return !user.hide});

        if (!displayedTable.length) {
            this.ns.showWarnMessage(this.dic.ERRORS.noDataToExportCsv);
            return;
        }

        let csvString = "Name,Email Address,Job Role,Status,Type,Last Email,Threats\n";

        let sortedTable = this.gs.sortTable(displayedTable, this.orderBy);
        sortedTable.forEach((mailbox) => {
			const statusMode = mailbox.is_protected && (mailbox.is_fes ? this.planInfo.threat_protection.is_monitoring ? ' (Monitor)' : ' (Relay)' : ' (API)') || ''
			csvString += `"${mailbox.name}",${mailbox.email},"${mailbox.job_title || 'N/A'}",${mailbox.status + (statusMode)},${mailbox.license},${mailbox.last_email || 'None'},${mailbox.threats || 'None'}\n`;
        });

        this.gs.exportCsv(csvString, `inbound_mailboxes.csv`);
    };

	changeThreatPeriod = (inputPeriod) => {
		this.mailboxThreatsPopup.period = inputPeriod;
		this.getThreatsMailboxGraph();
	};

    openThreatsGraph =  (mailboxObj) => {
		if (!mailboxObj.threats) {
            return this.ns.showWarnMessage(this.dic.ERRORS.noMailboxThreats);
        }

		this.mailboxThreatsPopup = {
			show: true,
			period: this.dic.CONSTANTS.trendsPeriod.lastWeek,
			mailboxObj: mailboxObj,
			inProcess: true
		};

		this.getThreatsMailboxGraph();
    };

	getThreatsMailboxGraph =  () => {
		this.mailboxThreatsPopup.inProcess = true;

		const params = {period: this.mailboxThreatsPopup.period.value};
		this.rs.getThreatsForMailbox(this.mailboxThreatsPopup.mailboxObj.email, params).then(threats => {
			this.mailboxThreatsPopup.inProcess = false;

			if (!threats.data.external && !threats.data.graymail && !threats.data.malicious &&
				!threats.data.spam && !threats.data.suspicious) {
				this.mailboxThreatsPopup.graph = null;
				return;
			}

			this.mailboxThreatsPopup.graph = {
				series: Object.values(threats.data),
				chart: {
					type: 'donut',
				},
				plotOptions: {
					pie: {
						donut: {
							size: '52%',
						}
					}
				},
				dataLabels: {
					enabled: true,
					formatter:  (val, opt) => {
						return opt.w.globals.series[opt.seriesIndex];
					}
				},
				colors: ['#BE1111', '#efde6e', '#262415', '#757373', '#0C9305'],
				labels: threats.labels,
				tooltip: {
					y: {
						formatter:  (val) => {
							return val + ' emails';
						}
					}
				}
			};
		}, (err) => {
			this.mailboxThreatsPopup.inProcess = false;
		});
	};

	deleteMailbox = (users) => {
		const title = users.length > 1 ? `Delete ${users.length} Mailboxes` : `Delete Mailbox ${users[0].email}`;
		const subTitle = users.length > 1 ? `You are about to delete ${users.length} Mailboxes` : `You are about to delete mailbox ${users[0].email}`;

		this.gs.showPopup({
			title: title,
			subTitle: subTitle,
			body: ['If this mailbox is still connected to inbound protection it will be created again when it receives a new email'],
			type: this.dic.CONSTANTS.popupWarning,
			doneBtnText: 'Delete',
			doneCb: () => {
				users.forEach((user) => {
					this.deleteMailboxExecute(user);
				});
			}
		});
	};

	deleteMailboxExecute = (user) => {
		const data = {
			action: this.dic.CONSTANTS.inboundMailboxAction.delete.value,
			email: user.email
		};
		this.rs.updateMailboxInfo(data).then(() => {
			this.protectedUsersCount--;
			this.filteredActiveMailboxList.forEach(item => {
				if (item.email === user.email) {
					item.selected = false;
				}
			});
			this.activeMailboxList = this.activeMailboxList.filter(item => item.email !== user.email);
			this.checkSelectAllUsers();
		});
	}


	resetInboundRelay = (user) => {
        this.gs.showPopup({
            title: `Reset protection for ${user.email}`,
            subTitle: `You are about to reset the Inbound Email Relay for ${user.email}`,
            body: ['Inbound Email Relay will be re-enabled when an email arrives to this mailbox'],
            type: this.dic.CONSTANTS.popupWarning,
            doneBtnText: 'Reset',
            doneCb: () => {
                this.resetInboundRelayExecute(user);
            }
        });
    };

    resetInboundRelayExecute = (user) => {
		const data = {
			action: this.dic.CONSTANTS.inboundMailboxAction.reset.value,
			email: user.email
		};
        this.rs.updateMailboxInfo(data).then(() => {
            user.is_fes = false;
            user.is_protected = false;

            let userSubscription = this.mailboxesSubscriptions && this.mailboxesSubscriptions.find(itm => itm.email === user.email);
            if (userSubscription) {
                if (userSubscription.mailbox_subscription) {
                    user.is_protected = true;
                }
                else {
                    user.is_protected = false;
                    this.protectedUsersCount--;
                }
            }
            else {
                user.is_protected = false;
            }

            user.status = user.is_protected ? this.dic.CONSTANTS.inboundMailBoxStatus.protect : this.dic.CONSTANTS.inboundMailBoxStatus.exposed;
        });
    }

    setMailboxAliasPopup = (mailbox) => {
        this.mailboxAliasPopup = {
            mailbox: mailbox,
            aliasInput: '',
            show: true
        };
    }

    updateMailboxAlias = () => {
        if (this.updateAliasInProcess) {
            return;
        }
        if (!this.mailboxAliasPopup.aliasInput) {
            this.ns.showWarnMessage(util.format(this.dic.ERRORS.emptyField, 'Mailbox'));
            return;
        }
        if (!this.gs.validateEmail(this.mailboxAliasPopup.aliasInput)) {
            this.ns.showWarnMessage(util.format(this.dic.ERRORS.invalidEmail, this.mailboxAliasPopup.aliasInput));
            return;
        }

        this.updateAliasInProcess = true;

        const updateData:any = {
            action: this.dic.CONSTANTS.inboundMailboxAction.setAlias.value,
            email: this.mailboxAliasPopup.mailbox.email,
            alias: this.mailboxAliasPopup.aliasInput.toLowerCase()
        };

        this.rs.updateMailboxInfo(updateData).then(() => {
            this.updateAliasInProcess = false;

            if (this.mailboxesSubscriptions.find(itm => itm.email && itm.email.toLowerCase() === updateData.alias)) {
                _.remove<any>(this.mailboxesSubscriptions, (itm:any) => itm.email && itm.email.toLowerCase() === updateData.alias);
            }
            else {
                this.mailboxAliasPopup.mailbox.email = updateData.alias;
            }

            this.mailboxAliasPopup = null;

            this.ns.showInfoMessage(this.dic.MESSAGES.operationsSuccess);
        }, (err) => {
            this.updateAliasInProcess = false;
            if (!err.data.display_bar) {
                this.ns.showErrorMessage(err.data.message);
            }
        });
    }

    openPersonalReviewerPopup = (user) => {
        this.personalReviewerPopup = {
            activeUser: user,
            reviewerAllowReleaseMalicious: user.reviewer_allow_release_malicious,
            isPersonalReviewer: user.is_personal_reviewer,
			notifyReviewerDailyHours: [],
            periods: {},
            periodsError: false,
			periodsHoursError: false,
            show: false
        }

        // create model for personalReviewerPopup dropdown selection (initial values from this.config)
        _.forEach(this.dic.CONSTANTS.tpRule.notifyOptions,(periodName, periodKey) => {
            this.personalReviewerPopup.periods[periodName] = user.notify_reviewer_periods && user.notify_reviewer_periods.includes(periodName);
        })
		let notifyReviewerDailyHours = [];
		if (user.notify_reviewer_daily_hour) {
			notifyReviewerDailyHours = user.notify_reviewer_daily_hour.split(', ');
			for (let i = 0; i < notifyReviewerDailyHours.length; i++) {
				let formattedHour:any = new Date();
				formattedHour.setUTCHours(notifyReviewerDailyHours[i]);
				formattedHour = (formattedHour.getHours() < 10 ? '0' : '') + formattedHour.getHours();
				notifyReviewerDailyHours[i] = formattedHour;
			}
		}
		this.personalReviewerPopup.notifyReviewerDailyHours = notifyReviewerDailyHours;
		this.personalReviewerPopup.show = true;
    };

    updatePersonalReviewer = () => {
        if (this.updatePersonalReviewerInProcess) {
            return;
        }

        const user = this.personalReviewerPopup.activeUser;

        // periods validation
        const isOneReviewerPeriodEnabled = _.find(this.personalReviewerPopup.periods,period => {return !!period;});
        if (!isOneReviewerPeriodEnabled) {
            // call "periods required!" error only if reviewer enabled, otherwise - set periods to default (immidiately only)
            if (this.personalReviewerPopup.isPersonalReviewer) {
                this.personalReviewerPopup.periodsError = true;
                return;
            }
        }
        //
        // make array of strings from the periods object (pick only enabled periods)
        const notifyReviewerPeriodsArr = _.keys(_.pickBy(this.personalReviewerPopup.periods));
        if (!notifyReviewerPeriodsArr.length) {
            notifyReviewerPeriodsArr.push(this.dic.CONSTANTS.tpRule.notifyOptions.immediately);
        }
        //
		// turn the local hours in the "daily_hour" models into UTC hours for the BE
		let notifyReviewerDailyHoursUTC = _.cloneDeep(this.personalReviewerPopup.notifyReviewerDailyHours);
		for (let i =0 ; i < notifyReviewerDailyHoursUTC.length; i++) {
			let formattedHour:any = new Date();
			formattedHour.setHours(notifyReviewerDailyHoursUTC[i]);
			formattedHour = (formattedHour.getUTCHours() < 10 ? '0' : '') + formattedHour.getUTCHours();
			notifyReviewerDailyHoursUTC[i] = formattedHour;
		}
		if (this.personalReviewerPopup.periods[this.dic.CONSTANTS.tpRule.notifyOptions.everyDay] && !notifyReviewerDailyHoursUTC.length ) {
			this.personalReviewerPopup.periodsHoursError = true;
			return;
		}

        const updateData:any = {
            action: this.dic.CONSTANTS.inboundMailboxAction.editPersonalReviewer.value,
            email: user.email,
            notifyReviewerPeriods: notifyReviewerPeriodsArr,
			notify_reviewer_daily_hour: notifyReviewerDailyHoursUTC.join(', '),
            isPersonalReviewer: this.personalReviewerPopup.isPersonalReviewer || false,
            reviewerAllowReleaseMalicious: this.personalReviewerPopup.reviewerAllowReleaseMalicious || false
        };

        this.updatePersonalReviewerInProcess = true;
        this.rs.updateMailboxInfo(updateData).then(() => {
            this.updatePersonalReviewerInProcess = false;
            user.is_personal_reviewer = updateData.isPersonalReviewer;
			user.notify_reviewer_daily_hour = notifyReviewerDailyHoursUTC.join(', ');
            user.reviewer_allow_release_malicious = updateData.reviewerAllowReleaseMalicious;
            user.notify_reviewer_periods = notifyReviewerPeriodsArr;
            this.personalReviewerPopup.show = false;
        }, (err) => {
            this.updatePersonalReviewerInProcess = true;
            if (!err.data.display_bar) {
                this.ns.showErrorMessage(err.data.message);
            }
        });
    }

    importContacts = (mailboxes) => {
        if (!mailboxes?.length) {
            return this.ns.showWarnMessage(this.dic.ERRORS.mailboxesImportContacts);
        }

        if (this.importType === this.dic.CONSTANTS.importContactsType.exchange) {
            if (this.planInfo.gsuite_server?.enabled) {
                this.importContactsFromGoogle(mailboxes);
            }
            else {
                this.importContactsFromExchange(mailboxes);
            }
        }
        else {
            this.importContactsFromCSV(mailboxes);
        }
    };

    setMultipleCheckImpersonation = (mailboxes, enabled) => {
        let title, body = [], type;
        if (enabled) {
            title = 'enable';
            body.push('The sender will be checked to see if they are impersonating the recipient\'s contacts');
            type = this.dic.CONSTANTS.popupInfo;
        }
        else {
            title = 'disable';
            body.push('The sender will not be checked to see if they are impersonating the recipient\'s contacts.');
            type = this.dic.CONSTANTS.popupWarning;
        }
        this.gs.showPopup({
            title: `${this.gs.toCapitalize(title)} impersonation check`,
            subTitle: `You are about to ${title} impersonation check for ${mailboxes.length} mailboxes`,
            body: body,
            type: type,
            doneBtnText: this.gs.toCapitalize(title),
            doneCb: () => {
				mailboxes.forEach((mailboxObj) => {
					mailboxObj.check_impersonation = enabled;
					this.setCheckImpersonationForMailbox(mailboxObj);
				});
            }
        });
    };

	setMultipleScanThreatAI = (mailboxes, enabled) => {
		let title, subTitle;
		if (enabled) {
			title = 'enable';
			subTitle = 'Incoming emails will be scanned using AI to detect Business Email Compromise (BEC) attacks';
		}
		else {
			title = 'disable';
			subTitle = 'Incoming emails will not be scanned using AI to detect Business Email Compromise (BEC) attacks';
		}
		this.gs.showPopup({
			title: `${this.gs.toCapitalize(title)} Scan Threat AI`,
			subTitle: subTitle,
			type: this.dic.CONSTANTS.popupInfo,
			doneBtnText: this.gs.toCapitalize(title),
			doneCb: () => {
				mailboxes.forEach((mailboxObj) => {
					mailboxObj.scan_threat_ai = enabled;
					this.setScanThreatAIForMailbox(mailboxObj);
				});
			}
		});
	};

    importContactsFromGoogle = (mailboxes) => {
        if (this.planInfo.gsuite_server?.enabled) {
            this.importContactsFromGooglePopup(mailboxes);
        }
        else {
            this.ns.showWarnMessage('Please go to the Inbound -> Plan Settings page and connect to Google Workspace.');
        }
    };

    importContactsFromExchange = (mailboxes) => {
        if (!this.planInfo.exchange_server.enabled) {
            this.ns.showWarnMessage('Please go to the Inbound -> Plan Settings page and connect to the Exchange Server.');
            return;
        }

        if (this.planInfo.exchange_server && this.planInfo.exchange_server.contacts_enabled) {
            this.importContactsFromExchangePopup(mailboxes);
        }
        else {
            this.authService.authMicrosoftExchangeContacts(this.userInfo.email, this.planInfo.exchange_server.tid, this.planInfo.exchange_server.azuread_endpoint, this.planInfo.exchangeContactAppId, (err) => {
                if (err) {
                    if (err !== true) this.ns.showWarnMessage(err);
                }
                else {
                    this.planInfo.exchange_server.contacts_enabled = true;
                    this.importContactsFromExchangePopup(mailboxes);
                }
            });
        }
    };

    importContactsFromGooglePopup = (mailboxes) => {
        this.gs.showPopup({
            title: `Import Contacts From Google GSuite`,
            subTitle: `You are about to import contacts from Google GSuite`,
            body: ["The user's contacts will be imported from Google GSuite into their Trustifi contact list",
                "Importing user contacts is highly recommended to help prevent contact impersonation attacks."],
            type: this.dic.CONSTANTS.popupInfo,
            doneBtnText: 'Import',
            doneCb: () => {
                this.importContactsFromExchangeExecute(mailboxes);
            }
        });
    };

    importContactsFromExchangePopup = (mailboxes) => {
        this.gs.showPopup({
            title: `Import Contacts From Exchange`,
            subTitle: `You are about to import contacts from Exchange`,
            body: ["The user's contacts will be imported from Exchange into their Trustifi contact list", "Importing user contacts is highly recommended to help prevent contact impersonation attacks."],
            type: this.dic.CONSTANTS.popupInfo,
            doneBtnText: 'Import',
            doneCb: () => {
                this.importContactsFromExchangeExecute(mailboxes);
            }
        });
    };

    importContactsFromExchangeWithoutPopup = (mailboxes) => {
        this.authService.authMicrosoftExchangeContacts(this.userInfo.email, this.planInfo.exchange_server.tid, this.planInfo.exchange_server.azuread_endpoint, this.planInfo.exchangeContactAppId, (err) => {
            if (err) {
                if (err !== true) this.ns.showWarnMessage(err);
            }
            else {
                this.planInfo.exchange_server.contacts_enabled = true;
                this.importContactsFromExchangeExecute(mailboxes);
            }
        });
    };

    importContactsFromExchangeExecute = (mailboxes) => {
        mailboxes.forEach(mailbox => {
			if (mailbox.is_protected) {
				const data = {
					action: this.dic.CONSTANTS.inboundMailboxAction.importContacts.value,
					email: mailbox.email
				};

				this.rs.updateMailboxInfo(data).then(() => {
					this.ns.showInfoMessage(util.format(this.dic.MESSAGES.itemAdded, 'Contacts'));
				}, err => {
					this.ns.showWarnMessage(util.format(this.dic.ERRORS.remainedUnprotected, mailbox.email));
				});
			}
        });
    };

    importContactsFromCSV = (mailboxes) => {
        const body = [
            'File type must be a Comma Separated Value (.csv).',
            'The first row must contain the field names.',
            'Email addresses should appear in the Email column.',
            'Complex text fields (that include spaces or other symbols) must be surrounded with double quotation marks.',
            'Optional columns: "Name", "Country_code", "Phone_number".'
        ];
        this.gs.showPopup({
            title: 'Import Contacts From CSV',
            subTitle: 'To ensure '+this.corpname+' can successfully handle your data, it should meet the following conditions:',
            body: body,
            type: this.dic.CONSTANTS.popupInfo,
            doneBtnText: 'OK',
            isFile: true,
            doneCb: (file) => {
                if (!file || !file.length) return;
                const validationFunctions = [this.gs.validateEmail];
                this.gs.readCsv(file[0], ['email'], ['name', 'country_code', 'phone_number'], this.dic.CONSTANTS.EMAIL_REGEX, validationFunctions, (err, results) => {
                    if (err || !results.email || !results.email.length) {
                        return;
                    }
                    let contacts = [], contact;
                    for (let i = 0; i < results.email.length; i++) {
                        contact = {};
                        contact.email = results.email[i];
                        if (results.name && results.name[i]) {
                            contact.name = results.name[i];
                        }
                        if (results.country_code && results.country_code[i] && results.phone_number && results.phone_number[i]) {
                            contact.phone = {
                                country_code: results.country_code[i],
                                phone_number: results.phone_number[i]
                            }
                        }

                        contacts.push(contact);
                    }
                    this.mailboxIndexInProcess = 0;
                    const notificationIdx = this.ns.showInfoMessage(util.format(this.dic.MESSAGES.importContactsStart, mailboxes.length));
                    this.importContactsFromCSVExecute(mailboxes, contacts, notificationIdx);
                });
            }
        });
    };

    importContactsFromCSVExecute = (mailboxes, contacts, notificationIdx) => {
		const data = {
			action: this.dic.CONSTANTS.inboundMailboxAction.uploadContacts.value,
			email: mailboxes[this.mailboxIndexInProcess].email,
			contacts: contacts
		};
        this.rs.updateMailboxInfo(data).then(() => {
            this.mailboxIndexInProcess++;
            this.ns.overwriteMessage(notificationIdx, util.format(this.dic.MESSAGES.importContactsProgress, this.mailboxIndexInProcess, mailboxes.length));
            if (this.mailboxIndexInProcess < mailboxes.length) {
                this.importContactsFromCSVExecute(mailboxes, contacts, notificationIdx);
            }
            else {
                this.ns.overwriteMessage(notificationIdx, util.format(this.dic.MESSAGES.itemAdded, 'Contacts'));
            }
        });
    };

    initFilters = () => {
        this.filterData = {
			filterType: this.dic.CONSTANTS.tableFilters.mailboxes,
            filters: {
                license: [this.dic.CONSTANTS.inboundMailboxTypes.member, this.dic.CONSTANTS.inboundMailboxTypes.guest,
                    this.dic.CONSTANTS.inboundMailboxTypes.unlicensed, this.dic.CONSTANTS.inboundMailboxTypes.onpremises,
                    this.dic.CONSTANTS.inboundMailboxTypes.disabled],
                status: [this.dic.CONSTANTS.inboundMailBoxStatus.protect, this.dic.CONSTANTS.inboundMailBoxStatus.exposed, this.dic.CONSTANTS.inboundMailBoxStatus.inactive],
                'email relay': [true, false],
				'executives': [true, false],
                'personal reviewer': [true, false, 'Allow Release Malicious'],
                'impersonation check': [true, false]
            },
			initFiltersFunction: this.initFilters,
        };
    };

	toggleFilter =  () => {
        if (!this.filterData) return;
		this.filterData.show = !this.filterData.show;
    };

    clearFilters =  () => {
        this.activeFilters = null;
        this.searchMailbox();
    };

	applyFilters(activeFilters) {
		if (!activeFilters) return;

		this.activeFilters = activeFilters;
		this.filterData.show = false;

		if (this.activeMailboxList?.length) {
			this.searchMailbox();
		}
	};

    checkSelectAllUsers = () => {
        this.filterSelectedUsers();
        this.selectedAll = this.selectedUsers.length === this.subscriptionUsers.length;
    };

    filterSelectedUsers = () => {
        this.selectedUsers = _.filter(this.subscriptionUsers, user => {return (user.selected)});
		// show or hide domain titles in "per domain" view
        if (this.mailboxListView === this.dic.CONSTANTS.mailboxListViews.domains) {
            _.filter(this.activeMailboxList, 'domainName').forEach(domainObj => { // get all domain title items only, and for each one -
                domainObj.hide = !_.reject(domainObj.users, 'hide').length;
            })
        }
		this.updateFilteredList();
	};

    toggleAllUsers = () => {
        this.subscriptionUsers.forEach( user => {
            if (!user.hide) {
                user.selected = this.selectedAll;
            }
        });

        this.filterSelectedUsers();
        this.closeItemActions(this.curMailbox);
    };

	goToPlanSettingsPage = () => {
		this.router.navigate([this.dic.CONSTANTS.appStates.adminInbound, this.dic.CONSTANTS.adminPages.inbound.plan, this.dic.CONSTANTS.inboundPlanSettingsPageTabs.emailFlowIntegration]);
    };

    getGroups =  (show) => {
        this.showGroups = show;
    };

    getMailboxesAliases =  (show) => {
        this.showAliases = show;
    };

	getDailyHoursText = (listOfHours) => {
		return _.map(listOfHours, hourStr => hourStr + ':00');
	}

	toggleNotificationDailyHour = (listOfHours, targetHour) => {
		if (listOfHours.includes(targetHour)) {
			_.remove(listOfHours, hour => hour === targetHour);
		}
		else {
			listOfHours.push(targetHour);
		}
	}

    updateListView = (listView) => {
        this.mailboxListView = listView;
        switch (listView) {
            case this.dic.CONSTANTS.mailboxListViews.all:
                this.activeMailboxList = this.subscriptionUsers;
                break;

            case this.dic.CONSTANTS.mailboxListViews.domains:
                this.createPerDomainList();
                this.activeMailboxList = this.domains;
                break;
        }
        this.sortUpdate(this.orderBy, () => {} );
    };

    createPerDomainList = () => {
        this.domains = [];

        this.subscriptionUsers.forEach(user => {
            const userDomain = user.email.substring(user.email.lastIndexOf('@') + 1,user.email.length);
            // check if this domain exist in new array and then insert the user into it,
            // or if not exist: add a new one and then insert the user into it
            let domainInNewList = _.find<any>(this.domains, newDomainObj => {return newDomainObj.domainName === userDomain});
            if (domainInNewList) {
                user['parent_domain'] = userDomain;
                domainInNewList.users.push(user);
            }
            else {
                this.domains.push({
                    domainName: userDomain,
                    users: [user]
                });
            }
        });
    };

	updateFilteredList() {
		this.filteredActiveMailboxList = _.reject(this.activeMailboxList, user => user.hide);
		this.displayedUsersCount = this.filteredActiveMailboxList.length;
	}
}
