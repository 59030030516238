import {Component, OnInit, ViewChild} from '@angular/core';
import {DICTIONARY} from "../../../../../dictionary";
import {GeneralService} from "../../../../../services/generalService";
import {RouteService} from "../../../../../services/routeService";
import {NotificationService} from "../../../../../services/notificationService";
import {ChartComponent} from "ng-apexcharts";
import html2pdf from 'html2pdf.js';
import util from "util";
import _ from 'lodash';
import {LookAndFeelService} from "../../../../../services/lookAndFeelService";

@Component({
  selector: 'app-classification-trends',
  templateUrl: './classification-trends.component.html',
  styleUrls: ['./classification-trends.component.scss']
})
export class ClassificationTrendsComponent implements OnInit {

	constructor(public gs:GeneralService,
				private rs:RouteService,
				public lfs:LookAndFeelService,
				private ns:NotificationService) {
	}

	@ViewChild('classificationTypesChart', {static: false}) classificationTypesChart: ChartComponent;
	@ViewChild('classificationSensitivePerDomainChart', {static: false}) classificationSensitivePerDomainChart: ChartComponent;
	@ViewChild('classificationSensitiveSenderChart', {static: false}) classificationSensitiveSenderChart: ChartComponent;
	@ViewChild('classificationComplianceTrendsChart', {static: false}) classificationComplianceTrendsChart: ChartComponent;
	@ViewChild('complianceTypesChart', {static: false}) complianceTypesChart: ChartComponent;

	userInfo;
	classificationTypesChartOptions;
	classificationSensitivePerDomainOptions;
	classificationSensitiveSenderOptions;
	classificationComplianceTrendsOptions;
	classificationComplianceTypesOptions;

	showClassificationTypesTips;
	showSensitivePerDomainTips;
	showSensitiveSenderTips;
	showComplianceTypesTips;
	showComplianceTrendsTips;

	emailsPopup;
	showSensitiveInfoPopup;

	dic = DICTIONARY;

	sensitivityConfiguration;
	generatePdfInProcess;

	period = this.dic.CONSTANTS.trendsPeriod.last3Days;
	range = {start: new Date(Date.now() - (1000 * 60 * 60 * 24 * 7)), end: new Date()};
	todayDate = new Date();
	showTrendsCustomPeriod;
	showAbortLoadingEmailsLabel;
	abortLoadingFlag;
	getDataInProcess;
	emails = [];

	dashboardPeriod = [
		this.dic.CONSTANTS.trendsPeriod.lastDay,
		this.dic.CONSTANTS.trendsPeriod.last3Days,
		this.dic.CONSTANTS.trendsPeriod.lastWeek,
		this.dic.CONSTANTS.trendsPeriod.lastMonth,
		this.dic.CONSTANTS.trendsPeriod.last3Months,
		this.dic.CONSTANTS.trendsPeriod.range
	];

	ngOnInit() {
		this.gs.getUserInfo(false, (userInfo) => {
			this.userInfo = userInfo;
		});

		this.getSensitivityConfiguration();
		this.getSensitivityTrends();
	}

	ngOnDestroy(): void {
		this.abortLoadingFlag = true;
	}

	getSensitivityConfiguration = () => {
		this.rs.getSensitivityConfiguration().then((response) => {
			this.sensitivityConfiguration = response;
		}, () => {});
	}

	updateMonitorMode = () => {
		const actionInfo = {
			action: this.dic.CONSTANTS.sensitivityActions.monitor,
			enabled: this.sensitivityConfiguration.sensitivity_monitor_mode
		};

		this.rs.updateSensitivityConfiguration(actionInfo).then((response) => {
			this.ns.showInfoMessage(this.dic.MESSAGES.changedSuccessfully);
		}, (err) => {
			this.sensitivityConfiguration.sensitivity_monitor_mode = false;
		});
	}

	getSensitivityTrends = () => {
		this.getDataInProcess = true;

		const params: any = {period: this.period.value, size: 100};
		if (this.period === this.dic.CONSTANTS.trendsPeriod.range) {
			params.range = {
				start: this.range.start,
				end: this.range.end
			};
		}
		this.rs.getSensitivityTrends(params).then((emails) => {
			this.emails = emails;
			this.showAbortLoadingEmailsLabel = true;
			this.getDataInProcess = false;

			this.prepareTrendsData(false);
			this.getNextEmailsBatch(emails, params);
		}, (err) => {
		});
	}

	getNextEmailsBatch = (emails, params) => {
		if (!emails?.length || this.abortLoadingFlag) {
			this.abortLoadingFlag = false;
			this.showAbortLoadingEmailsLabel = false;
			return;
		}

		params.size = 1000;
		const lastEmail: any = _.minBy(emails, (email) => new Date(email.created))
		params.lastCreated = lastEmail.created;

		this.rs.getSensitivityTrends(params).then((batchEmails) => {
			if (batchEmails?.length) {
				this.emails = this.emails.concat(batchEmails);
				this.prepareTrendsData(true);
				this.getNextEmailsBatch(batchEmails, params);
			}
			else {
				this.showAbortLoadingEmailsLabel = false;
			}
		});
	}

	prepareTrendsData = (isUpdate) => {
		const trendsData = this.prepareEmailsForTrends();

		this.prepareClassificationTypesGraph(trendsData.classificationTypes, isUpdate);

		this.prepareClassificationSensitivePerDomainGraph(trendsData.sensitiveDomains, isUpdate);

		this.prepareClassificationSensitiveSenderGraph(trendsData.sensitiveSenders, isUpdate);

		this.prepareClassificationComplianceTrendsGraph(trendsData.compliance, isUpdate);

		this.prepareCompliancesTypesGraph(trendsData.compliancesTypes, isUpdate);
	}

	prepareEmailsForTrends = () => {
		// @ts-ignore
		this.emails.sort((a, b) => new Date(a.created) - new Date(b.created));

		const trendsData = {
			compliance: {
				labels: [],
				data: [],
				series: [],
			},

			classificationTypes: {},
			compliancesTypes: {},
			sensitiveDomains: {},
			sensitiveSenders: {},
		};

		this.emails.forEach((emailObj) => {
			if (emailObj.sensitivity?.score < 2.5) {
				return;
			}

			// for BC
			if (emailObj.sensitivity.sensitive_sub_type && !emailObj.sensitivity.sensitive_types?.length) {
				emailObj.sensitivity.sensitive_types = [{sensitive_type: emailObj.sensitivity.sensitive_sub_type, compliance: emailObj.sensitivity.compliance || []}];
			}

			if (emailObj.sensitivity.sensitive_types?.length) {
				emailObj.sensitivity.sensitive_types.forEach((sensitiveObj) => {
					sensitiveObj.compliance?.forEach((compliance) => {
						if (!trendsData.compliance.series.includes(compliance)) {
							trendsData.compliance.series.push(compliance);
						}
					});
				});
			}
		});

		trendsData.compliance.series.forEach((itm) => {
			trendsData.compliance.data.push([]);
		});

		let complianceSourcePerPeriod: any = {dateStart: false, dateEnd: undefined};

		this.emails.forEach((emailObj) => {
			if (emailObj.sensitivity?.score < 2.5) {
				return;
			}

			// for BC
			if (emailObj.sensitivity.sensitive_sub_type && !emailObj.sensitivity.sensitive_types?.length) {
				emailObj.sensitivity.sensitive_types = [{sensitive_type: emailObj.sensitivity.sensitive_sub_type, compliance: emailObj.sensitivity.compliance || []}];
			}

			// classificationTypes
			if (emailObj.sensitivity?.sensitive_types?.length) {
				emailObj.sensitivity.sensitive_types.forEach((sensitiveObj) => {
					if (!trendsData.classificationTypes[sensitiveObj.sensitive_type]) {
						trendsData.classificationTypes[sensitiveObj.sensitive_type] = 0;
					}
					trendsData.classificationTypes[sensitiveObj.sensitive_type]++;
				});
			}

			// sensitiveDomains
			if (emailObj.sensitivity?.score === 5 && emailObj.recipients_display_only) {
				if (emailObj.recipients_display_only.to?.length) {
					emailObj.recipients_display_only.to.forEach((addressObj) => {
						const domain = this.gs.getEmailDomain(addressObj.address);
						if (!trendsData.sensitiveDomains[domain]) {
							trendsData.sensitiveDomains[domain] = 0;
						}
						trendsData.sensitiveDomains[domain]++;
					});
				}

				if (emailObj.recipients_display_only.cc?.length) {
					emailObj.recipients_display_only.cc.forEach((addressObj) => {
						const domain = this.gs.getEmailDomain(addressObj.address);
						if (!trendsData.sensitiveDomains[domain]) {
							trendsData.sensitiveDomains[domain] = 0;
						}
						trendsData.sensitiveDomains[domain]++;
					});
				}
			}

			// sensitiveSenders
			if (emailObj.sensitivity?.score === 5 && emailObj.from?.email) {
				if (!trendsData.sensitiveSenders[emailObj.from.email]) {
					trendsData.sensitiveSenders[emailObj.from.email] = 0;
				}
				trendsData.sensitiveSenders[emailObj.from.email]++;
			}

			// compliance
			if (!complianceSourcePerPeriod.dateStart) {
				complianceSourcePerPeriod = this.initEmailSourcePerPeriod(trendsData.compliance, emailObj, this.period.value);
			}
			if (this.gs.isSamePeriod(this.period.value, emailObj.created, complianceSourcePerPeriod.dateEnd)) {
				if (emailObj.sensitivity?.sensitive_types?.length) {
					let compliances: any = {};
					emailObj.sensitivity.sensitive_types.forEach((sensitiveObj) => {
						if (sensitiveObj.compliance?.length) {
							sensitiveObj.compliance.forEach((compliance) => {
								compliances[compliance] = 1;
							});
						}
					});

					Object.keys(compliances).forEach(key => {
						complianceSourcePerPeriod.compliances[key]++;
					});
				}
			}
			else {
				this.addComplianceData(trendsData, complianceSourcePerPeriod, this.period.value);

				complianceSourcePerPeriod = this.initEmailSourcePerPeriod(trendsData.compliance, emailObj, this.period.value);
				if (emailObj.sensitivity?.sensitive_types?.length) {
					let compliances: any = {};
					emailObj.sensitivity.sensitive_types.forEach((sensitiveObj) => {
						if (sensitiveObj.compliance?.length) {
							sensitiveObj.compliance.forEach((compliance) => {
								compliances[compliance] = 1;
							});
						}
					});

					Object.keys(compliances).forEach(key => {
						complianceSourcePerPeriod.compliances[key]++;
					});
				}
			}
		});

		this.addComplianceData(trendsData, complianceSourcePerPeriod, this.period.value);

		return trendsData;
	}

	initEmailSourcePerPeriod(reportDataTrends, currentSource, period) {
		const complianceSourcePerPeriod = this.gs.calcDatesForPeriod(currentSource, period);
		complianceSourcePerPeriod.compliances = _.zipObject(reportDataTrends.series, Array(reportDataTrends.series.length).fill(0));
		return complianceSourcePerPeriod;
	}

	addComplianceData(trendsData, complianceSourcePerPeriod, period) {
		if (!complianceSourcePerPeriod.compliances) {
			return;
		}

		if (complianceSourcePerPeriod.dateStart) {
			const dateLabel = this.gs.getPeriodLabel(period, complianceSourcePerPeriod);
			trendsData.compliance.labels.push(dateLabel);

			Object.entries(complianceSourcePerPeriod.compliances).forEach(([key, value], index) => {
				trendsData.compliance.data[index].push(value || 0);
			});
		}

		// compliancesTypes
		Object.entries(complianceSourcePerPeriod.compliances).forEach(([key, value], index) => {
			if (!trendsData.compliancesTypes[key]) {
				trendsData.compliancesTypes[key] = 0;
			}
			trendsData.compliancesTypes[key] += value;
		});

	}

	prepareClassificationTypesGraph = (classificationTypes, isUpdate) => {

		// @ts-ignore
		const sortedTypes = Object.fromEntries(Object.entries(classificationTypes).sort((a, b) => b[1] - a[1]));
		const sortedTypesArray = Object.entries(sortedTypes).map(([type, count]) => ({ type, count }));

		const series = [];
		const labels = [];
		for (let i = 0; i < sortedTypesArray.length && i < 10; i++) {
			series.push(sortedTypesArray[i].count);
			labels.push(sortedTypesArray[i].type);
		}

		if (isUpdate && this.classificationTypesChartOptions?.labels?.length && this.gs.areArraysEqual(this.classificationTypesChartOptions.labels, labels)) {
			this.classificationTypesChart.updateSeries(series, true);
			return;
		}

		this.classificationTypesChartOptions = {
			series,
			chart: {
				type: 'pie',
				height: '100%',
				events: {
					dataPointSelection: (event, chartContext, config) => {
						this.showEmailsFromGraph('classificationTypes', this.classificationTypesChartOptions.labels[config.dataPointIndex])
					}
				},
			},
			colors: this.gs.chartColors,
			labels: labels,
			plotOptions: {
				pie: {
					donut: {
						size: '52%',
					}
				}
			},
		};
	}

	prepareCompliancesTypesGraph = (complianceTypes, isUpdate) => {
		// @ts-ignore
		const sortedTypes = Object.fromEntries(Object.entries(complianceTypes).sort((a, b) => b[1] - a[1]));
		const sortedTypesArray = Object.entries(sortedTypes).map(([type, count]) => ({ type, count }));

		const series = [];
		const labels = [];
		for (let i = 0; i < sortedTypesArray.length && i < 10; i++) {
			series.push(sortedTypesArray[i].count);
			labels.push(sortedTypesArray[i].type);
		}

		if (isUpdate && this.classificationComplianceTypesOptions?.labels?.length && this.gs.areArraysEqual(this.classificationComplianceTypesOptions.labels, labels)) {
			this.complianceTypesChart.updateSeries(series, true);
			return;
		}

		this.classificationComplianceTypesOptions = {
			series,
			chart: {
				type: 'donut',
				height: '100%',
				events: {
					dataPointSelection: (event, chartContext, config) => {
						this.showEmailsFromGraph('complianceTypes', this.classificationComplianceTypesOptions.labels[config.dataPointIndex])
					}
				},
			},
			colors: this.gs.chartColors,
			labels: labels,
			plotOptions: {
				pie: {
					donut: {
						size: '52%',
					}
				}
			},
		};
	}

	prepareClassificationComplianceTrendsGraph = (compliance, isUpdate) => {

		const series = [];
		compliance.series.forEach((categoryName, index) => {
			series.push({
				name: categoryName,
				data: compliance.data[index],
				color: this.gs.chartColors[index]
			});
		});

		if (isUpdate && this.classificationComplianceTrendsOptions?.series?.length &&
			this.gs.areArraysEqual(compliance.labels, this.classificationComplianceTrendsOptions.xaxis.categories)) {
			this.classificationComplianceTrendsChart.updateSeries(series, true);
			return;
		}

		this.classificationComplianceTrendsOptions = {
			series,
			chart: {
				type: compliance.labels?.length === 1 ? 'bar' : 'area',
				height: '100%',
				toolbar: {
					show: false
				},
				animations: {
					enabled: true,
				},
				events: {
					click: (event, chartContext, config) => {
						console.log(this.classificationComplianceTrendsOptions.xaxis.categories[config.dataPointIndex]);
						this.showEmailsFromGraph('classificationComplianceTrends', this.classificationComplianceTrendsOptions.xaxis.categories[config.dataPointIndex])
					}
				},
			},
			plotOptions: {
				bar: {
					borderRadius: 5,
					horizontal: false,
				},
			},
			dataLabels: {
				enabled: false
			},
			stroke: {
				show: true,
				width: 4,
				colors: ['transparent']
			},
			xaxis: {
				tickAmount: 4, // Set the maximum number of ticks (labels) to be displayed on the x-axis
				categories: compliance.labels,
			},
			yaxis: {
				min: 0,
				title: {
					text: 'Compliance'
				}
			},
			fill: {
				opacity: 1
			},
		};
	}

	prepareClassificationSensitivePerDomainGraph = (sensitiveDomains, isUpdate) => {
		// @ts-ignore
		const sortedTypes = Object.fromEntries(Object.entries(sensitiveDomains).sort((a, b) => b[1] - a[1]));
		const sortedTypesArray = Object.entries(sortedTypes).map(([domain, count]) => ({ domain, count }));

		const series = [{name: 'Domain', data: []}];
		const labels = [];
		for (let i = 0; i < sortedTypesArray.length && i < 10; i++) {
			series[0].data.push(sortedTypesArray[i].count);
			labels.push(sortedTypesArray[i].domain);
		}

		if (isUpdate && this.classificationSensitivePerDomainOptions?.xaxis?.categories?.length &&
			this.gs.areArraysEqual(labels, this.classificationSensitivePerDomainOptions.xaxis.categories)) {
			this.classificationSensitivePerDomainChart.updateSeries(series, true);
			return;
		}

		this.classificationSensitivePerDomainOptions = {
			series,
			chart: {
				type: 'bar',
				height: '100%',
				toolbar: {
					show: false
				},
				animations: {
					enabled: true,
				},
				events: {
					dataPointSelection: (event, chartContext, config) => {
						this.showEmailsFromGraph('sensitiveDomains', this.classificationSensitivePerDomainOptions.xaxis.categories[config.dataPointIndex])
					}
				},
			},
			colors: [
				'#a80404'
			],
			plotOptions: {
				bar: {
					borderRadius: 4,
					horizontal: true,
					barHeight: this.gs.getOptimalBarWidth(labels.length) + '%',
				}
			},
			dataLabels: {
				total: {
					enabled: true,
					offsetX: 0,
					style: {
						fontSize: '13px',
						fontWeight: 900
					}
				}
			},
			xaxis: {
				categories: labels,
			},
		};
	}

	prepareClassificationSensitiveSenderGraph = (sensitiveSenders, isUpdate) => {
		// @ts-ignore
		const sortedTypes = Object.fromEntries(Object.entries(sensitiveSenders).sort((a, b) => b[1] - a[1]));
		const sortedTypesArray = Object.entries(sortedTypes).map(([domain, count]) => ({ domain, count }));

		const series = [{name: 'Sender', data: []}];
		const labels = [];
		for (let i = 0; i < sortedTypesArray.length && i < 10; i++) {
			series[0].data.push(sortedTypesArray[i].count);
			labels.push(sortedTypesArray[i].domain);
		}

		if (isUpdate && this.classificationSensitiveSenderOptions?.xaxis?.categories?.length &&
			this.gs.areArraysEqual(labels, this.classificationSensitiveSenderOptions.xaxis.categories)) {
			this.classificationSensitiveSenderChart.updateSeries(series, true);
			return;
		}

		this.classificationSensitiveSenderOptions = {
			series,
			chart: {
				type: 'bar',
				height: '100%',
				toolbar: {
					show: false
				},
				animations: {
					enabled: true,
				},
				events: {
					dataPointSelection: (event, chartContext, config) => {
						this.showEmailsFromGraph('sensitiveSenders', this.classificationSensitiveSenderOptions.xaxis.categories[config.dataPointIndex])
					}
				},
			},
			colors: [
				'#a80404'
			],
			plotOptions: {
				bar: {
					borderRadius: 4,
					horizontal: true,
					barHeight: this.gs.getOptimalBarWidth(labels.length) + '%',
				}
			},
			dataLabels: {
				total: {
					enabled: true,
					offsetX: 0,
					style: {
						fontSize: '13px',
						fontWeight: 900
					}
				}
			},
			xaxis: {
				categories: labels,
			},
		};
	}

	abortLoadingEmails = () => {
		this.abortLoadingFlag = true;
		this.showAbortLoadingEmailsLabel = false;
		this.getDataInProcess = false;
	}

	changePeriod(periodObj) {
		this.period = periodObj;
		this.getSensitivityTrends();
	}

	showEmailsFromGraph(graphType, value) {
		if (this.userInfo.type === this.dic.CONSTANTS.userTypes.dlp) {
			return;
		}

		let emails = [];

		this.emails.forEach((emailObj) => {
			if (emailObj.sensitivity?.score < 2.5) {
				return;
			}

			switch (graphType) {
				case 'classificationTypes':
					if (emailObj.sensitivity?.sensitive_types?.length) {
						if (emailObj.sensitivity.sensitive_types.find(itm => itm.sensitive_type === value)) {
							emails.push(emailObj);
						}
					}
					break;

				case 'classificationComplianceTrends':
					console.log(emailObj.created);
					const dateObj = new Date(emailObj.created);
					const day = dateObj.getUTCDate();
					const month = dateObj.getUTCMonth() + 1; // getUTCMonth() returns 0-based month, so add 1
					const formattedDate = `${month}/${day}`;
					if (formattedDate === value.split(' ')[1]) {
						emails.push(emailObj);
					}
					break;

				case 'complianceTypes':
					if (emailObj.sensitivity?.sensitive_types?.length) {
						if (emailObj.sensitivity.sensitive_types.find(itm => itm.compliance?.includes(value))) {
							emails.push(emailObj);
						}
					}
					break;

				case 'sensitiveSenders':
					if (emailObj.sensitivity?.score === 5 && emailObj.from?.email === value) {
						emails.push(emailObj);
					}
					break;

				case 'sensitiveDomains':
					let addedByDomain = false;
					if (emailObj.sensitivity?.score === 5 && emailObj.recipients_display_only) {
						if (emailObj.recipients_display_only.to?.length) {
							emailObj.recipients_display_only.to.forEach((addressObj) => {
								if (!addedByDomain && this.gs.getEmailDomain(addressObj.address) === value) {
									addedByDomain = true;
									emails.push(emailObj);
								}
							});
						}

						if (emailObj.recipients_display_only.cc?.length) {
							emailObj.recipients_display_only.cc.forEach((addressObj) => {
								if (!addedByDomain && this.gs.getEmailDomain(addressObj.address) === value) {
									addedByDomain = true;
									emails.push(emailObj);
								}
							});
						}
					}
					break;
			}
		});

		if (!emails.length) {
			return;
		}

		this.emailsPopup = {
			show: true,
			emails
		};
	}

	openSensitiveInfoPopup(email) {
		this.showSensitiveInfoPopup = {
			show: true,
			email,
			emailTitle: email.sent.title,
			sensitivity: email.sensitivity
		};
	}

	searchEmails = (event) => {
		this.emailsPopup.emails.forEach(record => {
			// search
			if (event.searchTerm) {
				const isFound = searchEmailTextExecute(record, event.searchTerm);
				if (!isFound) {
					record.hide = true;
					return;
				}
			}

			record.hide = false;
		});
	}

	exportChartsToPdf = () => {
		if (!this.emails?.length) {
			return;
		}
		this.generatePdfInProcess = true;

		// set the relevant elements' visual properties to be ideal for the PDF page before copying the HTML
		let areaForPdf:any = document.getElementById('trendsChartsContainer');
		areaForPdf.classList.add('trends-pdf-layout');
		setTimeout(() => {
			this.classificationComplianceTrendsChart.render();
			this.classificationTypesChart.render();
			this.classificationSensitiveSenderChart.render();
			this.classificationSensitivePerDomainChart.render();
		}, 300);

		// timeout is needed to let the css enough time to update on screen and for the responsive charts to resize
		setTimeout(() => {
			const element = document.getElementById('trendsChartsContainer');
			const opt = {
				margin:       40,
				filename:     'classification_report.pdf',
				image:        { type: 'jpeg', quality: 1 },
				html2canvas:  { scale: 2 , width: 1450, windowWidth: 1700},
				jsPDF:        { unit: 'px', format: 'a4', orientation: 'portrait', hotfixes: ['px_scaling']}
			};

			// New Promise-based usage:
			html2pdf().set(opt).from(element).then(() => {
				setTimeout(() => {
					this.exitFromPdfGeneration();
				})
			}).save();
		},800);
	}

	exitFromPdfGeneration = () => {
		this.ns.showInfoMessage(util.format(this.dic.MESSAGES.downloadSuccess, 'Trends and insights data'));

		document.getElementById('trendsChartsContainer').classList.remove('trends-pdf-layout');
		this.generatePdfInProcess = false;
		// rerender charts so they'll fit their container's size
		this.getDataInProcess = true;

		setTimeout(() => {
			this.getDataInProcess = false;
		});
	}
}


function searchEmailTextExecute(emailObj, searchTerm) {
	searchTerm = searchTerm.toLowerCase();
	if ((emailObj.from?.email?.toLowerCase().indexOf(searchTerm) > -1) ||
		(emailObj.sent?.title?.toLowerCase().indexOf(searchTerm) > -1)) {
		return true;
	}

	if (emailObj.recipients_display_only?.to?.length) {
		if (emailObj.recipients_display_only.to.find(itm => itm.address?.toLowerCase().indexOf(searchTerm) > -1)) {
			return true;
		}
	}

	if (emailObj.recipients_display_only?.cc?.length) {
		if (emailObj.recipients_display_only.cc.find(itm => itm.address?.toLowerCase().indexOf(searchTerm) > -1)) {
			return true;
		}
	}

	return false;
}
