import {DICTIONARY} from '../../../../dictionary';
import {GeneralService} from '../../../../services/generalService';
import {NotificationService} from '../../../../services/notificationService';
import {RouteService} from '../../../../services/routeService';
import _ from 'lodash';
import {Component, OnInit} from "@angular/core";
import util from "util";

@Component({
	selector: 'account-compromised-cfg-component',
	templateUrl: './account-compromised-configurations.component.html',
})
export class AccountCompromisedConfigurationsComponent implements OnInit {
	dic = DICTIONARY;
    usersSelectionTypes = {all: 'all', specific: 'specific'};
	users;
	userInfo;
	accountCompromisedConfig;
	exchangeServerConfig;
	incidentTypes;
	selectUsersPopup;
	suspiciousMailboxRulesPopup;
	applyUsersInProcess = false;
	getAccountCompromisedInProcess;
	_=_;

	constructor(private rs:RouteService,
				private ns:NotificationService,
				public gs:GeneralService) {
	}

	ngOnInit() {
		this.getAccountCompromisedInProcess = true;
		this.rs.getAccountCompromisedConfiguration().then(response => {
			this.users = response.users;
			this.accountCompromisedConfig = response.account_compromised;
			this.exchangeServerConfig = response.exchange_server;

			delete this.accountCompromisedConfig.incident_types.abnormal_user_activity;

			this.prepareIncidentTypes();
			this.getAccountCompromisedInProcess = false;
		}, () => {
			this.getAccountCompromisedInProcess = false;
		});

		this.gs.getUserInfo(false, userInfo => {
			this.userInfo = userInfo;
		});
    }

	prepareIncidentTypes() {
		this.incidentTypes = {};

		// set selected for actions for incident types
		_.each(this.accountCompromisedConfig.incident_types, (typeObj, typeName) => {
			let incidentActions = [];
			typeObj.displayActions = [];
			// !!-- to activate/deactivate actions : note/un-note them in dictionary --!!
			_.each(this.dic.CONSTANTS.accountCompromisedRulesSubActions, (actionData) => {
				const actionObj = {name: actionData.name, display: actionData.display, selected: false, tooltip: actionData.tooltip};
				if (typeObj.actions.includes(actionData.name)) {
					actionObj.selected = true;
					typeObj.displayActions.push(actionData.display);
				}
				incidentActions.push(actionObj);
			});
			this.incidentTypes[typeName] = {
				name: this.dic.CONSTANTS.accountCompromisedIncidentTypes[typeName].name,
				tooltip: this.dic.CONSTANTS.accountCompromisedIncidentTypes[typeName].tooltip,
				users: [],
				actions: incidentActions,
				actionTxt: typeObj.displayActions
			};
		});

		// get users for each incident
		this.users.forEach((user) => {
			if (user.misc.account_compromised?.incident_types) {
				delete user.misc.account_compromised.incident_types.abnormal_user_activity;

				_.each(user.misc.account_compromised.incident_types, (typeObj, typeName) => {
					this.incidentTypes[typeName].users?.push({
						name: user.name || '',
						email: user.email,
						selected: typeObj.enabled
					});
				});
			}
		});

		// set text for "users" boxes in UI
		_.each(this.accountCompromisedConfig.incident_types, (typeObj, typeName) => {
			this.setEnabledUsersText(typeName);
		});
	};

	setEnabledUsersText(incidentKey) {
	    if (this.accountCompromisedConfig.incident_types[incidentKey].apply_all_users) {
			this.accountCompromisedConfig.incident_types[incidentKey].enabledUsersText = 'All users';
	        return;
        }

		const enabledUsers = _.filter(this.incidentTypes[incidentKey].users, 'selected');
		let text;
		if (!enabledUsers.length) {
			text = 'Select users';
		}
		else {
			text = _.map(enabledUsers, 'email').join(', ');
		}

		this.accountCompromisedConfig.incident_types[incidentKey].enabledUsersText = text;
	}

	getUserEmailRules = () => {
		this.suspiciousMailboxRulesPopup.inProcess = true;
		this.suspiciousMailboxRulesPopup.verifyRulesSucces = false;
		this.suspiciousMailboxRulesPopup.verifyRulesFailure = false;

		this.rs.getUserEmailRules(this.userInfo.email).then((response) => {
			this.suspiciousMailboxRulesPopup.verifyRulesSucces = true;
			this.suspiciousMailboxRulesPopup.inProcess = false;
		}, () => {
			this.suspiciousMailboxRulesPopup.verifyRulesFailure = true;
			this.suspiciousMailboxRulesPopup.inProcess = false;
		});
	};

	getUserEmailRulesForPlan = () => {
		if (!this.exchangeServerConfig.enabled || !this.exchangeServerConfig.advanced_enabled) {
			const message = (util.format('Access to mailbox rules is not available. Make sure that Microsoft Exchange is connected and that advanced permissions have been granted in the %s page',
				'<a target="_self" href="/' + DICTIONARY.CONSTANTS.appStates.adminOutbound + '/' + DICTIONARY.CONSTANTS.adminPages.outbound.sharedPlan + '/' + DICTIONARY.CONSTANTS.outboundPlanSettingsPageTabs.integrations + '"><b><i>Integrations</i></b></a>'));
			return this.ns.showWarnMessage(message);
		}

		this.gs.showPopup({
			title: 'Check Existing Rules',
			subTitle: 'All of your monitored mailboxes will be checked for existing suspicious rules',
			body: ['If any suspicious rules are found, a new incident will be created in the Incidents & Activity page.',
			'Note: To use this feature, you must be connected to the Exchange API and grant advanced permissions'],
			type: this.dic.CONSTANTS.popupInfo,
			doneBtnText: 'Confirm',
			doneCb: () => {
				this.rs.getUserEmailRules('').then((response) => {
					this.ns.showInfoMessage('Checking rules started successfully');
				}, () => {
					const message = (util.format('Access to mailbox rules is not available. Make sure that Microsoft Exchange is connected and that advanced permissions have been granted in the %s page',
						'<a target="_self" href="/' + DICTIONARY.CONSTANTS.appStates.adminOutbound + '/' + DICTIONARY.CONSTANTS.adminPages.outbound.sharedPlan + '/' + DICTIONARY.CONSTANTS.outboundPlanSettingsPageTabs.integrations + '"><b><i>Integrations</i></b></a>'));
					return this.ns.showWarnMessage(message);
				});
			}
		});
	}

	openSelectUsersPopup(incidentType) {
		if (incidentType === 'new_forward_redirect_rules') {
			this.suspiciousMailboxRulesPopup = {show: true};
			return;
		}

		this.openSelectUsersPopupExecute(incidentType);
	}

	suspiciousMailboxRulesConfirmPopup() {
		if (!this.exchangeServerConfig.enabled || !this.exchangeServerConfig.advanced_enabled) {
			this.ns.showErrorMessage('To use this incident you need to connect to Exchange and grant advanced permissions');
			return;
		}

		this.suspiciousMailboxRulesPopup = null;
		this.openSelectUsersPopupExecute('new_forward_redirect_rules');
	}

	openSelectUsersPopupExecute(incidentType) {
		const popupUsers = _.cloneDeep(this.incidentTypes[incidentType].users);
		popupUsers.forEach(user => {
			user.hide = false;
		});

		const selectUsersType = this.accountCompromisedConfig.incident_types[incidentType].apply_all_users ? this.usersSelectionTypes.all : this.usersSelectionTypes.specific;

		this.selectUsersPopup = {
			incidentType: incidentType,
			selectUsersType: selectUsersType,
			users: popupUsers,
			show: true,
			errMsg: ''
		};
	}

	searchUser = (event) => {
		this.selectUsersPopup.users.forEach(record => {
			// search
			if (event.searchTerm) {
				const isFound = this.searchUserTextExecute(record, event.searchTerm);
				if (!isFound) {
					record.hide = true;
					return;
				}
			}

			record.hide = false;
		});
	}

	searchUserTextExecute(user, searchTerm) {
		searchTerm = searchTerm.toLowerCase();
		return ((user.name && user.name.toLowerCase().indexOf(searchTerm) > -1) ||
			(user.email && user.email.toLowerCase().indexOf(searchTerm) > -1));
	}

	applyUsersToIncidentType() {
		if (this.applyUsersInProcess) return;

        const enableEmails = _.map(_.filter(this.selectUsersPopup.users, user => {return user.selected}),'email');
        const disableEmails = _.map(_.filter(this.selectUsersPopup.users, user => {return !user.selected}), 'email');
        const isApplyAllUsers = this.selectUsersPopup.selectUsersType === this.usersSelectionTypes.all;

		const data = {
			action: this.dic.CONSTANTS.accountCompromisedUsersActions.incidentTypes,
			type: this.selectUsersPopup.incidentType,
            applyAllUsers: isApplyAllUsers,
			enableEmails: enableEmails,
			disableEmails: disableEmails
		};

		this.applyUsersInProcess = true;

		this.rs.updateAccountCompromisedUsers(data).then(response => {
			this.ns.showInfoMessage(this.dic.MESSAGES.changedSuccessfully);
			this.incidentTypes[this.selectUsersPopup.incidentType].users = _.cloneDeep(this.selectUsersPopup.users);
			const applyToAll = this.selectUsersPopup.selectUsersType === this.usersSelectionTypes.all;
            this.accountCompromisedConfig.incident_types[this.selectUsersPopup.incidentType].apply_all_users = applyToAll;
            if (applyToAll) {
                // in the next popup opening the users will be all selected for more understandable UX
                this.incidentTypes[this.selectUsersPopup.incidentType].users.forEach(user => {
                    user.selected = true;
                });
            }
			this.setEnabledUsersText(this.selectUsersPopup.incidentType);
			this.applyUsersInProcess = false;
            this.selectUsersPopup = null;
		});
	}

	changeIncidentActions(incidentType) {
		if (!incidentType) return;
		let actions = [];
		const displayActions = [];
		this.incidentTypes[incidentType].actions.forEach((actionObj) => {
			if (actionObj.selected) {
				actions.push(actionObj.name);
				displayActions.push(actionObj.display);
			}
		});

		const data = {
			action: this.dic.CONSTANTS.accountCompromisedConfigActions.incidentTypesActions,
			type: incidentType,
			actions: actions
		};

		const blockAndRiskyOverlap = _.intersection(actions, [this.dic.CONSTANTS.accountCompromisedRulesSubActions.blockUser.name, this.dic.CONSTANTS.accountCompromisedRulesSubActions.blockUser24hours.name, this.dic.CONSTANTS.accountCompromisedRulesSubActions.riskyUser24hours.name]);
		if (blockAndRiskyOverlap.length > 1) {
			this.ns.showErrorMessage(`"${blockAndRiskyOverlap[0]}" and "${blockAndRiskyOverlap[1]}" cannot be selected together`);
			return;
		}

		this.rs.updateAccountCompromisedConfig(data).then(response => {
			this.incidentTypes[incidentType].actionTxt = displayActions;
			this.ns.showInfoMessage(this.dic.MESSAGES.changedSuccessfully);
		});
	}
}
