import * as util from 'util';
import _ from 'lodash';
import {DICTIONARY} from '../../../../dictionary';
import {RouteService} from '../../../../services/routeService';
import {NotificationService} from '../../../../services/notificationService';
import {GeneralService} from '../../../../services/generalService';
import {Component, OnInit} from "@angular/core";

@Component({
	selector: 'account-addresses-component',
	templateUrl: './account-addresses.component.html',
})
export class AccountAddressesComponent implements OnInit {
    country_code = this.gs.country_code;
    dic = DICTIONARY;

    hasWKTextSecurity = this.gs.hasWKTextSecurity();
    userInfo: any;
    accountFromEmails: any;
    isPrimaryDefault: boolean;
    isAddAddressActive: any;
    curFromAddrObj: any;
	editAddressInProcess;

    constructor(public gs:GeneralService,
				private rs:RouteService,
				private ns:NotificationService,) {
    }

	ngOnInit() {
        this.gs.getUserInfo(false, (userInfo) => {
            this.userInfo = userInfo;
            this.accountFromEmails = this.userInfo.from;
            this.parseAccountEmails();
        });
    }

    parseAccountEmails = () => {
        this.isPrimaryDefault = true;
        this.accountFromEmails.forEach((accountEmailObj) => {
            if (!accountEmailObj.email_verified) {
                this.checkVerificationEmail(accountEmailObj);
            }
            accountEmailObj.type_display = accountEmailObj.type;
            if (accountEmailObj.type === this.dic.CONSTANTS.fromTypes.default) {
                this.isPrimaryDefault = false;
            }
        });

        if (this.isPrimaryDefault) {
            // first item is the primary
            this.accountFromEmails[0].type_display += ' (default)';
        }
    }

    showAddressActions = (accountEmailObj) => {
        let actions = [
            this.dic.CONSTANTS.fromActions.edit,
            this.dic.CONSTANTS.fromActions.checkIdentityVerified
        ];

        if (!accountEmailObj.email_verified) {
            actions.push(this.dic.CONSTANTS.fromActions.sendVerification);
        }
        if (!(accountEmailObj.type === this.dic.CONSTANTS.fromTypes.default || !accountEmailObj.email_verified || (accountEmailObj.type === this.dic.CONSTANTS.fromTypes.primary && this.isPrimaryDefault))) {
            actions.push(this.dic.CONSTANTS.fromActions.setAsDefault);
        }
        if (accountEmailObj.email !== this.userInfo.email) {
            actions.push(this.dic.CONSTANTS.fromActions.remove);
        }

        return actions;
    }

    selectAddressAction = (accountEmailObj ,action) => {
        switch (action) {
            case this.dic.CONSTANTS.fromActions.edit:
                this.startEditFromAddress(accountEmailObj);
                break;

            case this.dic.CONSTANTS.fromActions.checkIdentityVerified:
                this.checkVerificationEmail(accountEmailObj);
                break;

            case this.dic.CONSTANTS.fromActions.sendVerification:
                this.sendVerificationEmail(accountEmailObj);
                break;

            case this.dic.CONSTANTS.fromActions.setAsDefault:
                this.setAsDefault(accountEmailObj);
                break;

            case this.dic.CONSTANTS.fromActions.remove:
                this.removeFromAddr(accountEmailObj);
                break;
        }
    }

    startAddFromAddress = () => {
        if (this.isAddAddressActive) {
            return;
        }

        this.isAddAddressActive = true;

        const newAddress = {
            isEditMode: true,
            isNew: true,
            edit: {
                name: '',
                email: '',
				phone: {country_code: '', phone_number: ''},
            },
        };

        this.accountFromEmails.unshift(newAddress);
    };

    startEditFromAddress = (record) => {
        record.edit = {
            email: record.email,
            name: record.name,
			phone: _.clone(record.phone) || {country_code: '', phone_number: ''},
        };
        record.isEditMode = true;
    }

    confirmEditFromAddress = (address, isApproved) => {
		if (this.editAddressInProcess) {
			return;
		}

        // edit declined
        if (!isApproved) {
            // new address process was declined
            if (address.isNew) {
                _.remove<any>(this.accountFromEmails, address);
                this.isAddAddressActive = false;
            } else { // existed contact (not new)
                // empty the 'edit' property which contains the temp edit values and then close edit mode
                address.edit = null;
                address.isEditMode = false;
            }
            return;
        }

        //
        // edit approved
        if (!address.isNew) {
            this.editFromAddress(address);
        }
        else {
            this.addAccountEmail(address)
        }
    }

    editFromAddress = (address) => {
        let editData = {
            action: this.dic.CONSTANTS.fromActions.edit,
            fromDetails: {
                email: address.edit.email,
                phone: address.edit.phone,
                firstName: '',
                lastName: ''
            }
        };

        if (address.edit.name) {
            let indexOfSpaceInName = address.edit.name.indexOf(' ');
            editData.fromDetails.firstName = address.edit.name.substring(0, indexOfSpaceInName);
            editData.fromDetails.lastName = address.edit.name.substring(indexOfSpaceInName + 1);
            if (!editData.fromDetails.firstName || !editData.fromDetails.lastName) {
                return this.ns.showErrorMessage(this.dic.ERRORS.accountEmailMissingFullName);
            }
        }
        else {
            return this.ns.showErrorMessage(this.dic.ERRORS.accountEmailMissingFullName);
        }
		address.confirmEditInProcess = true;
		this.editAddressInProcess = true;

        this.rs.updateAccountEmail(address.email, editData).then((response) => {
			Object.assign(address,address.edit)
			if (address.phone?.phone_number) {
				address.phone.phone_number = address.phone.phone_number.replace(/^0+/, '');
			}

            address.edit = null;
			address.confirmEditInProcess = false;
            address.isEditMode = false;
			this.editAddressInProcess = false;

			if (address._id === this.accountFromEmails[0]._id) {
                this.gs.primaryNameChangeSubj.next(editData.fromDetails);
            }
            this.ns.showInfoMessage(this.dic.MESSAGES.userDetailsAccount);
        }, err => {
			address.confirmEditInProcess = false;
			this.editAddressInProcess = false;
		});

    }

    addAccountEmail = (address) => {
        if (!address.edit.email || !this.gs.validateEmail(address.edit.email)) {
            this.ns.showErrorMessage(this.dic.ERRORS.accountEmailInvalid);
            return;
        }
        if (!address.edit.firstName || !address.edit.lastName) {
            const text = !address.edit.firstName ? 'First name' : 'Last name';
            this.ns.showErrorMessage(util.format(this.dic.ERRORS.accountEmailMissingName, text));
            return;
        }

		address.confirmEditInProcess = true;

        this.rs.addAccountEmail(address.edit).then((response) => {
			Object.assign(address, address.edit);
			address.email = address.email.toLowerCase();
			if (address.phone?.phone_number) {
				address.phone.phone_number = address.phone.phone_number.replace(/^0+/, '');
			};

			const addressFromResponseObj = _.find(response.from, add => {return add.email === address.email});
            if (addressFromResponseObj) {
                address._id = addressFromResponseObj._id;
                address.name = addressFromResponseObj.name;
                address.email_verified = addressFromResponseObj.email_verified || !response.requiresVerification;
            }
            address.type = this.dic.CONSTANTS.fromTypes.regular;
            address.type_display = this.dic.CONSTANTS.fromTypes.regular;
            address.edit = null;
			address.confirmEditInProcess = false;
            address.isEditMode = false;
            address.isNew = undefined;
            this.isAddAddressActive = false;
        }, (err) => {
			address.confirmEditInProcess = false;
		});
    };

    setAsDefault = (accountEmailObj) => {
        if (!accountEmailObj) {
            return;
        }

        const editData = {
            action: this.dic.CONSTANTS.fromActions.setAsDefault
        };

        this.rs.updateAccountEmail(accountEmailObj.email, editData).then((response) => {
            this.accountFromEmails = response.from;
			this.userInfo.from = response.from;
            this.parseAccountEmails();
        });
    };

    checkVerificationEmail = (accountEmailObj) => {
        if (!accountEmailObj) {
            return;
        }

        this.rs.getUserVerifiedWithEmail(accountEmailObj.email).then((response) => {
            accountEmailObj.email_verified = response.email_verified;
        });
    };

    sendVerificationEmail = (accountEmailObj) => {
        if (!accountEmailObj) {
            return;
        }

        this.rs.sendVerficationMailWithEmail(accountEmailObj.email).then((response) => {});
    };

    removeFromAddr = (fromAddressToRemove) => {
        this.curFromAddrObj = fromAddressToRemove;
        this.gs.showPopup({
            title: 'Remove Email Address',
            subTitle: 'Please note - Delete email address from my account!',
            body: ['The email address will be removed from your account.'],
            type: this.dic.CONSTANTS.popupWarning,
            doneBtnText: 'Remove',
            doneCb: () => this.removeFromAddrExecute()
        });
    };

    removeFromAddrExecute = () => {
        if (!this.curFromAddrObj) {
            return;
        }

        this.rs.deleteAccountEmail(this.curFromAddrObj.email).then((response) => {
            let idx = 0;
            for (; idx < this.accountFromEmails.length; idx++) {
                if (this.accountFromEmails[idx].email === this.curFromAddrObj.email) {
                    this.accountFromEmails.splice(idx, 1);
                    break;
                }
            }

            if (this.curFromAddrObj.type === this.dic.CONSTANTS.fromTypes.default) {
                this.isPrimaryDefault = true;
            }

            this.ns.showInfoMessage(util.format(this.dic.MESSAGES.accountEmailDeleted, this.curFromAddrObj.email));
        });
    };

}
